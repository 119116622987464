<template>
  <div class="container my-5">
    <div class=" d-flex justify-content-around ">
      <input type="text" class="form-control form-control-sm rounded-pill my-3 w-50" v-model="searchQuery" placeholder="Search by title" />
      <select class="form-select form-select-sm w-25 my-3 rounded-pill" v-model="selectedCategory" aria-label=".form-select-sm example">
          <option value="">All</option>
          <option v-for="(category, i) in categories" :key="i" :value="category">{{ category }}</option>
      </select>
      <p class="my-3">{{filteredItems.length}} Item(s) Found</p>
    </div>

    <div class="row row-cols-md-2 justify-content-center row-cols-lg-3" v-if="searchQuery.length < 1 && selectedCategory.length < 1 " >
      <div  class="col-lg-4" v-for="(story, index) in storyset" :key="index">
        <div class="card card-margin">
            <div class="card-body pt-3" style="min-height:80%">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper">
                        <div class="widget-49-date-primary">
                          <img  :src="require('../assets/icons/' + story.icons)">
                            <!-- bi bi-bar-chart-line fs-2 -->
                        </div>
                        <div class="widget-49-meeting-info mx-auto" style="width: 70%;">
                            <span class="widget-49-pro-title fw-bold text-uppercase">{{story.title}}</span>
                            <!-- <span class="widget-49-meeting-time">12:00 to 13.30 Hrs</span> -->
                        </div>
                    </div>
                    <p class="widget-49-meeting-points">{{story.description}}</p>
                </div>
            </div>
            <div class="widget-49-meeting-action card-footer border-0 bg-transparent  d-flex justify-content-between">
                <span class="text-muted fst-italic" style="font-size:12px">Data Sourced by {{story.institute}}</span>
                <router-link class="btn rounded-pill btn-sm px-4" style="background-color:#e6b600" :to="{name:'Storysetchartboard', params:{data:JSON.stringify(storyset[index].category)}}" >View <i class="bi bi-arrow-right-short"></i> </router-link>
            </div>
        </div>
      </div>
    </div>
      <div class="row row-cols-md-2 justify-content-center row-cols-lg-3" v-else>
      <div  class="col-lg-4" v-for="(story, index) in filteredItems" :key="index">
        <div class="card card-margin">
            <div class="card-body pt-3" style="min-height:80%">
                <div class="widget-49">
                    <div class="widget-49-title-wrapper">
                        <div class="widget-49-date-primary">
                          <img  :src="require('../assets/icons/' + story.icons)">
                            <!-- bi bi-bar-chart-line fs-2 -->
                        </div>
                        <div class="widget-49-meeting-info mx-auto" style="width: 70%;">
                            <span class="widget-49-pro-title fw-bold text-uppercase">{{story.title}}</span>
                            <!-- <span class="widget-49-meeting-time">12:00 to 13.30 Hrs</span> -->
                        </div>
                    </div>
                    <p class="widget-49-meeting-points">{{story.description}}</p>
                </div>
            </div>
            <div class="widget-49-meeting-action card-footer border-0 bg-transparent  d-flex justify-content-between">
                <span class="text-muted fst-italic" style="font-size:12px">Data Sourced by {{story.institute}}</span>
                <router-link class="btn rounded-pill btn-sm px-4" style="background-color:#e6b600" :to="{name:'Storysetchartboard', params:{data:JSON.stringify(filteredItems[index].category)}}" >View <i class="bi bi-arrow-right-short"></i></router-link>
            </div>
        </div>
      </div>
    </div>
<br><br>
<br><br>
</div>
</template>
<script>
export default {
    data() {
        return {
            categories: ['Africa Rising', 'Aiccra'],
            searchQuery:'',
            selectedCategory: '',
            storyset:[
                {
                  title:'Statistics of the Dataset',
                  description:'Data used for this report was collected from 24 distinct communities across the Northern, Savanna and Upper East regions of Ghana. 47% of records came from the Northern region, 30% of the records were collected in the Savanna region and the  remaining 23% came from the Upper East region. Details of records is shown to the left.',
                  dataset_id:'ss001',
                  icons:"pie-chart.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'Regional Statistics',
                      description:'Data used for this report was collected from 24 distinct communities across the Northern, Savanna and Upper East regions of Ghana. 47% of records came from the Northern region, 30% of the records were collected in the Savanna region and the remaining 23% came from the Upper East region. Details of records is shown to the left.',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['Northern', 'Savanna', 'Upper East']
                        },
                        [],
                        [47,30,23],
                      ]
                    },
                  ]
                },
                {
                  title:'Youth & Gender Distribution of Respondents',
                  description:'A total of 373 farmers forming 59.5% of respondents were males and 254 respondents forming 40.5% were females. Over 62% of the respondents were in the maximum age bracket (above 38 years) with a little less than 1% being between the minimum age bracket of 18 to 22 years.',
                  dataset_id:'ss002',
                  icons:"line-graph (1).png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'Age & Gender Distribution',
                      description:'Data distribution by age bracket show that female farmer population is slightly above male farmer population among the youth (from 18 – 27 years). Within this age bracket, female to male farmer ratio stands at 1 : 0.86. This ratio is becomes 1 : 1 for farmers above 28 years.',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['18-22','23-27','28-32','33-37', '38 & up']
                        },
                        ['Male', 'Female', 'All'],
                        [4,25,42,77,224],
                        [2,21,26,38,127],
                        [6,46,68,155,391]
                      ]
                    },
                    {
                      title:'Farm size owned by Male and Female',
                      description:'84% of female respondents farm on a land size between 1 and 4 acres as compared to 21% of male respondents who farm on same size of land. On the other hand there are only 2% of female farmers farming on more than 5 acres of land whereas 31% of male farmers farm on more than same 5acres of land. Inner ring for female, outer ring for male',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['1-4','5-10','6-15','above 16']
                        },
                        [],
                        [21,48,13,18],
                        [84,14,0,2],
                      ]
                    },
                    {
                      title:'Farmer Dstribution by Farm Size & Age',
                      description:'majority of farmers are above 38 years old. Farm size between 1 to 4 acres dominate across age all groupings.',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['18-22','23-27','28-32','33-37', '38 & up']
                        },
                        ['16 and above', '11 to 15 Acres',' 5 to 10 Acres',' 1 to 4 Acres'],
                        [2,1,4,11,60],
                        [0,3,6,17,23],
                        [2,11,22,43,136],
                        [4,33,37,45,168]
                      ]
                    },
                  ]
                },
                {
                  title:'EDUCATION LEVELS',
                  description:'These Records are a collection and comparisim of farmers based on their education levels(wether uneducated, jhs, shs, O-levels, and tertiary) and farm size',
                  dataset_id:'ss003',
                  icons:"pie-chart.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'EDUCATION LEVEL OF FARMERS WITH FARM SIZE BETWEEN 1 – 4 ACRES',
                      description:'The data shows there are higher records of uneducated Female farmers 85% as compared to male farmers of 58% with land sized between 1-4 Acres. Outer circle Female Farmers, Inner Circle male Farmers',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['No Education', 'Basic Level', 'JHS', 'SHS', 'O-level', 'Tertiary level']
                        },
                        ['Female', 'Male'],
                        [85,7,6,1,1,0],
                        [58,5,10,20,3,4]
                      ]
                    },
                    {
                      title:'EDUCATION LEVEL OF FARMERS WITH FARM SIZE BETWEEN 5 – 10 ACRES',
                      description:'Unlike farmers with land sizes between 1-4 Acres, farmers with 5-10 Acres have more females having been educated as compared to Male Farmers.',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['No Education', 'Basic Level', 'JHS', 'SHS', 'O-level', 'Tertiary level']
                        },
                        ['Female', 'Male'],
                        [70,5,5,18,0,2],
                        [79,10,6,1,1,3]
                      ]
                    },
                    {
                      title:'EDUCATION LEVEL OF FARMERS WITH FARM SIZE BETWEEN 11 – 15 ACRES',
                      description:'The education level of farmers with farm sizes between 11-15 acres shows a 100% of females with this land sizes are uneducated whereas male farmers have more education and training',
                      chart_type:'line',
                      datasets: [
                        {
                          lables:['No Education', 'Basic Level', 'JHS', 'SHS', 'O-level', 'Tertiary level']
                        },
                        ['Female', 'Male'],
                        [100],
                        [59,22,11,6,0,2]
                      ]
                    },
                    {
                      title:'EDUCATION LEVEL OF FARMERS WITH FARM SIZE OF 16 ACRES AND ABOVE',
                      description:'The education level of farmers with farm sizes above 16 acres have female farmers matching up with male farmers with uneducated farmers 85% for female farmers and 84% for male farmers. Outer circle Female Farmers, Inner Circle male Farmers',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['No Education', 'Basic Level', 'JHS', 'SHS', 'O-level', 'Tertiary level']
                        },
                        ['Female', 'Male'],
                        [85,7,6,1,1,0],
                        [84,3,3,3,0,7]
                      ]
                    },
                  ] 
                },
                {
                  title:'FARM LAND OWNERSHIP',
                  description:'609 farmers forming 97.13% of the farmers own the lands they farm on leaving only 18 farmers forming 2.87% of the farmers who farm on lands obtained by other means.',
                  dataset_id:'ss004',
                  icons:"pie-chart.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'BREAKDOWN OF 609 FARMERS',
                      description:'56.36% of farmers with farm size between 1-4 acres own the lands individually or jointly with their spouse. 71.76% of farmers with farm size between 5-10 acres own the lands individually or jointly with their spouse. 78.72% of farmers with farm size above 16 acres own the lands individually or jointly with their spouse. 71.23% of farmers with farm size above 16 acres own the lands individually or jointly with their  spouse', 
                      chart_type:'Radar',
                      datasets: [
                        {
                          lables:['Personal', 'Family Land', 'Joint with Spouse', 'Comimunal Land', 'Others']
                        },
                        ['All Farmers'],
                        [321,260,87,10,9]
                      ]
                    },
                    {
                      title:'Use Of Family Lands for Farming',
                      description:'42.99% of farmers with farm size between 1-4 acres, 28.02% of farmers with farm size between 5-10 acres, 21.28% of farmers with farm size above between 11-15 acres, 28.77% of farmers with farm size above 16 acres make use of family lands',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['16 and above', '11 to 15 Acres',' 5 to 10 Acres',' 1 to 4 Acres']
                        },
                        ['Farmers'],
                        [28.77, 21.28, 28.02, 42.99]
                      ]
                    },
                    {
                      title:'OTHER LAND SOURCES (18 FARMERS)',
                      description:'Out of all farmers 18 farmers, land sources included renting, leasing, requesting from friend and from others sources, and Land sizes included 5-10 and 1-4 Acres. However, none of the farmers with farm size above 10 acres acquired their land on lease, rent or from a friend.',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['Leasing', 'Renting','Request From Friend','Others']
                        },
                        ['General','Other Land Sources (5 – 10 Acres)', 'Other Land Sources (1 – 4 Acres)'],
                        [15.79, 15.79,63.16,5.26 ],
                        [10.53, 0, 10.53, 0],
                        [5.26, 15.79, 52.63, 5.26]

                      ]
                    }
                  ]
                },
                {
                  title:'INSURANCE AFFINITY',
                  description:'10.7% of farmers indicated having no knowledge of crop insurance. Of the remaining 89.3% farmers who indicated having knowledge of farm insurance, 96%  indicated willingness to purchase insurance and only 18.1% of them had actually purchased insurance in the preceding farming season.',
                  dataset_id:'ss002',
                  icons:"scatter-graph.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'Insurance Affinity by Education',
                      description:'The likelihood of a farmer being informed about insurance irrespective of education level stands at 69.4%. However, farmers with a minimum of senior high education are shown to possibly have a 28.6% affinity towards insurance purchase as compared to 16.7% affinity for farmers with lower education level.',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['below SHS','minimal of SHS','No education']
                        },
                        [],
                        [28.8,16.7,54.5],
                      ]
                    },
                    {
                      title:'Insurance Affinity by Crop',
                      description:'Maize is registered as the most insured crop leading with 77.2% of purchased insurance followed by groundnut forming 15.8% of purchased insurance. Only one farmer insured soyabean. While no specific mention is made of cowpea insurance, some 7% of farmers purchased insurance for other varieties of crops including rice.',
                      chart_type:'Radar',
                      datasets: [
                        {
                          lables:['Maize','Groundnut','Others']
                        },
                        ['Registed Crop'],
                        [77.2,15.8,7  ],
                      ]
                    }
                  ]
                },
                {
                  title:'INSURANCE',
                  description:'Of a total of 144 farmers who insured their crops, six (6) of them are unwilling to continue mostly because of non-payment of payouts by insurance companies. Only one farmer would not continue because of the cost involved.',
                  dataset_id:'ss002',
                  icons:"pie-chart.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'FARMER OPINION ON INSURANCE COST',
                      description:'Out of all Farmers 86% found insuring their crops affordable, 8% very affordable, whereas 6% found insuring their crops as very expensive',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['Affordable','Very Affordable','Expensive']
                        },
                        [],
                        [86,8,6],
                      ]
                    },
                    {
                      title:'Willingness of farmer Purchase',
                      description:' 88.2% of the farmers are willing to pay insurance for improved fertilizer. whereas 66.7% of the farmers can afford improved fertilizer. 84.8% of the farmers are willing to pay insurance for improved seeds  whareas •69.4% of the farmers can afford improved seed',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['Willingness ot buy','Ability to buy']
                        },
                        ['Improved Crop', 'Improved Fertilizer'],
                        [84.8, 69.4],
                        [88.2,66.7],

                      ]
                    }
                  ]
                },
                {
                  title:'Farm Stress',
                  description:'94% of the farmers are affected by one form of farm stress or another with delayed rainfall being the most reported stress. Strong storms and shortage of water for animals were the lest reported stress. A breakdown of the reported stress is depicted below.',
                  dataset_id:'ss003',
                  icons:"bar-chart.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'Reported Stress by Farm Size',
                      description:'Delayed rainfall recorded the highest stress impact on farmers within the three regions. A majority of the farmers under the various stress indices are the small holder farmers. Water shortages (for both animal and humans), erratic temperatures and strong storms recorded the least impact on farmers in the regions.',
                      chart_type:'Doughnut',
                      other: 'From inner Circle: First is Delayed Rainfall, Second is Drought, Third is Flood ',
                      datasets: [
                        {
                          lables:['1-4','5-10','6-15','above 16']
                        },
                        [],
                        [116,92,16,22],
                        [45,48,11,16],
                        [53,26],
                      ]
                    },
                    {
                      title:'Affected Farmers Against Farm Size',
                      description:'Its observed that pest, forage and fires also contribute to farm stress, 55%, 70%, 70% and 75% caused by pests; 42%, 9% and 11% by Forage; and 7%, 21%, 19%, 25% by fires. All against farm size respectively',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['1-4','5-10','6-15','above 16']
                        },
                        ['Pest Outbreak', 'Forage Shortage', 'Bush fires'],
                        [27,26,7,6],
                        [19,3,1,0],
                        [4,8,2,2],
                      ]
                    },
                     {
                      title:'Affected Farmers Against Farm Size',
                      description:'The distribution of farmers affected by Disease Outbreak(Livestock), Erratic Temperature, Water Shortage (For Humans), Water Shortage (For Animals) and Strong Storm',
                      chart_type:'Line',
                      datasets: [
                        {
                          lables:['1-4','5-10','6-15','above 16']
                        },
                        ['Disease(Livestock)', 'Erratic Temperature', 'Water Shortage(Humans)', 'Water Shortage(Animals)','Strong Storm'],
                        [7,1,1,3],
                        [5,3,0,1],
                        [2,0,0,0],
                        [0,0,1,0],
                        [0,1,0,0]
                      ]
                    }                    
                  ]
                },
                {
                  title:'STATISTICS ON USAGE OF IMPROVED SEEDS',
                  description:'The data however shows that 49.76% of the farmers had actually purchased improved seeds at the time of investigation which is about 18.69% less than the average percentage of farmers who had indicated willingness to purchase improved seeds',
                  dataset_id:'ss002',
                  icons:"scatter-graph.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'LAND SIZE OF FARMERS USING IMPROVED SEEDS',
                      description:'Within the group of farmers who actually had improved seeds, inverse to the indication of interest in purchasing improved seeds, it is seen that 60.83% of farmers with farm size greater than 10 acres actually had improved seeds as compared to 46.94% of farmers with land size less than 10acres who actually had improved seeds.',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['less than 10 acres','more than 10 acres']
                        },
                        ['Land Size of farmers Vs Improved seeds'],
                        [60.83, 46.94],
                      ]
                    },
                    {
                      title:'Willingness of farmer Purchase',
                      description:'The survey shows that farmers are very interested in purchasing insurance and have also indicated high interest and ability to pay for improved seed and fertilizer',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['Farmer Affinity','Increased Affinity']
                        },
                        [],
                        [45.5, 86.1],
                        [45.5,87.2],

                      ]
                    }, 
                    {
                      title:'INPUT CREDIT - SEED',
                      description:'Farmers across all sizes of farm need some credit for seed input. The need reduces slightly for farmers with farm size above 15 acres.',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['1-4','5-10','6-15','above 15']
                        },
                        ['Require Input Credit', 'Does not Require Input Credit' ],
                        [84,87,87.2,68.5],
                        [10.6,13,12.8,31.5]
                      ]
                    },
                  ]
                },
                {
                  title:'Do Farmers work outside their farms?',
                  description:'In total, 52.7% of the farmers have other jobs aside farming. Of all the male respondents, there are 48.8% of the responding male farmers who are engaged in other jobs as compared with 57.8% of the female farmers engaged in other jobs beside their regular farming activities.',
                  dataset_id:'ss002',
                  icons:"line-graph (1).png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'Do Farmers work outside?',
                      description:'Within the group of farmers who actually had improved seeds, inverse to the indication of interest in purchasing improved seeds, it is seen that 60.83% of farmers with farm size greater than 10 acres actually had improved seeds as compared to 46.94% of farmers with land size less than 10acres who actually had improved seeds.',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['Yes', 'No']
                        },
                        ['All Survey'],
                        [52.7, 47.3],
                      ]
                    },
                    {
                      title:'Percentage of male Vs Percentage of female farmers doing other jobs', 
                      description:'Of all the male respondents, there are 48.8% of the responding male farmers who are engaged in other jobs as compared with 57.8% of the female farmers engaged in other jobs beside their regular farming activities.',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['Male farmers do other jobs','Female farmers do other jobs']
                        },
                        [],
                        [57.8, 48.8],
                      ]
                    }, 
                    {
                      title:'LIVESTOCK',
                      description:'83.7% of the respondents have households that keep livestock. Of five kinds of livestock (Sheep, goat, cattle, chicken, guinea fowl and pigs) kept by these holds,',
                      chart_type:'Line',
                      datasets: [
                        {
                          lables:['Sheep', 'Goat', 'Cattle', 'Chicken', 'Guinea Fowl' , 'Pig']
                        },
                        ['Total Number Of Livestocks kept', 'No. Households Keeping Livestock' ],
                        [1487, 2519, 1420, 7573, 4287, 193],
                        [248, 370, 118, 458, 221, 32]
                      ]
                    },
                  ]
                },
                {
                  title:'TECHNOLOGY & FARMER HOUSEHOLDS',
                  description:'In all do farmers have access to technology, which technology and platforms do they have access to. How do they have access to climate notifications',
                  dataset_id:'ss002',
                  icons:"pie-chart.png",
                  institute: 'Africa Rising',
                  category:[
                    {
                      title:'Do the farmers own mobile phones?',
                      description:'All farmer who had either senior high, O/A level or tertiary education also owned a mobile phone. Of a total of the 78 farmers forming who did not have mobile phones.',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['Yes', 'No']
                        },
                        [],
                        [87.54, 12.48],
                      ]
                    },
                    {
                      title:'Access to Climate Information Services',
                      description: 'This was a survey to acertain if farmers have access to Climate Information Service 91 farmers answered Yes whiles  7 answered No',
                      chart_type:'Chart',
                      datasets: [
                        {
                          lables:['Yes', 'No']
                        },
                        ['Do Farmers have access you Climate Info?'],
                        [97,7],
                      ]
                    },
                    {
                      title:'Climate Information Source',
                      description: 'Farmers recieve Climate information from various sources thus radio, mobile phones, televion as well as word of mouth',
                      chart_type:'Doughnut',
                      datasets: [
                        {
                          lables:['Moblie Phones', 'Radio', 'Televion', 'Word of Mouth']
                        },
                        [],
                        [22,44,10,24],
                      ]
                    },
                  ]
                },

                // new*************************************************
                {
                  title:'Source | Forms of Information',
                  description:'Of the multiple source and forms of infromation reaching farmers, this is a break down of the various types of forms and the population of farmers each data reached as well as the sources wherein these forms fall in',
                  dataset_id:'ss003',
                  icons:"bar-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Forms of Information recieved against Population',
                      description:'Information is important the form with which it is deliverd wheather verbally, textual, in video or audio is considered as important as well. the data displayed shows most farmers recieve information verbally or my word of mouth than the other forms',
                      chart_type:'Doughnut',
                      other: 'From inner Circle: First is Delayed Rainfall, Second is Drought, Third is Flood ',
                      datasets: [
                        

                        {
                          lables:['Mouth','Voice','Video','Text']
                        },
                        ['Forms of Information Received'],
                        [191,412,63,37]
                      ]
                    },
                    {
                      title:'Source of Information aginst Population',
                      description:'How does these information reach farmers and where are they from? this distribution displays some if not all the sources of information reaching out to farmers. Extention Agents carry the most as shown',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Telephone','Radio','TV','Neighbours','Farmer Based Organization','Extension Agents','Community Informaton Centres']
                        },
                        ['Sources Of Climate Information Received'],
                        [74,370,75,89,49,159,4]
                      ]
                    }                   
                  ]
                },
                // n/a************************************************************
                {
                  title:'Extention servise Agents',
                  description:'94% of the farmers are affected by one form of farm stress or another with delayed rainfall being the most reported stress. Strong storms and shortage of water for animals were the lest reported stress. A breakdown of the reported stress is depicted below.',
                  dataset_id:'ss003',
                  icons:"bar-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Organization training discussion',
                      description:'Delayed rainfall recorded the highest stress impact on farmers within the three regions. A majority of the farmers under the various stress indices are the small holder farmers. Water shortages (for both animal and humans), erratic temperatures and strong storms recorded the least impact on farmers in the regions.',
                      chart_type:'Doughnut',
                      other: 'From inner Circle: First is Delayed Rainfall, Second is Drought, Third is Flood ',
                      datasets: [
                        {
                          lables:['bee keeping','crop management','livestock husbandry','pest and disease control', 'snail farming','specific agricultural technologies']
                        },
                        ['Organization training discussion'],
                        [1,71,7,23,1,62]
                      ]
                    },
                    {
                      title:'Organization training by various institutions',
                      description:'Its observed that pest, forage and fires also contribute to farm stress, 55%, 70%, 70% and 75% caused by pests; 42%, 9% and 11% by Forage; and 7%, 21%, 19%, 25% by fires. All against farm size respectively',
                      chart_type:'Line',
                      other: '',
                      datasets: [
                        {
                          lables:['government agencies','ngo','private sector','research institution/universities']
                        },
                        ['Organization training by various institutions'],
                        [68,19,5,75]
                      ]
                    },
                    {
                      title:'Access extension service and visit extension service',
                      description:'Its observed that pest, forage and fires also contribute to farm stress, 55%, 70%, 70% and 75% caused by pests; 42%, 9% and 11% by Forage; and 7%, 21%, 19%, 25% by fires. All against farm size respectively',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Access extension service','Visit extension service']
                        },
                        ['Yes','No'],
                        [426,	143],
                        [116,	395],
                      ]
                    }                   
                  ]
                },

                {
                  title:'CSA Practices',
                  description:'Community supported agriculture (CSA) programs are a suggested strategy to increase access to healthy foods and distribute fresh fruits and vegetables from local farms to urban and rural areas. CSA programs may help strengthen and improve local and regional food systems and contribute to greater food system sustainability',
                  dataset_id:'ss003',
                  icons:"bar-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Access extension service and visit extension service',
                      description:'What constitutes a CSA practice is context-specific, depending on local socio-economic, environmental and climate change factors. Are our farmers aware and are these factors put to practice?',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['striga and low N ...','Provitamin A and quality ...','Disease and pest tolerant ...','Phosphorous efficient and...','early maturing','potato and tomato varieties','Drought stress tolerant', 'Pest management using sticky traps','Seedbed options-Ridging as...','pheromones','Organic amendment for ...','Water management (mulching)','Minimum tillage  for maize and ...','Leguminous crops as...','Mucuna pruriens or cowpea /maize ...', 'cowpea+maize and vegetable', 'Composting ...', 'Access to climate information','Seasonal calenders','Biopesticide use in potato and yam']
                        },
                        ['awareness',	'practiced'],
                        [236,25,248,40,236,93,236,11,81,11,139,112,252,164,100,304,80,217,1,17],
                        [115,1,140,15,115,44,115,3,37,3,84,63,159,96,24,280,16,135,1,3],
                      ]
                    }                   
                  ]
                },
                 {
                  title:'Climate Access',
                  description:'Ghana’s climate is tropical and strongly influenced by the West Africa monsoon winds. The climate is generally warm with variable temperatures masked by seasons and elevation. The northern part of the country typically records one rainy season, which begins in May and lasts until September. Southern Ghana records two rainy seasons; major season from April to July and minor from September to November.',
                  dataset_id:'ss003',
                  icons:"pie-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Regional Access To Climate Information',
                      description:' The northern part of the country typically records one rainy season, which begins in May and lasts until September. Southern Ghana records two rainy seasons; major season from April to July and minor from September to November this is the display of farmer accessibility to climate data.',
                      chart_type:'Doughnut',
                      other: 'From inner Circle: No, Outer Circle: Yes ',
                      datasets: [
                        {
                          lables:['Bono East','Central','Northern','Greater Accra', 'Upper East', 'Upper West', 'Average']
                        },
                        ['Yes', 'No'],
                        [169,90,70,16,56,75,79.33333333],
                        [16,13,16,15,3,11,12.33333333]
                      ]
                    },
                    {
                      title:'Social Membership Climate Information',
                      description:'Some farmers wish to sign up to some membership groups to be able to be current with information in the agronomic sector, of all farmers how many are part of social groups ',
                      chart_type:'Line',
                      other: '',
                      datasets: [
                        {
                          lables:['Bono East','Central','Northern','Greater Accra', 'Upper East', 'Upper West', 'Average']
                        },
                        ['Yes', 'No'],
                        [69,9,35,6,33,35,31.16666667],
                        [116,94,51,25,26,51,60.5]
                      ]
                    },
                    {
                      title:'Type of climate information received',
                      description:'Ghana’s climate is tropical and strongly influenced by the West Africa monsoon winds. The climate is generally warm with variable temperatures masked by seasons and elevation and the type of info farmers receive is important',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['temperature','rainonset','rainvolume','humidity','sessation 2','sessation 1']
                        },
                        ['type of climate information received'],
                        [124,445,89,76,90,125],
                      ]
                    }                   
                  ]
                },
                {
                  title:'Climate Info',
                  description:'Climatic info helps farmers decide the next point of action, how to prepare for the rains, dought and more, but do farmers in Ghana make use of climate data, are they willing to pay for the costs of climate data and whats is the use to farmers here. ',
                  dataset_id:'ss003',
                  icons:"bar-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Farmers willingness to pay for climate information',
                      description:'The entire farming process comes at a cost, despite the usefulness of climatic information are farmers here in Ghana willing to pay to access this information or do they prefer to go by the traditional means.',
                      chart_type:'Doughnut',
                      other: 'From inner Circle: No, Outer Circle: Yes ',
                      datasets: [
                        {
                          lables:['Yes', 'No']
                        },
                        [],
                        [270, 266],
                      ]
                    },
                    {
                      title:'Usefulness of climate information to farmers',
                      description:'The Collection of how useful climate information is to farmers, whether the data provided them was false, the data is accurate or there was no need for the data as their predictions were right and did not require any other source',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['very useful','somewhat useful', 'not useful']
                        },
                        ['Usefulness of climate information : farmers'],
                        [374,76,7]
                      ]
                    }               
                  ]
                },
                // ***************************************new**********************************
                {
                  title:'General Info',
                  description:'This includes the general analysis made on gender distribution, age distribution, educational levels, marital statuses, commodity values, livestock owned, assets owned and household sizes.',
                  dataset_id:'ss003',
                  icons:"bar-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Commodity Values in various regions',
                      description:'Generally all the region cultivate at least two crops except Central, who cultivate just Sweet potatoes. In Bono East, spikes are seen in the cultivation of maize and yam. Greater Accra also cultivate Pepper and tomatoes only.  Similar in Upper West who also cultivate Maize and Cowpea.',
                      chart_type:'chart',
                      other: '',
                      datasets: [
                        {
                          lables:['B.East', 'Central', 'G.Accra','Northern', 'U.Est','U.Wst']
                        },
                        ['Maize',	'Yam', 'Pepper', 'Cowpea', 'Tomatoes', 'Swt Potatoes'],
                        [160,125,0,0,0,0],
                        [0,0,0,0,0,98],
                        [0,0,30,0,20,0],
                        [81,11,0,16,0,0],
                        [48,0,6,24,28,0],
                        [78,0,0,54,0,0]
                      ]
                    },
                    {
                      title:'Land Tenures',
                      description:'44.1% of farmers assessed own the farms they cultivate on, 23.8% rent while 12.8% are family lands being used by them for farming',
                      chart_type:'Doughnut',
                       other: '',
                      datasets: [
                        {
                          lables:['Communal', 'Family','Owned','Temporal offer','Share Cropping', 'Rented out', 'Renting' ]
                        },
                        [],
                        [8.2, 12.8,41.1,8.6,2.1,0.3,23.8],
                      ]
                    },
                    {
                      title:'Ownership of livestock',
                      description:'Poultry is the most owned livestock(35.6%), coming next is Goats(26.5%), the next most owned livestock is sheep(21.6%)',
                      chart_type:'Doughnut',
                       other: '',
                      datasets: [
                        {
                          lables:['Bulls', 'Cattle','Donkey','Goats','Pigs', 'Sheep', 'Poultry' ]
                        },
                        [],
                        [2.5,7.1,1.0,26.5,5.7,21.6,35.6],
                      ]
                    },
                    {
                      title:'Ownership of Assets',
                      description:'Aside Cutlasses(18.5%), Hoes(18.4%) and mobile phones(17.9%), asset distribution is almost even, and with this small population owning mobile phones, it explains why the mostly communicate face-to-face and prefer to receive information that way',
                      chart_type:'Doughnut',
                       other: '',
                      datasets: [
                        {
                          lables:['Bike', 'Granary','PH Drying','Cutlass','Hoe', 'Tractor', 'TV' ,'Refrigerator', 'Radio','Plough','Motorbike','Phone']
                        },
                        [],
                        [9.5,0.6,1.2,18.5,18.4,0.1,8.3,2.8,13.2,0.3,7.7,17.9],
                      ]
                    }               
                  ]
                },
                // ****************************************
                {
                  title:'Climate and yield',
                  description:'Climate and crop yield data refers to the statistical information that quantifies the relationship between weather patterns, environmental conditions, and the productivity of agricultural crops, providing valuable insights into the impact of climate change on agricultural systems.',
                  dataset_id:'ss003',
                  icons:"pie-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Access to Climate Information',
                      description:'Quite an impressive fraction of the population had access to climate information(86.6%)',
                      chart_type:'Doughnut',
                      other: '',
                      datasets: [
                        {
                          lables:['No', 'Yes']
                        },
                        [17,87],
                        [73,471],
                      ]
                    },
                    {
                      title:'Type of Climate Info Given',
                      description:'Rain Onset was the type of climate given most to the farmers',
                      chart_type:'chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Temp', 'RainOnset', 'Rain_vol','Humidity', 'Sessation_1','Sessation_2']
                        },
                        ['No',	'Yes'],
                        [424,103,458,471,456,421],
                        [120,441,86,73,88,123],
                      ]
                    },
                    {
                      title:'Source of Climate Information',
                      description:'Climate information given came from different sources. Of these sources, radio was the prominent source',
                      chart_type:'chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Source of Info']
                        },
                        ['telephone',	'television',	'radio','neighbours and other farmers',	'farmer-based organizations',	'extension agents',	'community information centers'],
                        [73],[74],[367],[87]	,[48]	,[156],[4]
                      ]
                    },
                    {
                      title:'Form of Climate information Given',
                      description:'Voice was most used form of communication used to give information because radio was prominent in the source of climate information',
                      chart_type:'chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Source of Info']
                        },
                        ['mouth',	'voice',	'video','text'],
                        [187],[409],[62],[35]
                      ]
                    },
                    {
                      title:'Comparing Climate Access to crop yield ',
                      description:'Access to climate information wasn’t of much useful to maize and sweet potato farmers since the yields of those without access to climate information was still higher',
                      chart_type:'chart',
                      other: '',
                      datasets: [
                        {
                          lables:['maize(100kg)', 'yam(100kg)', 'swt potatoes(100kg)','tomatoes(50kg)', 'cowpea(100kg)']
                        },
                        ['No',	'Yes'],
                        [1.679508197,0.231147541,	0.295081967,	0.016393443,	0.008196721],
                        [1.515022624,	0.896726862,	0.183972912	,0.146726862,	0.19530474]
                      ]
                    }, 
                    {
                      title:'Comparing educational levels to crop yield ',
                      description:'Except among sweet potato and tomato farmers, yield generally increases as the level of education of farmers increases and the exception may be due to a few number of people with that educational level cultivating that crop',
                      chart_type:'chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Basic education','Primary education','Secondary education', 'Tertiary education', 'No formal Education', 'Vocational']
                        },
                        ['maize(100kg)', 'yam(100kg)', 'swt potatoes(100kg)','tomatoes(50kg)', 'cowpea(100kg)'],
                        [1.33530303,3,1.335362319,1.377777778,1.673774319,0.75],
                        [0.080681818,0,0.067285714,0.094444444,0.248871595,0],
                        [0.854166667,0.666666667,0.9,0.722222222,0.775875486,0],
                        [0.35719697,0,0.271428571,0,0.129766537,0],
                        [0.151515152,0,0.342857143,0.055555556,0.081712062,0]
                      ]
                    },  
                    {
                      title:'Comparing Access to extension agents to yield ',
                      description:'Extension agents also generally have no effect on the yield on the yield of farmers because the farmers who didn’t have access to extension agents had higher yields except yam farmers',
                      chart_type:'chart',
                      other: '',
                      datasets: [
                        {
                          lables:['maize(100kg)', 'yam(100kg)', 'swt potatoes(100kg)','tomatoes(50kg)', 'cowpea(100kg)']
                        },
                        ['No',	'Yes'],
                        [1.512093023,	0.873582474,	0.228092784,	0.113402062,	0.17685567],
                        [1.696422018, 0.659633028,	0.036697248,	0.201834862,	0.168807339]
                      ]
                    },              
                  ]
                },
                // **************************************************************
                {
                  title:'Practices and services',
                  description:'94% of the farmers are affected by one form of farm stress or another with delayed rainfall being the most reported stress. Strong storms and shortage of water for animals were the lest reported stress. A breakdown of the reported stress is depicted below.',
                  dataset_id:'ss003',
                  icons:"bar-chart.png",
                  institute: 'Aiccra',
                  category:[
                    {
                      title:'Access to Extension Service',
                      description:'Generally most of the farmers have access to extension services, only about a quarter of the assessed population do not have access',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Access extension service']
                        },
                        ['Yes','No'],
                        [422],[115]
                      ]
                    },
                    {
                      title:'Medium extension service communicate',
                      description:'When it comes to communication between extension officers and farmers, the most used or common means is face to face communication',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Medium communication']
                        },
                        ['Digital/electronic','Face-to-Face','Farmer-to-farmer'],
                        [4],[405],[8]
                      ]
                    },
                    {
                      title:'Who extension service',
                      description:'Generally most of the farmers have access to extension services, only about a quarter of the assessed population do not have access',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['MOFA extension','NGOs','Private','Research']
                        },
                        [''],
                        [403,55,24,78],
                      ]
                    },
                    {
                      title:'Organization offering training',
                      description:'Research institutions and Government agencies offer the most training to farmers',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['']
                        },
                        ['Government agencies','NGOs','Private sector','Research institution/ university'],
                        [66],[19],[5],[74],
                      ]
                    },
                    {
                      title:'Information of assistance',
                      description:'Of the trainings offered, farmers found pest and disease management as well as use of fertilizer the most useful information to them followed by the use of improved varieties',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['']
                        },
                        ['Bush fire Mgmt','Gen crop prod. advice','Pest & disease Mgmt','Use of fertilizer','Weather info','Impr. Mgmt of livestock', 'Use of impr. varieties', 'Crop marketing advice','Farm Mgmt & recording', 'Other'],
                        [20],[174],[295],[279],[110],[32],[247],[27],[42],[41],
                      ]
                    },
                    {
                      title:'Membership: FBO and Social',
                      description:'Some farmers show membership in some groups such us FBO and Social membership groups',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['Yes', 'No']
                        },
                        ['fbo membership','social membership'],
                        [311,232],
                        [302,230],
                      ]
                    },
                    {
                      title:'Social Membership Benefit',
                      description:'In joining groups such as Social membership groups, they derive some benefits',
                      chart_type:'Chart',
                      other: '',
                      datasets: [
                        {
                          lables:['']
                        },
                        ['training on good agronomic practices','labour help from farmers','access to working boots','access to farming inputs','access to climate information'],
                        [291],[140],[1],[146],[185]
                      ]
                    }              
                  ]
                },
            ],
        }
    },  
    computed:{
      // filteredItems(){
      //   const query = this.searchQuery.toLowerCase();
      //   return this.storyset.filter(item => item.title.toLowerCase().includes(query));
      // },
      filteredItems() {
      const query = this.searchQuery.toLowerCase();
      const categoryFilter = this.selectedCategory.toLowerCase();
      console.log([query, categoryFilter])
      return this.storyset.filter(item => {
        const titleMatch = item.title.toLowerCase().includes(query);
        const categoryMatch = categoryFilter === '' || item.institute.toLowerCase() === categoryFilter;
        return titleMatch && categoryMatch;
      });
    }

    }
}
</script>

<style scoped>

.card-margin {
    margin-bottom: 1.875rem;
}

.card-footer a{
  color: #fff;
  transition: background-color 0.3s cubic-bezier(0.47, 0, 0.745, 0.715); 
}
.card-footer a:hover{
  background-color: #306102 !important;
}

.card {
    border: 0;
    box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #e6e4e9;
    border-radius: 8px;
    height: 90%;
}

.card .card-header.no-border {
    border: 0;
}
.card .card-header {
    background: none;
    padding: 0 0.9375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    min-height: 50px;
}
.card-header:first-child {
    border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
}

.widget-49 .widget-49-title-wrapper {
  display: flex;
  align-items: center;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e6b60078;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f0fafb;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #68CBD7;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-pro-title {
  color: #3c4142;
  font-size: 14px;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-meeting-time {
  color: #B1BAC5;
  font-size: 13px;
}

.widget-49 .widget-49-meeting-points {
  font-weight: 400;
  font-size: 13px;
  margin-top: .5rem;
}


.widget-49 .widget-49-meeting-action {
  text-align: right;
}

.widget-49 .widget-49-meeting-action a {
  text-transform: uppercase;
}
</style>
<template>
  <div class="row me-0 mt-4" style="height: 7in !important" v-if="dataset">
    <div class="col-md-9 col-sm-12 right-side p-3">
      <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0" class="scrollspy-example w-75 mx-auto" tabindex="0">
        <!-- scrollspy1 -->
        <h1 id="scrollspyHeading1" class="fw-bolder">{{passedData.name}}</h1>
        <p>{{passedData.description}}</p>
        <p class="text-start">
         <span v-if="passedData.userAccess == 'false'"><i class="bi bi-unlock-fill" ></i> Public </span> 
          <span v-if="passedData.userAccess == 'true'"><i class="bi bi-lock-fill"></i> Private </span>
           
            <button class="btn btn-sm px-3 rounded-pill float-end text-light" @click="downloadFile(passedData.identity)" :disabled="passedData.userAccess == 'true'" style="background-color:#e6b600">
              <i class="bi bi-file-earmark-arrow-down fw-bolder me-1" v-if="!isLoading"></i>
              <div v-if="isLoading" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              {{isLoading ? 'downloading' : 'download'}}
            </button>
          </p>
        <hr class="w-50 mx-auto">
        <!-- scrollspy2 -->
        <h4 id="scrollspyHeading2" class="text-start mt-5">Data Preview</h4>
        <div class="table-responsive rounded-1 border-secondary border-start border-end" >
    <table class="table table table-sm table-striped table-hover text-start" style="font-size:12px !important">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" v-for="(header, i) in dataset[0].splice(0,8)" :key="i">{{header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, x_index) in dataset.splice(1,10)" :key="x_index">
          <th scope="row">{{x_index}}</th>
          <td v-for="(col, y_index ) in row.splice(0,8)" :key="y_index">{{col}}</td>
        </tr>
      </tbody>
    </table>
        </div>
        <!-- scrollspy3 -->
        <br><br>
        <div class="row" id="scrollspyHeading3">
          <div class="col  text-start">
            <h5 class="fw-bold">Data Summary</h5>
            <hr class="w-50">
            <p><i class="bi bi-file-earmark-binary fw-bolder pe-1"></i>{{passedData.size/1000}}Mb Size</p>
            <p><i class="bi bi-distribute-horizontal fw-bolder pe-1"></i> {{noCols}} Columns</p>
            <p><i class="bi bi-distribute-vertical fw-bolder pe-1"></i> {{noRows}} Rows</p>
          </div>
          <div class="col text-end">
             <h5 class="fw-bold">Activity</h5>
             <hr class="w-50 ms-auto">
            <p><i class="bi bi-download fw-bolder pe-1"></i>{{downloads}} Downloads</p>
            <p><i class="bi bi-eye fw-bolder pe-1" ></i> {{views}} Views</p>
          </div>
        </div>
        <!-- scrollspy4 -->
        <br>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <p id="scrollspyHeading4" class="text-start float-start">{{passedData.description}}</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <button class="btn btn-sm px-3 rounded-pill float-end mx-auto text-light" @click="downloadFile(passedData.identity)" :disabled="passedData.userAccess == 'true'" style="background-color:#e6b600">
              <i class="bi bi-file-earmark-arrow-down fw-bolder me-1" v-if="!isLoading"></i>
              <div v-if="isLoading" class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              {{isLoading ? 'downloading' : 'download'}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 py-lg-5 d-none d-md-block">
      
      <ul class="list-group list-group-flush ps-3  text-start list-unstyled" @click="getDataset(passedData)">
        <br>
        <h5 class=" nav-link fs-6 fw-bold">On this page</h5>
        <hr class="w-75 ms-3 my-0">

        <li class="nav-item my-1"><a class="nav-link" href="#scrollspyHeading1">Description</a></li>
        <li class="nav-item my-1"><a class="nav-link" href="#scrollspyHeading2">Dataset Preview</a></li>
        <li class="nav-item my-1"><a class="nav-link"  href="#scrollspyHeading3">Activity & Summary </a></li>
        <li class="nav-item my-1"><a class="nav-link" href="#scrollspyHeading4">License | Download</a></li>
      </ul>
<!-- <br><br><br> -->
    </div>
    
  </div>
  
  <div class="text-center my-5" v-if="!dataset">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
          views:null,
          downloads:null,
          dataset:null,
          passedData:[],
          noRows:null,
          noCols:null,
          refCount: 0,
          isLoading: false,
          logId:null,
        }
    },
    
    computed: {
      isDisabled: function(){
          return this.passedData.userAccess;
      }
    },
    beforeRouteLeave (to, from , next) {
      if(to.name == "Datasets"){
        next()
      }
      if(to.name != "Datasets"){
        this.setCookie('DataToken', null, 1)
        next()
      }
    },  
    mounted(){
      this.passedData = this.$route.params      
      console.log(this.passedData)
      if (Object.keys(this.passedData).length === 0 && this.passedData.constructor === Object) {
        this.$router.push({name:'Datasets'})
      }else{
        this.getDataset(this.passedData)
        this.addlog()
        this.downloads =  this.passedData.dwonloads
        this.views = this.passedData.datasetviews.length
      }
    },
    methods:{
          addlog(){
            var DataToken = this.getCookie('DataToken')

            axios.post(`https://aghub.miphost.com/api/datasets/addlog`, 
              {
                datasetId:this.passedData.id,
              },
              { headers:{'Authorization': `Bearer ${DataToken}`}} 
            )
            .then( response =>{
                this.logId = response.data.id
            })
            .catch( error => {
                console.log(error);
            }) 
          },
           updatelog(){
            var DataToken = this.getCookie('DataToken')

            axios.get(`https://aghub.miphost.com/api/datasets/updatelog/${this.logId}`,
              { headers:{'Authorization': `Bearer ${DataToken}`}} 
            )
            .then( response =>{
            })
            .catch( error => {
                console.log(error);
            }) 
          },
          getDataset(passedData){
            var DataToken = this.getCookie('DataToken')

            axios.post('https://aghub.miphost.com/api/datasets/',
              { 
                sheetId:passedData.identity,
                range:passedData.gName
              },
              { headers:{'Authorization': `Bearer ${DataToken}`}}
            ).then(response =>{
              console.log(response.data)
              this.dataset = response.data.values
              this.noRows = response.data.values.length
              this.noCols = response.data.values[0].length
            }).catch(error =>{
              console.log(error.response.data)
              this.errorMsg = error.response.data
                setTimeout(() => {
                  this.errorMsg = null
              }, 2000);
            })
          },
          forceFileDownload(response, title) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', title)
          document.body.appendChild(link)
          link.click()
        },
          downloadFile(id){
            axios.interceptors.request.use((config) => {
                this.setLoading(true);
                return config;
                }, (error) => {
                this.setLoading(false);
                return Promise.reject(error);
            });

            axios.interceptors.response.use((response) => {
                this.setLoading(false);
                return response;
                }, (error) => {
                this.setLoading(false);
                return Promise.reject(error);
            });
            var DataToken = this.getCookie('DataToken')
            axios.get(`https://aghub.miphost.com/api/datasets/download/${id}`,
              { headers:{'Authorization': `Bearer ${DataToken}`},
               responseType: 'arraybuffer',
              }
              
            ).then(response =>{
              this.forceFileDownload(response, `Dataset ${new Date().toDateString()}.xlsx`)
              this.updatelog()
              
            }).catch(error =>{
              console.log(error)
            })
          },
          setLoading(isLoading) {
            if (isLoading) {
                this.refCount++;
                this.isLoading = true;
            } else if (this.refCount > 0) {
              this.refCount--;
              this.isLoading = (this.refCount > 0);
            }
          },
          getCookie(cname) {
          let name = cname + "=";
          let ca = document.cookie.split(';');
          for(let i = 0; i < ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
              c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
              }
          }
          return "";
        },
        setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
table{
  font-family: 'Space Mono', sans-serif !important;
}

    .btn:hover{
        background-color:#306102 !important;
    }
    
.right-side{
  /* background-color: lightblue; */
  height: 90%;
  overflow: auto;
}
.nav-link{
  color: #212529 !important;
  font-size: smaller;
  padding: .2rem 1rem !important;
}
.nav-item:hover{
  background-color: #c2213c41;
  border-radius: 50px;

}
/* Track */
::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 50px !important;
}
::-webkit-scrollbar-track:hover {
  background: #cccccc !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  -webkit-overflow-scrolling: touch;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(33 37 41);
  border-radius: 50px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212529;
  border-radius: 50px !important;
}

</style>


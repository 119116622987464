<template>
  <div class="container my-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Middle Belt</a></li>
        <li class="breadcrumb-item"><a href="#">Vegetables</a></li>
        <li class="breadcrumb-item active" aria-current="page">Double Win(AP)</li>
        <li class="breadcrumb-item active" aria-current="page">Medium Investment </li>
      </ol>
    </nav>

    <div class="row row-cols-md-2 justify-content-center row-cols-lg-3">
      <div class="col-lg-4" v-for="(data, index) in bundle" :key="index">
        <div class="card card-margin">
          <div class="card-body pt-3" style="min-height: 80%">
            <div class="widget-49">
              <div class="widget-49-title-wrapper">
                <div class="widget-49-meeting-info" style="width: 40%">
                              <span class="badge bg-secondary rounded-0 text-bg-secondary">Bundle Item ({{index + 1}})</span>

                  <!-- <span class="widget-49-pro-title fw-bold text-uppercase">{{story.title}}</span> -->
                  <!-- <span class="widget-49-meeting-time">12:00 to 13.30 Hrs</span> -->
                </div>
              </div>
              <p class="widget-49-meeting-points">{{ data }}</p>
            </div>
          </div>
          <!-- <div class="widget-49-meeting-action card-footer border-0 bg-transparent  d-flex justify-content-between">
                <span class="text-muted fst-italic" style="font-size:12px">Data Sourced by {{story.institute}}</span>
                <router-link class="btn rounded-pill btn-sm px-4" style="background-color:#e6b600" :to="{name:'Storysetchartboard', params:{data:JSON.stringify(storyset[index].category)}}" >View <i class="bi bi-arrow-right-short"></i> </router-link>
            </div> -->
        </div>
      </div>
    </div>
    <br /><br />
    <br /><br />
  </div>
</template>
<script>
export default {
  data() {
    return {
      bundle: [
        "Smart locally-adapted variety",
        "Cereal – legume integration",
        "Bioproducts for soil amendment",
        "Organic fertilizer",
        "Integrated Pest and Disease Management",
        "Inorganic fertilizers",
        "Diversification of crop with improved breeds of goat",
        "Agroforestry option that have high food and feed benefits",
        "Reforestation with multifunctional trees and forages",
        "Improved irrigation or water management",
      ],
    };
  },
  computed: {},
};
</script>

<style scoped>
.card-margin {
  margin-bottom: 1.875rem;
}

.badge{
  background: #2c3e50 !important;
  font-weight: 200;
}

.card-footer a {
  color: #fff;
  transition: background-color 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.card-footer a:hover {
  background-color: #306102 !important;
}

.card {
  border: 0;
  box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #e6e4e9;
  border-radius: 8px;
  height: 90%;
}
    .card:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

.card .card-header.no-border {
  border: 0;
}
.card .card-header {
  background: none;
  padding: 0 0.9375rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  min-height: 50px;
}
.card-header:first-child {
  border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
}

.widget-49 .widget-49-title-wrapper {
  display: flex;
  align-items: center;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e6b60078;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f0fafb;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #68cbd7;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-meeting-info
  .widget-49-pro-title {
  color: #3c4142;
  font-size: 14px;
}

.widget-49
  .widget-49-title-wrapper
  .widget-49-meeting-info
  .widget-49-meeting-time {
  color: #b1bac5;
  font-size: 13px;
}

.widget-49 .widget-49-meeting-points {
  font-weight: 400;
  font-size: 15px;
  margin-top: 0.5rem;
}

.widget-49 .widget-49-meeting-action {
  text-align: right;
}

.widget-49 .widget-49-meeting-action a {
  text-transform: uppercase;
}

.breadcrumb {
  margin: 5px;
  background-color: green;
  overflow: hidden;
}
.breadcrumb li {
  position: relative;
  height: 2rem;
  line-height: 2rem;
  padding-left: 1.2rem !important;
  padding-right: 0.6rem;
  color: white !important;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: bold;
}
.breadcrumb li a {
  height: 2rem;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}
.breadcrumb li:before {
  display: none;
}
.breadcrumb li:after {
  position: absolute;
  right: -1.7rem;
  top: 0;
  content: "";
  display: block;
  width: 1rem;
  height: 1rem;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  border-top: 1rem solid transparent;
  border-left: 1rem solid rgb(0, 255, 13);
}
.breadcrumb li:first-child {
  background-color: #02ef1e;
  z-index: 20;
  padding-left: 0.85rem !important;
}
.breadcrumb li:first-child:after {
  border-left: 12px solid #02ef02;
}
.breadcrumb li:nth-child(2) {
  background-color: #02da2d;
  z-index: 19;
}
.breadcrumb li:nth-child(2):after {
  border-left: 12px solid #02da14;
}
.breadcrumb li:nth-child(3) {
  background-color: #02c60f;
  z-index: 18;
}
.breadcrumb li:nth-child(3):after {
  border-left: 12px solid #02c60f;
}
.breadcrumb li:nth-child(4) {
  background-color: #01b213;
  z-index: 17;
}
.breadcrumb li:nth-child(4):after {
  border-left: 12px solid #01b222;
}
.breadcrumb li:nth-child(5) {
  background-color: #01409e;
  z-index: 16;
}
.breadcrumb li:nth-child(5):after {
  border-left: 12px solid #01409e;
}
.breadcrumb li:nth-child(6) {
  background-color: #013889;
  z-index: 15;
}
.breadcrumb li:nth-child(6):after {
  border-left: 12px solid #013889;
}
.breadcrumb li:hover {
  filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.5));
}
.breadcrumb.breadcrumb-responsive {
  flex-wrap: nowrap !important;
  overflow-x: scroll;
}
.breadcrumb.breadcrumb-responsive li,
.breadcrumb.breadcrumb-responsive li a {
  white-space: nowrap !important;
}
</style>

<template>
  <div style="height: 70vh; width: 100%; mx-5" class="position-relative">
    <div
      id="searchbar"
      class="ui container position-absolute w-lg-25 w-sm-50 mt-3"
    >
      <!-- <div class="ui centered grid">
        <div class="row">
          <div class="column">
            <div class="ui action input dropdown">
              <input
                type="text"
                id="searchInput"
                class="form-control shadow rounded-5 form-control-sm dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-model="searchInput"
                @keyup="filterResults()"
                placeholder="Search..."
              />
              <ul class="dropdown-menu w-100">
                <li>
                  <span
                    class="dropdown-item" style="font-size:12px"
                    @click="changeCenter(farm.center_point)"
                    v-for="(risk, i) in risks"
                    :key="i"
                    >{{ farm.ghana_card_no }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <l-map ref="map" v-model:zoom="zoom" :center="center">
      <l-tile-layer
         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
                :attribution="attribution"

        name="OpenStreetMap"
      ></l-tile-layer>
      <span v-if="risks.length > 0">
        <l-circle-marker v-for="(risk, i) in risks" :key="i" :lat-lng="ConvertLocation(risk.location)" :color="risk.riskZone.color" :radius="20"  >
          <l-popup ref="popup"> 
            <h5 ><i class="bi bi-radioactive px-3 text-danger btn-font"></i> {{ risk.name }}</h5>
            <button class="btn-sm text-light btn-dark btn d-block w-100" @click="$emit('more_info', risk)">more</button>
          </l-popup>
        </l-circle-marker>
      </span>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import {
  LMap,
  LTileLayer,
  LPolygon,
  LPopup,
  LMarker,
  LCircleMarker
} from "@vue-leaflet/vue-leaflet";
import axios from "axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    LPopup,
    LMarker,
    LCircleMarker,
    ColorPicker 
  },
  emits:['more_info'],
  props:{
    risks:Array
  },
  data() {
    return {
      attribution: '&copy; <a href="https://www.esri.com/en-us/home">Esri</a>',
      msg: '',
      jwt:{},
      centerCoordinates: "",
      riskLocation:{},
      index: 0,
      districtData: [],
      searchInput: "",
      zoom: 7,
      center: [7.998338, -1.213402],
      geojson: null,
      geoStyler: (feature) => ({
        opacity: feature.properties.code / 100000,
        radius: 8,
        fillColor: "#198754",
        color: "#fff",
        weight: 1,
        fillOpacity: 0.5,
      }),
    };
  },
  created() {
  },
  methods: {
    onPopupOpen(data) {},
    closePopup() {},
    


    changeCenter(centerPoint) {
      this.center = centerPoint.split(", ").map(Number);
      this.zoom = 15;
    },

    ConvertLocation(location) {
      let riskLocation = JSON.parse(location) 
      return riskLocation.coordinates.split(",")
       
    },

    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  watch: {
    polygonPoints: {
      handler(newPoints) {
        if (newPoints.length && newPoints[0]) {
          this.polygonPoints[this.numPoints - 1] = newPoints[0];
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.btn-font{
  font-size:15px;
}
#searchbar {
  top: 10%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

#formBar {
  top: 50%;
  right: 1%;
  transform: translate(0%, -50%);
  z-index: 1000;
}

#addBtn {
top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

@media (max-width: 575.98px) {
  .w-sm-50 {
    width: 50%;
  }
  #searchbar {
    top: 5%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  #formBar {
  top: 50%;
  right: 2%;
  transform: translate(0%, -50%);
  z-index: 1000;
}
}

@media (min-width: 576px) {
  .w-lg-25 {
    width: 25%;
  }
}
</style>

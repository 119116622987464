<template>
    <header>
        <div class="collapse text-light" id="navbarHeader" >
            <div class="container">
                <div class="row">
                    <div class="col-sm-8 col-md-7 py-4">
                        <h4 class="text-light">About</h4>
                        <p class="text-light">
                            Add some information about the album below, the author, or any
                            other background context. Make it a few sentences long so folks
                            can pick up some informative tidbits. Then, link them off to some
                            social networking sites or contact information.
                        </p>
                    </div>
                    <div class="col-sm-4 offset-md-1 py-4">
                        <h4 class="text-light">Menu</h4>
                        <hr style="color:#e6b600 !important">
                        <ul class="list-unstyled">
                            <li class="w-100 px-2 rounded-pill">
                                <router-link to="/main/services" class="text-light " @click="collapseCloseOnclick()">
                                Services
                                </router-link>
                            </li>
                            <li class="w-100 px-2 rounded-pill">
                                <router-link to="/main/dashboard" class="text-light" @click="collapseCloseOnclick()">
                                    Dashboards
                                </router-link>
                            </li>
                            <li class="w-100 px-2 rounded-pill">
                                <router-link to="/main/storyset" class="text-light" @click="collapseCloseOnclick()">
                                    StorySet
                                </router-link>
                            </li>
                            <li class="w-100 px-2 rounded-pill">
                                <router-link to="/main/datasets" class="text-light" @click="collapseCloseOnclick()">
                                    Dataset
                                </router-link>
                            </li>
                            <li class="w-100 px-2 rounded-pill">
                                <router-link to="/main/resources" class="text-light" @click="collapseCloseOnclick()">
                                    Resources
                                </router-link>
                            </li>
                            <li class="w-100 px-2 rounded-pill">
                                <router-link to="/main/faq" class="text-light faq"  @click="collapseCloseOnclick()">
                                    Contact & FAQ 
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="navbar navbar-dark shadow-sm" style="background-color:#2b5207 !important">
            <div class="container d-flex justify-content-between">
                <a href="#" class="navbar-brand d-flex align-items-center w-50">
                    <router-link to="/" class=" text-decoration-none d-flex align-content-center ">
                        <img style="width:18%" class="ms-0 float-start" src="../assets/logo3.png" >
                        <h6 style="font-size:15px" class="text-light my-2 text-capitalize my-auto "><i class="bi bi-arrow-right-short"></i> {{ $route.name }}</h6>
                    </router-link>
                </a>
                <button class="navbar-toggler" style="border-color:#ffffffa6!important" type="button"
                    ref="toggleCollapse" data-bs-toggle="collapse" data-bs-target="#navbarHeader"
                    aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    name: "Navbar",
    methods: {
        collapseCloseOnclick() {
            this.$refs.toggleCollapse.click()
        }
    }
};
</script>
<style scoped>
#navbarHeader{
    background-color:#306102a6 !important
}
.nav-link:hover {
    opacity: 0.8;
}

li a {
    padding-inline-end: 100%;
}

li:hover {
    background-color: #e6b4007b ;

}

.col-sm-8,
.col-sm-4 {
    text-align: start;
}

.navbar-toggler {
    padding: .1rem .4rem !important;
    font-size: 1rem !important;
}

li a {
    text-decoration: none;
}

li a:hover {
    opacity: .8;
    ;
}
.faq{
    display:contents
}
</style>
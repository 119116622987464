<template>
  <div class="bg-img">
    <form action="/action_page.php" class="container">
      <div class="input-group input-group-sm">
        <span class="input-group-text"
          ><img src="https://cdn-icons-png.flaticon.com/512/2917/2917995.png" height="15"/></span>
        <select
          class="form-select"
          id="inputGroupSelect01"
          v-model="searchCrop"
        >
          <option selected value="default">Select CropType</option>
          <option v-for="(res, i) in crops" :key="i" :value="res.crop" selected>{{capitalize(res.crop)}}</option>
        </select>
        <span class="input-group-text"
          ><img src="https://cdn-icons-png.flaticon.com/512/684/684908.png" height="15"/></span>
        <select
          class="form-select"
          id="inputGroupSelect01"
          v-model="searchRegion"
        >
          <option selected value="default">Choose Region</option>
          <option v-for="(res, i) in regions" :key="i" :value="res.region">{{getRegionFullName(res.region)}}</option>
        </select>
      </div>
    </form>
  </div>


  <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-brown" disabled type="checkbox" />
    <label class="form-check-label">Land Preparation</label>
  </div>
  <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-info" disabled type="checkbox" />
    <label class="form-check-label">Rain </label>
  </div>
  <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-success" disabled type="checkbox" />
    <label class="form-check-label">Planting</label>
  </div>
  <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-orange" disabled type="checkbox" />
    <label class="form-check-label">Fertilizer App(Basal)</label>
  </div>
  <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-secondary" disabled type="checkbox" />
    <label class="form-check-label">Weeding</label>
  </div>
  <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-wine" disabled type="checkbox" />
    <label class="form-check-label">Fertilizer App(Top Dressing)</label>
  </div>
    <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-danger" disabled type="checkbox" />
    <label class="form-check-label">Pesticide App.</label>
  </div>
  <div class="form-check form-check-inline mt-4">
    <input class="form-check-input bg-warning" disabled type="checkbox" />
    <label class="form-check-label"> Harvesting </label>
  </div>
  <div class="calendar">
    <Calendar 
      class="calendar_item"
      v-for="(item, index) in selectionChange"
      :key="item.id"
      :calendar_data="selectionChange[index]"
    />
    <p
      v-show="selectionChange.length == 0"
      class="fw-bolder fs-6 my-5 text-danger"
    >
      No result found, Please make your selections
    </p>
  </div>

</template>

<script>
// @ is an alias to /src
import Calendar from "../components/calendarComponent.vue";
import Navbar from "../components/navbar.vue";
import Footer from "../components/footer.vue";
import axios from 'axios';

export default {
  name: "CroppingCalendar",
  components: {
    Calendar,
    Navbar,
    Footer,
  },
  data() {
    return {
      searchRegion: "default",
      searchCrop: "default",
      cropdata:[],
      crops:[],
      regions:[]
    };
  },
  mounted(){
    this.getCropData()
    this.getCrops()
    this.getRegions()
  },
  computed: {
    selectionChange() {
      var results = [];
      for (var i = 0; i < this.cropdata.length; i++) {
        if (
          this.cropdata[i].crop == this.searchCrop &&
          this.cropdata[i].region == this.searchRegion
        ) {
          results.push(this.cropdata[i]);
        }
      }
      console.log(results)
      return results;
    },
  },
  methods:{
    cropwithOnset(){
    var landpreparation, planting, basalfertApp, rainOnset, topDressFertApp, weedingOne, weedingTwo, droughtStess, perstidideApp, cobForm, haverting


      for (var i = 0; i < this.cropdata.length; i++) {

        rainOnset = new Date(this.cropdata[i].rain)
        landpreparation = new Date(rainOnset).setDate(rainOnset.getDate() - 30); 
        this.cropdata[i].landpreparation = [new Date(landpreparation)]

        planting = new Date(landpreparation).setDate(new Date(landpreparation).getDate() + 7);
        this.cropdata[i].planting = [new Date(planting)]

        basalfertApp = new Date(planting).setDate(new Date(planting).getDate() + 14); //*** */
        this.cropdata[i].firtilizerB = [new Date(basalfertApp)]

        topDressFertApp = new Date(basalfertApp).setDate(new Date(basalfertApp).getDate() + 21);//** */
        // this.cropdata[i].firtilizerT.push(new Date(topDressFertApp))
        this.cropdata[i].firtilizerT = [new Date(topDressFertApp)]


        weedingOne = new Date(planting).setDate(new Date(planting).getDate() + 30); 
        this.cropdata[i].weeding = [new Date(weedingOne)]

        weedingTwo = new Date(weedingOne).setDate(new Date(weedingOne).getDate() + 30); //** */
        this.cropdata[i].weeding.push(new Date(weedingTwo))

        // droughtStess = new Date(planting).setDate(new Date(planting).getDate() + 40);//** */
        perstidideApp = new Date(planting).setDate(new Date(planting).getDate() + 40);
        this.cropdata[i].pesticide = [new Date(perstidideApp)]
        
        // cobForm = new Date(planting).setDate(new Date(planting).getDate() + 42); 
        haverting = new Date(planting).setDate(new Date(planting).getDate() + 70);
        this.cropdata[i].Harvesting = [new Date(haverting)]
      }
    },
    getCropData(){
      axios.get('https://aghub.miphost.com/api/croppingcalendar')
      .then(response => {
        this.cropdata = response.data
        this.cropwithOnset()

      })
      .catch(error =>{
        console.log(error)
      })
    },
    getCrops(){
      axios.get('https://aghub.miphost.com/api/croppingcalendar/crops')
      .then(response => {
        this.crops = response.data
      })
      .catch(error =>{
        console.log(error)
      })
    },
    getRegions(){
      axios.get('https://aghub.miphost.com/api/croppingcalendar/regions')
      .then(response => {
        this.regions = response.data
      })
      .catch(error =>{
        console.log(error)
      })
    },
    getRegionFullName(shortHand) {
    let regionName = '';

    switch (shortHand.toLowerCase()) {
      case 'ah':
        regionName = 'Ashanti Region';
        break;
      case 'ba':
        regionName = 'Brong-Ahafo Region';
        break;
      case 'ce':
        regionName = 'Central Region';
        break;
      case 'ea':
        regionName = 'Eastern Region';
        break;
      case 'gr':
        regionName = 'Greater Accra Region';
        break;
      case 'nr':
        regionName = 'Northern Region';
        break;
      case 'oe':
        regionName = 'Oti Region';
        break;
      case 'sv':
        regionName = 'Savannah Region';
        break;
      case 'ue':
        regionName = 'Upper East Region';
        break;
      case 'uw':
        regionName = 'Upper West Region';
        break;
      case 'wr':
        regionName = 'Western Region';
        break;
      default:
        regionName = 'Invalid shorthand';
    }
    return regionName;
  },
  capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
  }
  },
};
</script>

<style scoped>
label{
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: 'Open Sans', sans-serif !important;
}
.calendar {
  padding: 10px;
  padding-bottom: 50px; /* Height of the footer */
}
* {
  box-sizing: border-box;
}
.form-check-input:disabled {
  opacity: 1 !important;
}
.form-check-input {
  border: none;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 1 !important;
}
.bg-img {
  /* The image used */
  background-image: url("https://images.unsplash.com/photo-1499529112087-3cb3b73cec95?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80");

  /* Control the height of the image */
  min-height: 250px;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.form-select {
  background-color: #ffffffb9;
  font-size: 0.8rem;
  color: rgb(43, 40, 40);
  font-weight: 500;
}
.form-select option {
  background-color: rgba(255, 255, 255, 0.521);
  border-radius: 2px;
  opacity: 0.5;
}
.form-select:focus {
  border-color: #86b7fe40;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 3%);
}
/* Add styles to the form container */
.bg-img .container {
  position: absolute;
  margin: 20px;
  max-width: 40%;
  padding: 12px;
  border-radius: 5px;
  background-color: rgba(85, 85, 85, 0.514);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 530px) {
  .bg-img .container {
    max-width: 80%;
    left: 45%;
  }
}

@media screen and (max-width: 530px) {
  .calendar {
    margin-inline: auto;
    width: 80%;
    overflow-x: auto;
  }
  .calendar::after {
    content: "<ouPlease Scroll";
    font-size: 10px;
    /* margin-bottom: 20%; */
  }
  .calendar_item {
    width: 1000px;
  }
}
</style>

<style scoped>
.progress-bar {
  color: #efefef;
}
.bg-brown {
  background-color: rgb(155, 104, 37) !important;
}
.bg-orange {
  background-color: rgb(148, 45, 173) !important;
}
.bg-success {
  background-color: rgb(74, 187, 108) !important;
}
.bg-warning {
  background-color: rgb(24, 186, 35) !important;
}
.bg-info {
  background-color: rgb(74, 174, 204) !important;
}
.bg-secondary {
  background-color: rgb(95, 95, 95) !important;
}
.bg-danger {
  background-color: rgb(255, 109, 73) !important;
}
.bg-wine{
  background-color: rgb(150, 58, 58) !important;
}
</style>

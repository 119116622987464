<template>
<div class="container" v-if="!submit">
    <Stepper :currentForm="formStage" />
    <form ref="form" class="row g-3 my-5">
    <div class="personal-info text-start row d-flex justify-content-around " v-if="formStage1">
        <div class="col-md-6 ">
            <label for="Firstname" class="form-label">Ecological Zone</label>
            <select class="form-select form-select-sm " aria-label="Default select example" v-model="questionnaire.zone">
                <option value="" disabled selected>Open this select menu</option>
                <option value="NB">Northern Belt</option>
                <option value="MB">Middle Belt</option>
                <option value="SB">Southern Belt</option>
            </select> 
       </div>
        <div class="col-md-6 ">
            <label for="lastname" class="form-label">Value Chain</label>
            <select class="form-select form-select-sm " aria-label="Default select example" v-model="questionnaire.valueChain">
                <option value="" disabled selected>Open this select menu</option>
                <option value="NB">Maize</option>
                <option value="MB">Vegetables</option>
                <option value="SB">Soybean</option>
                <option value="SB">Yam</option>
                <option value="SB">Cocoa</option>
                <option value="SB">Goat</option>
                <option value="SB">Poultry</option>

            </select> 
        </div>
        <!-- <div class="col-md-4">
            <label for="othernames" class="form-label">Other Names</label>
            <input type="text" class="form-control form-control-sm" placeholder="Mensah" v-model="bio.othernames" id="othernames" />
        </div>
        <div class="col-md-4">
            <label for="inputPassword4" class="form-label text-success">DoB</label>
            <input type="date" class="form-control form-control-sm" v-model="bio.dob" id="inputPassword2" />
        </div>
        <div class="col-md-4">
            <label for="inputPassword4" class="form-label text-danger">DoD</label>
            <input type="date" class="form-control form-control-sm" v-model="bio.dod" id="inputPassword4" />
        </div> -->

        
        <div class="col-12 mt-4">
            <button class="btn btn-sm rounded-pill next px-5  float-end" @click="next">next</button>
            <button class="btn btn-sm rounded-pill prev px-5  float-end mx-2" @click="previous">prev</button>
        </div>
    </div>
    <!-- occupation and terretries -->
    <div class="row d-flex justify-content-start text-start" v-if="formStage2">
        <div class="col-md-4">
            <label for="inputPassword4" class="form-label">1. On a scale of 1 to 10, how effective is a drought-tolerant variety in conserving water</label>
            <input type="number" class="form-control form-control-sm" v-model="questionnaire.q1" id="q1" />
        </div>
        <div class="col-md-4">
            <label for="inputPassword4" class="form-label ">2. Is an early maturing variety generally harvested sooner than a late maturing one? </label>
            <select class="form-select form-select-sm" aria-label="Default select example" v-model="questionnaire.q2">
                <option value="" disabled selected>Open this select menu</option>
                <option value="true">yes</option>
                <option value="false">no</option>
            </select>         </div>
        <div class="col-md-12 my-3">
            <label for="lastname" class="form-label">3.  Have you implemented Integrated Pest and Disease Management (IPDM) practices, combining multiple approaches to control pests and diseases on your farm? </label>
            <select class="form-select form-select-sm" aria-label="Default select example" v-model="questionnaire.q3">
                <option value="" disabled selected>Open this select menu</option>
                <option value="true">yes</option>
                <option value="false">no</option>
            </select> 
        </div>
        <div class="col-md-4">
            <label for="q4" class="form-label">4. On a scale of 1 to 5, how much do integrated practices reduce your reliance on chemical pesticides? </label>
            <input type="number" class="form-control form-control-sm" v-model="questionnaire.q4" id="q4" />
        </div>
        <div class="col-12 mt-4">
            <button class="btn btn-sm rounded-pill next px-5  float-end" @click="next">next</button>
            <button class="btn btn-sm rounded-pill prev px-5  float-end mx-2" @click="previous">prev</button>
        </div> 
    </div>

    <div class="row d-flex justify-content-start text-start" v-if="formStage3">
        <div class="col-md-12 my-3">
            <label for="q5" class="form-label">1.  Do you actively employ risk management strategies, such as insurance, to protect your farm investments? </label>
            <select class="form-select form-select-sm" aria-label="Default select example" v-model="questionnaire.q5">
                <option value="" disabled selected>Open this select menu</option>
                <option value="true">yes</option>
                <option value="false">no</option>
            </select> 
        </div>
        <div class="col-md-4">
            <label for="q6" class="form-label">2. What is your budget range for agricultural investments this season? </label>
            <input type="number" class="form-control form-control-sm" v-model="questionnaire.q4" id="q6" />
        </div>
        <div class="col-md-12 my-3">
            <label for="q7" class="form-label">1.   Are You interested in learning about investment packages that promote crop diversification on your farm.</label>
            <select class="form-select form-select-sm" aria-label="Default select example" v-model="questionnaire.q7">
                <option value="" disabled selected>Open this select menu</option>
                <option value="true">yes</option>
                <option value="false">no</option>
            </select> 
        </div>
        <div class="col-md-8">
            <label for="q8" class="form-label">2. On a scale of 1 to 10, with 1 being low importance and 10 being highly important, how much emphasis do you place on sustainable farming practices in your investment decisions? </label>
            <input type="number" class="form-control form-control-sm" v-model="questionnaire.q4" id="q8" />
        </div>
        <div class="col-12 mt-4">
            <div class="mt-2">
            <p class="text-success fs-6 float-start mx-auto" v-show="responseMsg">Your process was Successful</p>
            <p class="text-danger fs-6 float-start mx-auto" v-show="errorMsg">{{error}}</p>
            <button class="btn btn-sm  px-5 next rounded-pill float-end" v-show="!dataToEdit" @click="addBio, submit=true">submit</button>
            <button class="btn btn-sm  px-5 next rounded-pill float-end" v-show="dataToEdit" @click.prevent="addBio">Update</button>
            <button class="btn btn-sm  px-5 prev rounded-pill float-end mx-2" @click="previous">previous</button>
        </div>
        </div> 
    </div>

    <!-- <span class="row"  v-show="formStage4">
        <div class="mt-2">
            <button class="btn btn-sm btn-primary float-end" @click="next">next</button>
            <button class="btn btn-sm btn-dark float-end mx-2" @click="previous">previous</button>
        </div>
    </span> -->

    <!-- <span v-show="formStage5">
        <div class="mt-2">
            <p class="text-success fs-6 float-start mx-auto" v-show="responseMsg">Your process was Successful</p>
            <p class="text-danger fs-6 float-start mx-auto" v-show="errorMsg">{{error}}</p>
            <button class="btn btn-sm btn-success float-end" v-show="!dataToEdit" @click="addBio">submit</button>
            <button class="btn btn-sm btn-success float-end" v-show="dataToEdit" @click.prevent="addBio">Update</button>
            <button class="btn btn-sm btn-danger float-end mx-2" @click="previous">previous</button>
        </div>
    </span> -->
</form>
</div>

<div class="container d-flex justify-content-center my-5" v-if="submit">
    <div class="card border-0 mb-3 shadow-lg py-4" style="max-width: 25rem;">
  <div class="card-body">
    <i class="bi bi-award-fill fs-1"></i>
    <h5 class="card-title fw-bold">Questionnaire Complete</h5>
    <p class="card-text">You are from our Middle Belt Zone and farming on Maize, you have a Double Win(AP) Membership and an intermediate investor.</p>
  </div>
  <div class="card-foote border-0r bg-transparent ">
    <button class="btn btn-sm btn-warning mx-1" @click="submit=false"><i class="fa-solid fa-pen-to-square"></i></button>
    <router-link class="btn btn-sm btn-success w-75" :to="{name:'bundles'}">View Bundle</router-link>
  </div>
</div>
</div>


</template>

<script>
import axios from 'axios'
import Stepper from '../components/stepbar.vue'
export default {
components:{
    Stepper
},
emits:[
    'progress',
    'borderstatus'
],
props:[
    'dataToEdit'
],
data() {
    return {
        questionnaire:
            {
                zone: null,
                valueChain: null,
                othernames: null,
                dod: null,
                dob: null,
                placeofBirth: null,
                hometown: null,
                finalResidence:null,
                FamilyName:null,
                url: [],
                territories: [],
                occupation: [],
                bioRelation: {},
                relatedAncestors:[],
                biography: null,
                causeofDeath:null,
                links:[]
            }
        ,
        errorMsg:false,
        error:null,
        responseMsg:false,
        submit:false,
        progress: 0,
        formStage: 1,
        formStage1: false,
        formStage2: false,
        formStage3: false,
        // formStage4: false,
        // formStage5: false,
    };
},
    mounted() {
        this.formStage1 = true;



    },
    created() {
        // axios.get('http://127.0.0.1:8000/api/'
        // ).then(response => {
        //     this.ancestors = response.data
        // }).catch(error => {
        //     console.log(error);
        // }) 
    },
    watch:{
        dataToEdit(newVal, oldVal){
            this.bio = 
                {
                    firstname: newVal.firstname,
                    lastname: newVal.lastname,
                    othernames: newVal.othernames,
                    dod: newVal.dod.split(' ')[0],
                    dob: newVal.dob.split(' ')[0],
                    placeofBirth: newVal.placeofBirth,
                    url:[],
                    territories: [],
                    occupation: [],
                    bioRelation: {},
                    links:[],   
                    hometown: newVal.hometown,
                    finalResidence:newVal.finalResidence,
                    FamilyName:newVal.FamilyName,
                    biography: newVal.biography,
                    causeofDeath:newVal.causeofDeath,
                }
            if(newVal.links){
                this.links = []
                JSON.parse(newVal.links).forEach(item => {
                this.links.push(item);
            });
            }
            this.territories =  []
            JSON.parse(newVal.territories).forEach(item => {
                this.territories.push({'place':item});
            });
            this.occupation =  []
            JSON.parse(newVal.occupation).forEach(item => {
                this.occupation.push({'institution':item});
            });
            this.relations =  []
            newVal.relations.forEach(item => {
                this.relations.push({'relativeName':item.relativeName, 'relativeRelation':item.relation });
            });
            this.relatedAncestors =  []
            newVal.relatedAncestors.forEach(item => {
                this.relatedAncestors.push({'relative':item.relative });
            });
            newVal.memories.forEach(item => {
                this.bio.url.push({'image':item.images, 'description':item.imageDescription });
            });
        }
    },
    methods: {
        addBio (e) {
            e.preventDefault();
            this.territories.forEach(item => {
                this.bio.territories.push(item.place)
            });
            this.occupation.forEach(item => {
                this.bio.occupation.push(item.institution)
            });
            this.relations.forEach(item => {
                this.bio.bioRelation[item.relativeName] = item.relativeRelation;
            });
            this.relatedAncestors.forEach(item => {
                this.bio.relatedAncestors.push(item.relative)
            });
            // var token = localStorage.getItem('token');
            var token = this.getCookie('token')
            if(this.dataToEdit){
                var urloption = `http://127.0.0.1:8000/api/update/${this.dataToEdit.id}`
            }else{
                var urloption = `http://127.0.0.1:8000/api/create`
            }

            axios.post(urloption, this.bio,
            {
                headers:{'Authorization': `Bearer ${token}`}
            }).then(response =>  {
                this.postFormData.append('newUser', response.data.ancestor_id);
                if(!this.files.length == 0){
                    axios.post('http://127.0.0.1:8000/api/storeimg', this.postFormData,
                    {
                        headers:{'Authorization': `Bearer ${token}`}
                    })
                    .then(response =>  {
                        this.$emit('borderstatus')   
                        
                    }).catch(error => {
                        console.log(error.response.data.message);
                        this.errorMsg = true
                        this.error = error.response.data.message
                    })
                }
                else{
                    this.$emit('borderstatus')   
                }
            }).catch(error => {
                console.log(error.response);
                this.errorMsg = true
                this.error = error.response.data.message
                    this.territories =  [
                        {
                            place: "",
                        },
                    ]
                    this.occupation = [
                        {
                            institution: "",
                        },
                    ]
                    this.relations= [
                        {
                            relativeName: null,
                            relativeRelation:null
                        },
                    ]
                    this.responseMsg = true
                    this.postFormData = new FormData(),
                    this.$refs.importfile.type = 'text'
                    this.$refs.importfile.type = 'file'
                })

        }, 
        next(e) {
        e.preventDefault();
        this.formStage++;
        if (typeof eval(`this.formStage${this.formStage}`) !== "undefined") {
            this.formStage1 = false;
            this.formStage2 = false;
            // this.formStage3 = false;
            // this.formStage4 = false;
            eval(`this.formStage${this.formStage} = true`);
            this.$emit("progress", this.formStage);
        } else {
            this.formStage--;
        }
        },
        previous(e) {
        e.preventDefault();
        if (this.formStage > 1) {
            this.formStage--;
            if (typeof eval(`this.formStage${this.formStage}`) !== "undefined") {
            this.formStage1 = false;
            this.formStage2 = false;
            this.formStage3 = false;
            // this.formStage4 = false;
            // this.formStage5 = false;
            eval(`this.formStage${this.formStage} = true`);
            this.$emit("progress", this.formStage);
            }
        }
        },
       
        getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
        },
    },
};
</script>

<style scoped>
    @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");
        .input-group-text {
    cursor: pointer;
    }
    label{
        font-size: smaller;
    }
    .cursor {
        cursor: pointer;
    }
    .container{
        /* height: 100vh; */
    }
    option{
        font-weight: 100;
        font-family: 'Raleway', sans-serif;
    }
    .prev{
        background-color:#8c8c8c;
        color:#fff

    }
    .prev:hover{
        background-color: #e6b600 !important;
                color:#fff
    }
    .next{
        background-color:#306102;
        color:#fff
    }
    .next:hover{
        background-color: #e6b600 !important;
                color:#fff

    }
    img {
        width: 100%;
        /* height: 4.5in; */
        object-fit: cover;
        transition: font-size 0.25s linear;
        -o-transition: font-size 0.25s linear;
        -moz-transition: font-size 0.25s linear;
        -webkit-transition: font-size 0.25s linear;
    }
    .form-select, .form-control:focus {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    }
    .link-btn {
    color: #fff !important;
    background-color: #cdcdcdb0 !important;
    border-color: #bababa !important;
    }
    .link-btn:hover {
        color: #fff !important;
        background-color: #b5b5b5 !important;
        border-color: #878787 !important;
    }
    .bi-x-circle{
        transition: transform .2s; /* Animation */
        display: inherit;
        margin-bottom: 0px;
    }
    .bi-x-circle:hover{
        color: red !important;
          transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }
    .card:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .input-group-text:hover{
        opacity: .9;
    }
</style>
<template>
    <div class="container">
        <h3 class="d-flex justify-content-around">
			<span>
				<router-link class="" :to="{ name: 'Leaflet' }"><i class="bi bi-arrow-left-circle-fill"></i></router-link>
			</span>
            Project Details
        </h3>

		<div id="page" class="ui segment bg-white p-4 shadow">
            <div class="ui segment text-justify">
                    <h3 class="ui header fw-bold">{{matchingProject["Name of Project"]}}</h3>
                    <p class="text-justify">Description: {{matchingProject["Description"]}}</p>
                    <p>Region: {{matchingProject["Region"]}}</p>
                    <p>District: {{matchingProject["District"]}}</p>
                    <p class="text-justify">Sponsor-Full: {{matchingProject["Sponsor-Full"]}}</p>
                    <p>System: {{matchingProject["System"]}}</p>
                    <p>ImplementingAgencies: {{matchingProject["ImplementingAgencies"]}}</p>
                    <p>Year Approved: {{matchingProject["Year Approved"]}}</p>
                    <p>Year Completed: {{matchingProject["Year Completed"]}}</p>
                    <p>Link: <a :href="matchingProject['Link']">{{matchingProject['Link']}}</a></p>
                    <p>CSA Technology: {{matchingProject["CSA Technology"]}}</p>
                    <p>Beneficiaries: {{matchingProject["Beneficiaries"]}}</p>
                    
                </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            matchingProject: {}
        }
    },
    created(){
        this.getData()
    },
    methods:{
            getData(){
                fetch('/data/ngo_data/csvjson.json')
                .then(response => response.json())
                .then(data => {
                    let searchString = this.$route.params.id
                    let matchingProject = data.find(project => project["UID"] === parseInt(searchString));
                    if (matchingProject) {
                        this.matchingProject = matchingProject
                    } else {
                    console.log("No matching project found.");
                    }
                })
                .catch(error => {
                    console.error('Error fetching JSON data:', error);
                });
            }
        }
}
</script>
<style>
    .text-justify{
        text-align: justify !important
    }
</style>
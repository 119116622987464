<template>
    <div class=" container text-start my-5 pb-3">
        <div class="container my-2">
            <input type="text" v-model="searchVal" @keydown="searchResource()" class="form-control form-control-sm rounded-pill w-25 bg-transparent" style="width: 3in!important;" placeholder="eg. ghana or farming">
        </div>

        <div class="container mb-2" v-for="(resource, i) in resources" :key="i">
            <label for="recources" class="fw-bold fs-4">{{resource.title}}</label>
            <p>{{resource.subtext}}</p>
            <div class=" d-flex justify-content-between">
                <a :href="resource.link"  class="btn btn-sm text-light  border-white rounded-pill px-4 ">Access<i class="bi bi-arrow-right-short"></i></a>           
                <p class="text-cark mx-5 fst-italic" style="font-size:12px">Data Source : <a :href="resource.sourcelink">{{resource.source}}</a></p>
            </div>   
            <hr class="w-75">          
        </div>
    </div>
    <br>
</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {
            resources:[],
            searchVal:''
        }
    },
    created(){
        // console.log(this.$route.params)
        if(!this.$route.params.searchValue) {
            this.getResources()
        }else{
            this.searchVal = this.$route.params.searchValue
            this.searchResource()
        }
    },
    methods:{
        getResources(){
            axios.get('https://aghub.miphost.com/api/resource')
            .then(res =>{
                this.resources = res.data
            })
            .catch(err =>{
                console.log(err.response.data)
            })
        },
        searchResource(){
            axios.get(`https://aghub.miphost.com/api/resource/${this.searchVal}`)
            .then(res =>{
                this.resources = res.data
            })
            .catch(err =>{
                console.log(err.response.data)
            })
        }
    }
}
</script>
<style scoped>
    .btn{
        background-color:#e6b600 !important;
        padding-top: 7px;
        width: 1.5in;
        height: 0.4in !important;
    }
        .btn:hover{
        background-color:#306102 !important;
    }
</style>
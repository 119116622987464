<template>
<div class="container my-5">
    <div class="row row-cols-md-2 justify-content-center row-cols-lg-3">
        <div  class="col-lg-4" v-for="(dataset, index) in datasets" :key="index">
            <div class="card card-margin">
                <div class="card-body pt-3 " style="min-height:80%">
                    <div class="widget-49">
                        <div class="widget-49-title-wrapper">
                            <div :class="widgetColors[Math.floor(Math.random() * 7)]">
                                <i :class="icons[Math.floor(Math.random() * 5)]"></i>
                            </div>
                            <div class="widget-49-meeting-info mx-auto" style="width: 70%;">
                                <span class="widget-49-pro-title fw-bold text-uppercase">{{dataset.name}}</span>
                            </div>
                        </div>
                        <p class="widget-49-meeting-points">{{dataset.description}}</p>
                    </div>
                </div>
                <div class=" card-footer bg-transparent border-0 text-end ">
                  <!-- <router-link class="btn btn-sm rounded-pill px-3" style="background-color:pink" to="/main/datasetpreview">Preview</router-link> -->
                  <button class="btn btn-sm rounded-pill px-3 text-light" style="background-color:#e6b600; margin-bottom: 30px;" @click="preview(dataset)">Preview <i class="bi bi-arrow-right-short"></i></button>
                </div>
            </div>
        </div>
    </div>
  <br><br>
  <!-- Button trigger modal -->
  <a data-bs-toggle="modal" data-bs-target="#exampleModal" ref="modalclick" hidden></a>
  <a data-bs-toggle="modal" data-bs-target="#addDataset"  ref="addDatasetTrig" hidden></a>
  <a data-bs-toggle="modal" data-bs-target="#config"  ref="configTrig" hidden></a>



  <a  class="float2 align-middle " @mouseover="searchbar=true" @mouseout="searchbar=false">
    <input type="text" v-show="searchbar"  class="form-control search form-control-sm " v-model="searchText"  @keyup="searchDataset()">
    <i class="bi bi-search  fs-5 my-float2 me-1" ></i>
  </a>

  <a  class="float align-middle" @click="adddataset()">
    <i class="bi bi-file-earmark-plus  fs-4 my-float"></i>
  </a>

  <a  class="float3 align-middle" @click="config()">
    <i class="bi bi-gear  fs-4 my-float"></i>
  </a>





<div class="modal fade" id="config" tabindex="-1" aria-labelledby="configLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content text-light" style="background-color:#fff !important">
        <div class="modal-header">
          <h6>Settings</h6>
          <button type="button" ref="closeupdateModal" class="btn-close btn-close-white me-1" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      <div class="modal-body">
       
        <div class=""  style="font-size:0.9rem" v-if="!usersetting">
          <label for="">Your Datasets</label>
          <div class="alert alert-warning alert-dismissible d-flex justify-content-between fade show text-start py-0 pe-0"  v-for="(data, i) in userdataset" :key="i" role="alert">
            <strong>{{data.name}}</strong>
           <p class="text-muted mb-0 mt-2" style="font-size:0.6rem"> {{new Date(data.updatedAt).toUTCString()}}</p>
            <button type="button" @click="deleteData(data.id)"  class="btn h-100 btn-sm deleteBtn rounded-0" data-bs-dismiss="alert" aria-label="Close"><i class="bi color-secondary bi-trash3-fill"></i></button>
          </div>
          <p v-if="userdataset.length <= 0">No Datasets found to your Account</p>
          <div v-if="isAdmin">
            <hr>
            <label class="text-start">Datalake</label>
            <p class="text-light" style="font-size:10px">(Click on Dataset Label to download & <i class="bi bi-folder-symlink"></i> to approve and move file into Datesets)</p>
            <div class="alert alert-light alert-dismissible fade show text-start py-1 d-flex justify-content-between px-0"  v-for="(data, i) in datalake" :key="i" role="alert">
              <strong @click="downloadFile(data.identity)" class="" style="cursor: pointer;width:250px">{{data.name}}</strong>
              <p class="text-muted mb-0" style="font-size:0.6rem; width:100px"> {{new Date(data.updatedAt).toUTCString()}}</p>
              <div class=" d-flex ">
                  <button type="button" @click="deleteLakeData(data.id)"  class="btn btn-sm deleteBtn mx-1" data-bs-dismiss="alert" aria-label="Close"><i class="bi color-secondary bi-trash3-fill"></i></button>
                  <button type="button" @click="moveFile(data.id)"  class="btn btn-sm moveBtn" data-bs-dismiss="alert" aria-label="Close"><i class="bi bi-folder-symlink"></i></button>
              </div>
             </div>
            <p v-if="datalake.length <= 0">No Datasets found in datalake</p>
          </div>
        </div>

        <div class="row text-start" v-if="usersetting">
          <div class="col-md-12">
                <label for="inputFirstname4" class="form-label" style="font-size:0.9rem">FullName</label>
                <input type="text" class="form-control form-control-sm" v-model="userData.name" required id="inputFirstname4" placeholder="John">
              </div>
              <div class="col-md-6">
                <label for="institution" class="form-label" style="font-size:0.9rem">Institution</label>
                <input type="text" class="form-control form-control-sm" v-model="userData.institution " required id="institution" placeholder="eg. CSIR">
              </div>
              <div class="col-md-6">
                <label for="profession" class="form-label" style="font-size:0.9rem">Profession</label>
                <input type="text" class="form-control form-control-sm" v-model="userData.profession" required id="profession" placeholder="eg. Researcher">
              </div>
              <div class="col-8">
                <label for="inputEmailReg" class="form-label" style="font-size:0.9rem">Email</label>
                <input type="email" class="form-control form-control-sm" v-model="userData.email" required id="inputEmailReg" placeholder="johndoe@***.com">
              </div>
              <div class="col-4">
                <label for="phone" class="form-label" style="font-size:0.9rem">Phone</label>
                <input type="text" maxlength="10" minlength="10" class="form-control form-control-sm" v-model="userData.phone" required id="phone" placeholder="02x xxx xxx7">
              </div>
              <div class="col-12">
                <label for="inputpassword" class="form-label" style="font-size:0.9rem">Password</label>
                  <input type="password"   class="form-control form-control-sm  d-inline password" v-model="userData.password" required>
                  <!-- <i class="bi bi-eye" id="togglePassword"  @click="showpassword()"></i> -->
              </div>
              <div class="col-12">
                <label for="inputpassword" class="form-label" style="font-size:0.9rem">Confirm Password</label>
                  <input type="password"   class="form-control form-control-sm  d-inline password" v-model="userData.repeat_password" required>
                  <!-- <i class="bi bi-eye" id="togglePassword" ></i> -->
              </div> 
              <div class="col-12">
                <button type="button" @click="updateUser()"  class="w-25 float-end btn btn-sm rounded-pill btn-light text-black px-3">Update</button>
              </div> 
        </div>
      </div>
      <div class="modal-footer">
        <p class="text-warning text-center mx-auto"  style="font-size:0.7rem !important">{{resMsg}}</p>

        <a href="#" class="text-light ms-2" @click="usersetting = true">
          <i class="bi bi-person-lines-fill"></i>
        </a>
        <a href="#" class="text-light ms-2" @click="usersetting = false">
          <i class="bi bi-gear"></i>
        </a>
      </div>
    </div>
  </div>
</div>







<!-- add data modal -->
<!-- Button trigger modal -->

<!-- Modal -->
<div class="modal fade" id="addDataset" tabindex="-1" aria-labelledby="addDatasetLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content text-light" style="background-color:#fff !important">
        <div class="modal-header">
          <h6> File Upload</h6>
          <button type="button" ref="closeuploadModal" class="btn-close btn-close-white me-1" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
      <div class="modal-body">
        <div class="">
            <div class="row g-2 text-start">
              <div class="col-12">
                <label for="filelabel" class="form-label" style="font-size:0.9rem">Label</label>
                <input type="text" v-model="fileData.label" class="form-control form-control-sm" required id="filelabel" placeholder="Enter a label for Dataset">
              </div>
              <div class="col-12">
                <label for="inputpassword" class="form-label" style="font-size:0.9rem">Description</label>
                  <textarea  v-model="fileData.description" maxlength="500"  class="form-control form-control-sm" placeholder="Please descricribe scope of dataset(max: 70 words)"></textarea>
              </div>
              <div class="col-6">
                <div class="form-check form-switch  text-start">
                  <input class="form-check-input" v-model="fileData.privacy" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                  <label class="form-check-label mb-1 text-secondary" v-if="fileData.privacy" style="font-size:12px" for="flexSwitchCheckDefault"><i class="bi bi-lock-fill me-1 mb-1 text-warning"></i> Private access to you only</label>
                  <label class="form-check-label mb-1 text-secondary" v-else style="font-size:12px" for="flexSwitchCheckDefault"><i class="bi bi-unlock-fill me-1 mb-1 text-success"></i> Public to the community</label>
                </div>
              </div>
              <div class="col-6" v-if="isAdmin">
                <div class="form-check form-switch  text-start">
                  <input class="form-check-input" v-model="cleaned" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                  <label class="form-check-label mb-1 text-secondary" v-if="!cleaned" style="font-size:12px" for="flexSwitchCheckDefault">Unclean Data</label>
                  <label class="form-check-label mb-1 text-secondary" v-else style="font-size:12px" for="flexSwitchCheckDefault"> <i class="bi bi-recycle me-1 mb-1 text-success"></i> Clean Data</label>
                </div>
              </div>
              <div class="col-12">
                <label for="fileUpload" style="font-size:0.9rem">Please</label>
                <input class="form-control form-control-sm fileUpload  mb-0"  @change="onFileChange" id="formFileSm"   ref="importfile"  type="file">
                <p class="mb-0 mt-1 text-muted text-start" style="font-size:12px">Accepted file formats; xlsx, csv, ods, </p>
                
                <p class="text-warning text-center mx-auto"  style="font-size:0.7rem !important">{{fileMsg}}</p>
                
                <button class="btn btn-sm rounded-pill px-3 fw-bold float-end" style="background-color:pink" @click="uploadfile()">Add</button>
              </div>
            </div>
            
          <!-- <input class="form-control form-control-sm" id="formFileSm" type="file" data-multiple-caption="{count} files selected" multiple>
          <label for="formFileSm" class=" formFilelable form-label px-5">Click here or drag file here</label> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- refistration Modal  -->
<div class="modal border-0 fade" id="exampleModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content text-light" style="background-color:#fff !important">
      <div class="modal-header" >
        <h6 class="modal-title   ms-2" id="exampleModalLabel">{{signin ? 'Sign In' : 'Join Us'}}</h6>
        <button type="button" ref="closeModal" class="btn-close btn-close-white me-1" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- signin form -->
        <form>
          <div  v-show="signin">
            <!-- signin -->
            <div class="row g-2 text-start">
              <div class="col-12">
                <label for="inputEmail" class="form-label" style="font-size:0.9rem">Email</label>
                <input type="email" v-model="userData.email" class="form-control form-control-sm" required id="inputEmail" placeholder="johndoe@***.com">
              </div>
              <div class="col-12">
                <label for="inputpassword" class="form-label" style="font-size:0.9rem">Password</label>
                  <input type="password" v-model="userData.password"   class="form-control form-control-sm  d-inline password">
                  <!-- <i class="bi bi-eye" id="togglePassword"  @click="showpassword()"></i> -->
              </div>
              
            </div>
            <div class="row mb-3">
              <div class="col-sm-4">
                <!-- <small class="float-start"><a href="#" >Forgot password</a></small> -->
              </div>
              <div class="col-sm-8">
                <small class="float-end text-success">Don't have an account <a href="#" @click="signin = false" class="text-success">Signup</a></small>
              </div>
            </div>
          </div>
            <!-- end signin -->
            <!-- signup -->
            <div class="row g-2 text-start" v-show="!signin">
              <div class="col-md-12">
                <label for="inputFirstname4" class="form-label" style="font-size:0.9rem">FullName</label>
                <input type="text" class="form-control form-control-sm" v-model="userData.name" required id="inputFirstname4" placeholder="John">
              </div>
              <div class="col-md-6">
                <label for="institution" class="form-label" style="font-size:0.9rem">Institution</label>
                <input type="text" class="form-control form-control-sm" v-model="userData.institution " required id="institution" placeholder="eg. CSIR">
              </div>
              <div class="col-md-6">
                <label for="profession" class="form-label" style="font-size:0.9rem">Profession</label>
                <input type="text" class="form-control form-control-sm" v-model="userData.profession" required id="profession" placeholder="eg. Researcher">
              </div>
              <div class="col-8">
                <label for="inputEmailReg" class="form-label" style="font-size:0.9rem">Email</label>
                <input type="email" class="form-control form-control-sm" v-model="userData.email" required id="inputEmailReg" placeholder="johndoe@***.com">
              </div>
              <div class="col-4">
                <label for="phone" class="form-label" style="font-size:0.9rem">Phone</label>
                <input type="text" maxlength="10" minlength="10" class="form-control form-control-sm" v-model="userData.phone" required id="phone" placeholder="02x xxx xxx7">
              </div>
              <div class="col-12">
                <label for="inputpassword" class="form-label" style="font-size:0.9rem">Password</label>
                  <input type="password"   class="form-control form-control-sm  d-inline password" v-model="userData.password" required>
                  <!-- <i class="bi bi-eye" id="togglePassword"  @click="showpassword()"></i> -->
              </div>
              <div class="col-12">
                <label for="inputpassword" class="form-label" style="font-size:0.9rem">Confirm Password</label>
                  <input type="password"   class="form-control form-control-sm  d-inline password" v-model="userData.repeat_password" required>
                  <!-- <i class="bi bi-eye" id="togglePassword" ></i> -->
              </div>          
              <small class="float-end mt-1 text-success">Alreay a member <a href="#" @click="signin = true" class="text-success">SignIn</a></small>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <p class="text-warning text-center mx-auto"  style="font-size:0.7rem !important">{{resMsg}}</p>
        <button type="button" class="btn btn-sm rounded-pill px-3 btn-light text-light" v-show="signin" @click="login()">Signin</button>
        <button type="button" @click="register()"  class="btn btn-sm rounded-pill btn-light text-black px-3" v-show="!signin">Join Us</button>
      </div>
    </div>
  </div>
</div>
</div>
</template>
<script>
import axios from 'axios'
import jwt_decode from "jwt-decode";

export default {
    data() {
        return {
            isAdmin:false,
            cleaned : false,
            searchText:'',
            userdataset:[],
            datalake:[],
            usersetting:false,
            userData:{
              name:'',
              email:'',
              phone:'',
              profession:'',
              institution:'',
              password:'',  
              repeat_password:'',
            },
            FormData: new FormData(),
            fileData:{
              privacy:false,
              label:'',
              description:''
            },
            resMsg:'',
            fileMsg:'',
            FormData: new FormData(),
            searchbar:false,
            signin:true, //if signup
            authenticated:false,
            datasets:[],
            icons:[
                'bi bi-bar-chart-line fs-2',
                'bi bi-bar-chart-line-fill fs-2',
                'bi bi-pie-chart-fill fs-2',
                'bi bi-graph-up fs-2',
                'bi bi-pie-chart fs-2'
            ],
            widgetColors:[
                'widget-49-date-primary',
                'widget-49-date-secondary',
                'widget-49-date-dark',
                'widget-49-date-danger',
                'widget-49-date-success',
                'widget-49-date-warning',
                'widget-49-date-info',
            ]
        }
    },
    beforeRouteLeave (to, from , next) {
      if(to.name == "DatasetPreview"){
        next()
      }
      if(to.name != "DatasetPreview"){
        this.setCookie('DataToken', null, 1)
        next()
      }
    }, 
    
    created(){
        
        this.getDatasets()
        
        var token = this.getCookie('DataToken')
        
        if (token == 'null' || token == null || token == '' ) {
          this.authenticated = false
          setTimeout(() => {
              this.$refs.modalclick.click();
                // console.log('hello')  
            }, 1000);
        }else{
          this.authenticated = true
          this.isAdmin = jwt_decode(token).isAdmin
        }
        //  console.log(this.authenticated) 
        
    },
    methods: {
       register(){
          axios.post('https://aghub.miphost.com/api/datasets/create',
               this.userData,
          ).then(response =>{
             this.setCookie('DataToken', response.data, 1)
             this.resMsg = 'Thank you for joining Us'
             setTimeout(() => {
                    this.resMsg = null
                    this.signin = true
                    this.authenticated = true
                    this.$refs.closeModal.click();
                }, 2000);

          }).catch(error =>{
              console.log(error.response.data)
              this.errorMsg = error.response.data
                setTimeout(() => {
                  this.errorMsg = null
              }, 2000);
          })
        },
        login(){
          axios.post('https://aghub.miphost.com/api/datasets/login',
               {
                email:this.userData.email,
                password:this.userData.password
               },
          ).then(response =>{
             this.setCookie('DataToken', response.data, 1)
             this.isAdmin = jwt_decode(response.data).isAdmin
             this.resMsg = 'You are currently logged In'
             setTimeout(() => {
                    this.resMsg = null
                    this.signin = true
                    this.authenticated = true
                    this.$refs.closeModal.click();
                }, 1000);

          }).catch(error =>{
              console.log(error.response.data)
              this.resMsg = error.response.data
                setTimeout(() => {
                  this.resMsg = null
              }, 1000);
          })
        },
        updateUser(){
          var DataToken = this.getCookie('DataToken')
          var decoded = jwt_decode(DataToken);
          let dataToUpdate = this.userData
          if(dataToUpdate.password == ""){
            delete dataToUpdate.password
            delete dataToUpdate.repeat_password
          }           
          axios.post(`https://aghub.miphost.com/api/datasets/update/${decoded.id}`,
            this.userData,
            { headers:{'Authorization': `Bearer ${DataToken}`}}
          ).then(response =>{
            console.log(response)
              this.resMsg = 'User has been updated'
              setTimeout(() => {
                this.resMsg = null
                this.$refs.closeupdateModal.click()
              }, 2000);
          }).catch(error =>{
              console.log(error)
               this.resMsg = error.response.data
                setTimeout(() => {
                   this.resMsg = null
                    this.$refs.closeupdateModal.click()
              }, 2000);
          })
        },
        deleteData(id){
          var DataToken = this.getCookie('DataToken')

          let text = "you are taking Permanent Action!\nEither OK or Cancel.";
            if (confirm(text) == true) {
              axios.get(`https://aghub.miphost.com/api/datasets/destroy/dataset/${id}`, 
                { headers:{'Authorization': `Bearer ${DataToken}`}})
            .then(response =>  {
                console.log(response.data)
                this.getDatasets()
              }).catch(error => {
                console.log(error);
                if(error.response.data === 'invalid token') this.authenticated = false
            })
          } else {
            text = "You canceled!";
          }
        },
        deleteLakeData(id){
          var DataToken = this.getCookie('DataToken')

          let text = "you are taking Permanent Action!\nEither OK or Cancel.";
            if (confirm(text) == true) {
              axios.get(`https://aghub.miphost.com/api/datasets/deletelake/${id}`, 
                { headers:{'Authorization': `Bearer ${DataToken}`}})
            .then(response =>  {
                this.getDatasets()
              }).catch(error => {
                console.log(error);
                if(error.response.data === 'invalid token') this.authenticated = false
            })
          } else {
            text = "You canceled!";
          }
        },
        showDataset(){
          var DataToken = this.getCookie('DataToken')

          axios.get('https://aghub.miphost.com/api/datasets/show', 
              { headers:{'Authorization': `Bearer ${DataToken}`}})
          .then(response =>  {
              this.userdataset = response.data
          }).catch(error => {
              console.log(error);
              if(error.response.data === 'invalid token') this.authenticated = false
          })
        },
         showDatalake(){
          var DataToken = this.getCookie('DataToken')

          axios.get('https://aghub.miphost.com/api/datasets/getlake', 
              { headers:{'Authorization': `Bearer ${DataToken}`}})
          .then(response =>  {
              this.datalake = response.data
          }).catch(error => {
              console.log(error);
              if(error.response.data === 'invalid token') this.authenticated = false
          })
        },
      adddataset(){
        if(!this.authenticated){
          this.$refs.modalclick.click();
        }else{
          this.$refs.addDatasetTrig.click()
        }
      },
      config(){
        if(!this.authenticated){
          this.$refs.modalclick.click();
        }else{
            const decoded = jwt_decode(this.getCookie('DataToken'));
            this.userData.name = decoded.name
            this.userData.email = decoded.email
            this.userData.phone = decoded.phone
            this.userData.institution = decoded.institution
            this.userData.profession = decoded.profession
          this.$refs.configTrig.click()
          this.showDataset()
          this.showDatalake()
        }
      },
      onFileChange(e) {
        const file = e.target.files;
        this.FormData = new FormData()
        this.FormData.append('file',file[0]);
      },
      uploadfile () {

        var DataToken = this.getCookie('DataToken')
        let url = ''
        if(this.isAdmin == true && this.cleaned == true){
          url = `https://aghub.miphost.com/api/datasets/upload`
        }else{
          url = `https://aghub.miphost.com/api/datasets/upload/lake`
        }
        this.FormData.append('fileName', this.fileData.label)
        this.FormData.append('description', this.fileData.description)
        this.FormData.append('access', this.fileData.privacy)

        console.log(this.fileData.label)
        var contentType = {
            headers: {
              'content-type':'multipart/form-data',
              'Authorization': `Bearer ${DataToken}`
            }
        }
        axios.post(url, 
        this.FormData, 
        contentType 
        )
        .then( response =>{
            console.log(response)
            
            this.$refs.closeuploadModal.click();
            this.fileData.label = ''
            this.fileData.description = null
            this.fileData.privacy = false

            this.$refs.importfile.type = 'text'
            this.$refs.importfile.type = 'file'
            this.FormData = new FormData()
            this.getDatasets()
        })
        .catch( error => {
            this.fileMsg = error.response.data

            this.fileData.label = ''
            this.fileData.description = null
            this.fileData.privacy = false

            this.$refs.importfile.type = 'text'
            this.$refs.importfile.type = 'file'
            this.FormData = new FormData()
            console.log(error);
        }) 
      },      
      getDatasets(){
        axios.get('https://aghub.miphost.com/api/datasets/alldata')
        .then(response =>  {
           var count = 0
            this.datasets = response.data
            this.datasets.forEach((datasetsVal, i) => {
              datasetsVal.datasetviews.forEach(log => {
                if(log.download) count++
              })
              this.datasets[i].dwonloads = count
              count = 0
            });
            console.log(this.datasets)

        }).catch(error => {
            console.log(error);
            // if(error.response.data === 'invalid token') this.authenticated = false
        })
      },
      searchDataset(){
        if(this.searchText.length <= 0) return this.getDatasets()

        axios.get('https://aghub.miphost.com/api/datasets/search/' +  this.searchText)
        .then(response =>  {
            var count = 0
            this.datasets = response.data
            this.datasets.forEach((datasetsVal, i) => {
              datasetsVal.datasetviews.forEach(log => {
                if(log.download) count++
              })
              this.datasets[i].dwonloads = count
              count = 0
            });
            console.log(this.datasets)
        }).catch(error => {
            console.log(error);
        })
      },
      preview(data){
        if(this.authenticated){
          this.$router.push({name:'DatasetPreview', params:data})
        }else{
          this.$refs.modalclick.click();
        }
      },
      forceFileDownload(response, title) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', title)
        document.body.appendChild(link)
        link.click()
      },
      downloadFile(id){
        var DataToken = this.getCookie('DataToken')
        axios.get(`https://aghub.miphost.com/api/datasets/downloadlake/${id}`,
          { headers:{'Authorization': `Bearer ${DataToken}`},
            responseType: 'arraybuffer',
          }
        ).then(response =>{
          this.forceFileDownload(response, `Dataset ${new Date().toDateString()}.xlsx`)
        }).catch(error =>{
          console.log(error)
        })
      },
      moveFile(){
         var DataToken = this.getCookie('DataToken')
          axios.get(`https://aghub.miphost.com/api/datasets/movefile/${id}`,
            { headers:{'Authorization': `Bearer ${DataToken}`},
              responseType: 'arraybuffer',
            }
          ).then(response =>{
                    this.resMsg = response.data
                    setTimeout(() => {
                    this.resMsg = null
                }, 2000);
          }).catch(error =>{
            console.log(error)
          })
      },
      // showpassword(){
        
      //   const password = document.getElementsByClassName('password');
      //   const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
      //   password.setAttribute('type', type);

      //   // Toggle the eye and bi-eye icon
        
      //   if(type === 'password'){
      //     document.getElementById('togglePassword').classList.add('bi-eye');
      //     document.getElementById('togglePassword').classList.remove('bi-eye-slash');
      //   }else{
      //     document.getElementById('togglePassword').classList.add('bi-eye-slash');
      //     document.getElementById('togglePassword').classList.remove('bi-eye');
      //   }
      // },
       getCookie(cname) {
          let name = cname + "=";
          let ca = document.cookie.split(';');
          for(let i = 0; i < ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
              c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
              }
          }
          return "";
        },
          setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    },
}
</script>

<style scoped>
::-ms-reveal {
  filter: invert(100%);
}
  .btn-close:focus {
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
  }
  .form-check-input:checked{
      background-color: #e6b600 !important;
      border-color: #306102 !important;
  }    

    .form-check-input{
      background-color: #e6b600 !important;
      border-color: #306102 !important;
  }   

input, select, textarea {
    background-color: #3061023d;
    border: none;
    border-bottom: 2px solid #306102;
    border-top: 0px;
    border-radius: 0px;
    font-weight:100;
    outline: 0;
    margin-bottom: 20px;
    padding-left: 0px;
    color: #000000;
}
.form-control-sm,.form-select-sm{
    font-size: 0.75rem !important;
}
.form-control:focus ,.form-select-sm:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px solid #e6b600 !important ;
    outline: 0;
    background-color: #306102;
    color: #000000 !important;
}

input:focus {
    outline: none;
    box-shadow: 0 0 0;
}

label {
    margin-bottom: 0px;
}

.form-label {
    font-size: 10px;
    color: #6C6C6C;
    font-weight:100;
    letter-spacing: 1px;
}



input[type=file]::file-selector-button {
  border: 2px solid #7567d900;
  padding: .3em 1em;
  border-radius: 22em;
  background-color: #e6b400c0;
  transition: 1s;
  
}

.card-footer a:hover{
  background-color: #306102!important;
}

.form-control[data-v-6a57b8d6]:focus {
    
    background-color: rgb(176 176 176 / 0%) !important;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

input[type = file]{
    display: inline !important;
    width: auto !important;
    border: none !important;
    background-color:#d2691e00;
    margin-inline: 5px;
}
input[type=file]::-webkit-file-selector-button:hover {
  background-color: #baa3a7;
  border: 2px solid #00cec9;
}

.modal-header {
  background-color: #306102;
  letter-spacing: 2px;
  height: 60px;
  color: #fff !important;
  border-bottom: 1px solid #dee2e634;
}

.modal-footer{
    border-top: 1px solid #dee2e634;
}

.deleteBtn{
  /* position: absolute;
    top: 0px;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; */
}
.moveBtn{
  /* position: absolute;
    top: -8px;
    right: 23px;
    font-size: larger;
    z-index: 2;
    padding: 1.25rem 1rem; */
}
.btn:hover{
  color: #fff !important;
  background-color: #306102 !important;
}
.btn{
  color: #fff !important;
  background-color: #e6b400 !important;
}

.float{
	position:fixed;
	width:41px;
	height:40px;
	bottom:70%;
	left:15px;
	background-color:#e6b600;
	color:#FFF;
	border-radius:50px;
  transition: transform .2s;
	text-align:center;
	box-shadow: 0px 0px 5px #e6b600;
}
.my-float{
    position: absolute;
    top: 4%;
    left: 23%;
}
.float:hover{
  transform: scale(1.1); 
	background-color:#306102;
  	box-shadow: 0px 0px 5px #85ff13;


}

/* ********************** */
.float2{
	position:fixed;
	width:41px;
	height:40px;
	bottom:80%;
	left:15px;
	background-color:#e6b600;
	color:#FFF;
	border-radius:50px;
  transition: width 1s;
  transition-timing-function: ease-in-out;
	text-align:center;
	box-shadow: 0px 0px 5px #e6b600;
}
.my-float2{
    position: absolute;
    top: 12%;
    left: 100%;
      transition: transform 1s;
  transition-timing-function: ease-in-out;
}
.float2:hover .my-float2{
    font-size: 15px !important;
    transform: translate(-4px,0px);
    margin-block: 5px;
    top: 3px !important;
}
.float2:hover{
  width:200px;
	background-color:#e6b600;

}


.float3{
	position:fixed;
	width:41px;
	height:40px;
	bottom:60%;
	left:15px;
	background-color:#e6b600;
	color:#FFF;
	border-radius:50px;
  transition: width 1s;
  transition-timing-function: ease-in-out;
	text-align:center;
	box-shadow: 0px 0px 5px #e6b600;
}
.my-float3{
    position: absolute;
    top: 12%;
    left: 100%;
      transition: transform 1s;
  transition-timing-function: ease-in-out;
}
.float3:hover .my-float3{
    font-size: 15px !important;
    transform: translate(-4px,0px);
    margin-block: 5px;
    top: 3px !important;
}
.float3:hover{
  transform: scale(1.1); 
	background-color:#306102 !important;
  	box-shadow: 0px 0px 5px #85ff13;
}



.search{
    width: 90%;
    padding-left: 5px !important;
    display: block;
    min-height: 0% !important;
    background-color: #ffffff00;
    color: #e8e8e8;
    border: 2px solid #ced4da;
    max-height: 25px !important;
    border-radius: 50px;
    margin: .45rem!important;
    margin-inline: 0.6rem !important
}

form a{
  color: #72bcec;
}
.form-control:focus {
    color: #e8e8e8;
    background-color: rgb(176 176 176 / 17%);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

/* .form-control {
    color: #e8e8e8;
    background-color: rgb(176 176 176 / 17%);
} */

.search:focus {
    color: #e8e8e8;
    background-color: rgb(176 176 176 / 17%);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

form i, .bi-search {
    margin-left: -30px;
    cursor: pointer;
  }

.modal-footer, .modal-header{
  padding: .5rem !important;
}

.card-margin {
    margin-bottom: 1.875rem;
}

.card {
    border: 0;
    box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #e6e4e9;
    border-radius: 8px;
    height: 90%;
}

.card .card-header.no-border {
    border: 0;
}
.card .card-header {
    background: none;
    padding: 0 0.9375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    min-height: 50px;
}
.card-header:first-child {
    border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
}

.widget-49 .widget-49-title-wrapper {
  display: flex;
  align-items: center;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #edf1fc;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #4e73e5;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e3e3e3;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #616161;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e8faf8;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #17d1bd;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebf7ff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #36afff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: floralwhite;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
   color: #FFC868;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #feeeef;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #F95062;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-light {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fefeff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #f7f9fa;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebedee;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #394856;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f0fafb;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #68CBD7;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-pro-title {
  color: #3c4142;
  font-size: 14px;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-meeting-time {
  color: #B1BAC5;
  font-size: 13px;
}

.widget-49 .widget-49-meeting-points {
  font-weight: 400;
  font-size: 13px;
  margin-top: .5rem;

  -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.widget-49 .widget-49-meeting-action {
  text-align: right;
}

.widget-49 .widget-49-meeting-action a {
  text-transform: uppercase;
}
</style>
<template>
    <footer class="d-flex flex-wrap justify-content-between align-items-center pb-2 my-5 border-top "  style="background-color:#2b5207 !important; height:70px" >
        <div class="col-md-4  d-flex mx-auto align-items-center">
            <!-- <a href="/" class="mb-3 me-2 mb-md-0 text-light text-decoration-none lh-1">
                <svg class="bi" width="30" height="24"><use xlink:href="#bootstrap"/></svg>
            </a> -->
            <span class="text-light ">&copy; {{new Date(Date.now()).getFullYear()}} Ag-Datahub</span>
        </div>

        <ul class="nav col-md-6  mx-auto  me-md-5  justify-content-end list-unstyled  d-flex">
            <li class="ms-3"><router-link to="/main/services" class="text-light">Services</router-link></li>
            <li class="ms-3"><router-link to="/main/dashboard" class="text-light">Dashboards</router-link></li>
            <li class="ms-3"><router-link to="/main/storyset" class="text-light">StorySet</router-link></li>
            <li class="ms-3"><router-link to="/main/datasets" class="text-light">Dataset</router-link></li>
            <li class="ms-3"><router-link to="/main/resources" class="text-light">Resources</router-link></li>
            <li class="ms-3"><router-link to="/main/faq" class="text-light">Support</router-link></li>
        </ul>
    </footer>
</template>


<style scoped>
    footer{
        margin-bottom: 0% !important;
        margin-top:4.3rem !important;
        height: 50px;
        position:absolute;
        bottom: 0;
        width:100%;
        z-index: 1000;
    }
    li .text-light{
        text-decoration: none;
    }
    li{
        transition:  .5s;
        border-radius: 50px;
        padding-inline: 15px;
        background-color: transparent; 
        transition: background-color 0.5s cubic-bezier(0.47, 0, 0.745, 0.715); 
    }
    li:hover{
        background-color: #e6b600;
        border-radius: 50px;
        padding-inline: 15px;
    }
</style>
<template>
  <div>
    <div class="main mt-3">
      <ul>
        <li>
          <i class="icons awesome fa-brands fa-pagelines"></i>
          <div class="step first active z-3">
            <p>1</p>
            <i class="awesome fa-solid fa-check"></i>
          </div>
          <p class="label">Ecozone</p>
        </li>
        <li>
          <i class="icons awesome fa-regular fa-lightbulb"></i>
          <div class="step second">
            <p>2</p>
            <i class="awesome fa-solid fa-check"></i>
          </div>
          <p class="label">Smartness</p>
        </li>
        <li>
          <i class="icons awesome fa-solid fa-coins"></i>
          <div class="step third">
            <p>3</p>
            <i class="awesome fa-solid fa-check"></i>
          </div>
          <p class="label">Investment</p>
        </li>
        <!-- <li>
          <i class="icons awesome fa-regular fa-star-half-stroke"></i>
          <div class="step fourth">
            <p>4</p>
            <i class="awesome fa-solid fa-check"></i>
          </div>
          <p class="label">Evaluation</p>
        </li>
        <li>
          <i class="icons awesome fa-solid fa-thumbs-up"></i>
          <div class="step fifth">
            <p>5</p>
            <i class="awesome fa-solid fa-check"></i>
          </div>
          <p class="label">Approval</p>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'currentForm',
  ],
  data() {
    return {
      steps: [],
    };
  },
  mounted() {
    this.steps = Array.from(document.getElementsByClassName("step"));
  },
  methods: {
    nextStep(currentStep) {
      this.steps.forEach((step) => step.classList.remove("active"));

      this.steps.forEach((step, index) => {
        if (index <= currentStep) {
          step.classList.add("active");
        } else {
          step.classList.remove("active");
        }
      });
    },
  },
  watch: {
    currentForm(newForm) {
      this.nextStep(newForm-1);
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

ul {
  display: flex;
}

.step{
  /* z-index: 1 !important; */
}

ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 40px;
}

ul li .icons {
  font-size: 25px;
  color: #1b761b;
  margin: 0 60px;
}

ul li .label {
  font-family: sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #1b761b;
}

ul li .step {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #d7d7c3;
  margin: 16px 0 10px;
  display: grid;
  place-items: center;
  color: ghostwhite;
  position: relative;
  cursor: pointer;
}

.step::after {
  content: "";
  position: absolute;
  width: 197px;
  height: 2px;
  background-color: #d7d7c3;
  right: 24px;
}

.first::after {
  width: 0;
  height: 0;
}

ul li .step .awesome {
  display: none;
}

ul li .step p {
  font-size: 18px;
}

ul li .active {
  background-color: #1b761b;
}

li .active::after {
  background-color: #1b761b;
}

ul li .active p {
  display: none;
}

ul li .active .awesome {
  display: flex;
}
</style>

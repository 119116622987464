<template>
  <div style="height: 700px; width: 100%; mx-5" class="position-relative">
    <div
      id="searchbar"
      class="ui container position-absolute w-lg-25 w-sm-50 mt-3"
    >
      <div class="ui centered grid">
        <div class="row">
          <div class="column">
            <div class="ui action input dropdown">
              <input
                type="text"
                id="searchInput"
                class="form-control shadow rounded-5 form-control-sm dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-model="searchInput"
                @keyup="filterResults()"
                placeholder="Search..."
              />
              <ul class="dropdown-menu w-100">
                <li>
                  <span
                    class="dropdown-item"
                    @click="changeCenter(farm.center_point)"
                    v-for="(farm, i) in farms"
                    :key="i"
                    >{{ farm.ghana_card_no }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


    <l-map ref="map" v-model:zoom="zoom" :center="center">
      <l-tile-layer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        :attribution="attribution"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <span v-if="farms.length > 0">
        <l-polygon
          v-for="(farm, i) in farms"
          :key="i"
          :lat-lngs="parseJson(farm.coordinates)"
          color="#dd9d04"
          :fill="true"
          :fillOpacity="0.5"
          fillColor="#dd9d04"
        >
        <l-popup ref="popup"> {{ farm.ghana_card_no }} </l-popup>
          <l-marker :lat-lng="parseCoordinate(farm.center_point)"> 
            <l-popup ref="popup"> {{ farm.ghana_card_no }} </l-popup>
          </l-marker>
          
        </l-polygon>
      </span>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LPolygon,
  LPopup,
  LMarker,
} from "@vue-leaflet/vue-leaflet";
import axios from "axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    LPopup,
    LMarker,
  },
  data() {
    return {
       attribution: '&copy; <a href="https://www.esri.com/en-us/home">Esri</a>',
      showForm: false,
      msg: {},
      ghanaCardNo: "",
      centerCoordinates: "",
      numPoints: 0,
      polygonPoints: [],
      numPointsError: "",
      farms: [],
      index: 0,
      districtData: [],
      searchInput: "",
      zoom: 7,
      jData: [],
      center: [7.998338, -1.213402],
      geojson: null
    };
  },
  created() {
    this.getAllPolygons();
  },
  methods: {
    onPopupOpen(data) {},
    closePopup() {},
    generatePolygonInputs() {
      if (this.numPoints < 4 || this.numPoints > 8) {
        this.numPointsError = "Number of points must be between 4 and 8.";
        this.polygonPoints = [];
      } else {
        this.numPointsError = "";
        this.polygonPoints = Array(this.numPoints).fill("");
      }
    },

    getAllPolygons() {
      axios
        .get("https://aghub.miphost.com/api/polygon")
        .then((response) => {
          console.log(response.data);
          this.farms = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    parseJson(jsonString) {
      console.log(JSON.parse(jsonString));
      const stringArray = JSON.parse(jsonString);
      const coordinateArray = stringArray.map((coord) => {
        return coord.split(", ").map(Number);
      });

      return coordinateArray;
    },

    validateInput() {
      // Optional: add validation logic for the inputs
    },
    changeCenter(centerPoint) {
      this.center = centerPoint.split(", ").map(Number);
      this.zoom = 15;
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    parseCoordinate(centerPoint) {
      return centerPoint.split(", ").map(Number);
    },
  }
};
</script>

<style scoped>
#searchbar {
  top: 5%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}



@media (max-width: 575.98px) {
  .w-sm-50 {
    width: 50%;
  }
  #searchbar {
    top: 5%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

}

@media (min-width: 576px) {
  .w-lg-25 {
    width: 25%;
  }
}
</style>

<template>
    <nav class="navbar my-2   bg-tranparent text-light">
        <div class="container-fluid">
            <span class="navbar-brand mb-0 fs-3">theGhWeather.com</span>
        </div>
    </nav>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    @media only screen and (max-width: 800px) {
        .fs-3{
            font-size: 1rem !important;
            font-weight: 300 !important;
            line-height: 1.0 !important;
        }
    }
</style>
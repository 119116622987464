<template>
  <div class="loader my-5 mx-auto" v-if="loader"></div>

  <div class="container py-5" v-else>

    <div class="row row-cols-md-1 row-cols-lg-3 justify-content-center" style="padding-bottom: 1in;">
        <div  class="col-lg-6" 
        data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" 
        aria-controls="offcanvasExample" 
        v-for="(risk, index) in risks" :key="index"
        @click="selectedRisk = risk"
        >
            <div class="card card-margin" >
                <div class="card-body pt-3" style="height: 70%;">
                    <div class="widget-49">
                        <div class="widget-49-title-wrapper">
                        <div v-if="risk && risk.riskCategory" :class="widgetColors[Math.floor(Math.random() * 7)]">
                          <img v-if="riskCategories.includes(risk.riskCategory.name.toLowerCase())" width="50" :src="require('../assets/icons/' + risk.riskCategory.name.toLowerCase() + '.png')" style="object-fit:contain" alt="">
                          <img v-else width="50" :src="require('../assets/icons/insecticide.png')" style="object-fit:contain" alt="">
                        </div>
                            <div class="widget-49-meeting-info mx-auto py-2 rounded-pill" style="width: 70%; background-color:rgba(230, 180, 0, 0)" >
                                <span class="widget-49-pro-title fw-bold text-uppercase">{{risk.name}}</span>
                            </div>
                        </div>
                        <p class="widget-49-meeting-points">{{risk.description}}</p>
                    </div>
                </div>
                <div class="widget-49-meeting-action card-footer border-0 bg-transparent">
                <div class="d-flex justify-content-between  ">
                    <div style=" font-size: 13px;" class=" text-end w-100" role="alert" >
                      <span v-html="JSON.parse(risk.location).name"></span>
                      <br>
                      <span class="badge bg-dark text-bg-dark">{{ risk.isActive ? 'Active':'Inactive' }}</span>
                      <br>
                      Last Updated {{new Date(risk.updatedAt).toDateString()}}
                    </div>
                </div>
                </div>
            </div>  
        </div>
    </div>
  </div>


<div class="offcanvas offcanvas-start w-50"  tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
 <div v-if="selectedRisk" class="overflow-auto">
   <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">{{ selectedRisk.name }}</h5>
      <button type="button" class="btn-close" ref="closeCanvas" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body" >
      <div class="image-container mx-auto">
        <div 
          class="image" 
          v-for="(image, index) in selectedRisk.riskImages" 
          :key="index"
          @mouseover="hoverEffect(index)"
          @mouseleave="resetEffect(index)"
        >
          <img :src="'https://aghub.miphost.com/risk_files/'+ image.image" alt="Image" />
          <img :src="'https://aghub.miphost.com/risk_files/'+ image.image" alt="Image" :style="getStyle(index)" />
        </div>
      </div>
      <div class="mt-5">
        <div class="article-title px-3 mb-4">
              <div class="media d-flex justify-content-between">
                  <div class="d-flex justify-content-between">
                      <div class="avatar border-3" :class="selectedRisk.isActive ? 'border-danger ':'border-warning '">
                          <img width="40"  :src="require('../assets/leaf.png')" title="" alt="">
                      </div>
                      <p class="mx-2 py-2"  :class="selectedRisk.isActive ? 'text-danger':' text-warning'"> {{selectedRisk.isActive ? 'Active':'Inactive'}}</p>
                  </div>
                  <div class="media-body">
                      <span class="text-start" style="font-size:14px" v-html="JSON.parse(selectedRisk.location).name"></span>
                  </div>
              </div>
          </div>
          <div class="article-content text-start px-3">
              <p style="font-size:14px">{{selectedRisk.description}}</p>
              <span v-if="selectedRisk.comments" >
                  <h5>PPRSD Officers' Comments</h5>
                  <div class="widget-body text-start" style="font-size:14px"  v-html="`<p>${selectedRisk.comments.replace(/\r\n/g, '</p><p>')}</p>`"></div>
              </span>
          </div>
          <div class="widget-title  px-3" v-if="selectedRisk.mitigation">
              <h5 class="text-start">Recommended Mitigation</h5>
              <div class="widget-body text-start"   style="font-size:14px" v-html="`<p>${selectedRisk.mitigation.replace(/\r\n/g, '</p><p>')}</p>`">
              </div>
          </div>
          <div class="text-start px-3">
            <h5 class="text-start">Other Comments</h5>
            <p style="font-size:14px"   v-for="(comment, index) in JSON.parse(selectedRisk.respondent_comments)" :key="index" >
             {{ comment }}
            </p>
          </div>
          <div class="nav tag-cloud d-flex justify-content-center px-3">
              <a href="#">Reported on :{{new Date(selectedRisk.createdAt).toDateString()}}</a>
              <a href="#">Updated on :{{new Date(selectedRisk.updatedAt).toDateString() }}</a>
              <a href="#" v-if="selectedRisk && selectedRisk.riskZone">{{selectedRisk.riskZone.name}}</a>
          </div>
      </div>
    </div>
      <div class="col-md-10 mx-auto">
          <label for="description" class="form-label text-start fw-bold">Add a Comment</label>
          <textarea v-model="respondentComment" class="form-control form-control-sm bg-light text-dark" id="description"></textarea>
          <div class="loader my-5 mx-auto" v-if="loader"></div>
          <div class="btn btn-sm btn-success my-3" v-else @click="addComment()">Comment</div>
      </div>
 </div> 
</div>



</template>
<script>
import axios from "axios"


export default {
    data() {
        return {
            loader:false,
            org:'',
            clipStart: 'inset(50% 50% 50% 50%)', 
            clipEnd: 'inset(0% 0% 0% 0%)',     
            hoverIndex: null,
            selectedRisk:null, 
            respondentComment:'',
            resMsg:'',
            risks:[],
            token:'',
            riskCategories:[
                'virus',
                'pest',
                'disease',
                'flood',
                'drought'
            ],
            widgetColors:[
                'widget-49-date-primary',
                'widget-49-date-secondary',
                'widget-49-date-dark',
                'widget-49-date-danger',
                'widget-49-date-success',
                'widget-49-date-warning',
                'widget-49-date-info',
            ]
        }
    },  
    created(){
    this.token = this.getCookie('token')
      this.getRespondent()
    },
    methods: {
      addComment() {
        if (!this.selectedRisk.respondent_comments) {
          this.selectedRisk.respondent_comments = '[]';
        }

        var comments = JSON.parse(this.selectedRisk.respondent_comments);
        comments.push(`[${this.org}] - ${ this.respondentComment}`);
        this.selectedRisk.respondent_comments = JSON.stringify(comments);
        this.updateRisk(this.selectedRisk.id)
      },
      hoverEffect(index) {
        this.hoverIndex = index;
      },
      resetEffect() {
        this.hoverIndex = null;
      },
      getStyle(index) {
        return {
          clipPath: this.hoverIndex === index ? this.clipEnd : this.clipStart,
          transition: 'clip-path 0.5s',
        };
      },
      async updateRisk(id) {
          this.isLoader = true
          var token = this.getCookie('token')
          delete this.selectedRisk.createdAt
          delete this.selectedRisk.updatedAt
          delete this.selectedRisk.extensionsOfficerId
          delete this.selectedRisk.riskZone
          delete this.selectedRisk.riskImages
          delete this.selectedRisk.extensionsOfficer
          delete this.selectedRisk.riskCategory
          delete this.selectedRisk.RiskExtensionOfficers

          
          axios.post('https://aghub.miphost.com/api/broadcast/risk/update/'+ id, this.selectedRisk,
              { headers:{'Authorization': `Bearer ${token}`}})
          .then(response =>  {
              this.isLoader = false
              this.getRespondent()
              this.respondentComment = ''
              this.$refs.closeCanvas.click()
          }).catch(error => {
              console.log(error);
          })
      },
      getRespondent(){
        this.loader = true
        axios.get('https://aghub.miphost.com/api/broadcast/respondent', 
                    { headers:{'Authorization': `Bearer ${this.token}`}})
                .then(response =>  {
                    this.risks = response.data.AssignedRisks
                    this.org = response.data.name
                    this.loader = false
                }).catch(error => {
                    console.log(error);
                })
      },
      getCookie(cname) {
          let name = cname + "=";
          let ca = document.cookie.split(';');
          for(let i = 0; i < ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
              c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
              }
          }
          return "";
        },
          setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    },
}
</script>

<style scoped>
.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #ffffff;
  background: #20247b;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
  text-decoration: none;
}


.image-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
  width: 600px;
  grid-gap: 0.5rem;
}

.image {
  position: relative;
  padding-bottom: 100%;
}

.image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.image img:nth-of-type(1) {
  filter: grayscale(1) brightness(40%);
}





  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

/*  */
input[type=file]::file-selector-button {
  border: 2px solid #7567d900;
  padding: .2em .4em;
  border-radius: .2em;
  background-color: #5c5c5c;
  transition: 1s;
  
}
.card-footer a{
  background-color: #e6b600 !important;
  transition: background-color 0.3s cubic-bezier(0.47, 0, 0.745, 0.715); 
}

.card-footer a:hover{
  background-color: #306102 !important;
}

.form-control[data-v-6a57b8d6]:focus {
    background-color: rgb(176 176 176 / 0%) !important;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

input[type = file]{
    display: inline !important;
    width: auto !important;
    border: none !important;
    background-color:#d2691e00;
    margin-inline: 5px;
}
input[type=file]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
}

.modal-header {
    border-bottom: 1px solid #dee2e634;
}

.modal-footer{
    border-top: 1px solid #dee2e634;
}


/* #formFileSm {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
#formFileSm + .formFilelable {
    font-weight: 700;
    color: rgb(216, 216, 216);
    border: 2px solid #98989882;
    display: inline-block;
    border-radius: 50px;
}

#formFileSm:focus + .formFilelable,
#formFileSm + .formFilelable:hover {
    background-color: rgba(141, 141, 141, 0.173);
} */

.float{
	position:fixed;
	width:41px;
	height:40px;
	bottom:70%;
	left:15px;
	background-color:rgb(60, 62, 62);
	color:#FFF;
	border-radius:50px;
  transition: transform .2s;
	text-align:center;
	box-shadow: 0px 0px 5px #999;
}
.my-float{
    position: absolute;
    top: 4%;
    left: 23%;
}
.float:hover{
  transform: scale(1.1); 
	background-color:rgb(75, 76, 76);

}

/* ********************** */
.float2{
	position:fixed;
	width:41px;
	height:40px;
	bottom:80%;
	left:15px;
	background-color:rgb(60, 62, 62);
	color:#FFF;
	border-radius:50px;
  transition: width 1s;
  transition-timing-function: ease-in-out;
	text-align:center;
	box-shadow: 0px 0px 5px #999;
}
.my-float2{
    position: absolute;
    top: 12%;
    left: 100%;
      transition: transform 1s;
  transition-timing-function: ease-in-out;
}
.float2:hover .my-float2{
    font-size: 15px !important;
    transform: translate(-4px,0px);
    margin-block: 5px;
}
.float2:hover{
  width:200px;
	background-color:rgb(75, 76, 76);

}

.search{
    width: 90%;
    display: block;
    min-height: 0% !important;
    background-color: #ffffff00;
    color: #e8e8e8;
    border: 2px solid #ced4da;
    max-height: 25px !important;
    border-radius: 50px;
    margin: .45rem!important;
    margin-inline: 0.6rem !important
}

form a{
  color: #72bcec;
}
.form-control:focus {
    color: #ffffff;
    background-color: rgb(176 176 176 / 17%);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.form-control {
    color: #ffffff;
    background-color: rgb(176 176 176 / 17%);
    
}

.search:focus {
    color: #ffffff;
    background-color: rgb(176 176 176 / 17%);
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

form i, .bi-search {
    margin-left: -30px;
    cursor: pointer;
  }

.modal-footer, .modal-header{
  padding: .5rem !important;
}

.card-margin {
    margin-bottom: 1.875rem;
}

.card {
    border: 0;
    box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
    -ms-box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.1);
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: border-box;
    border: 1px solid #e6e4e9;
    border-radius: 8px;
    height: 90%;
}

.card .card-header.no-border {
    border: 0;
}
.card .card-header {
    background: none;
    padding: 0 0.9375rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    min-height: 50px;
}
.card-header:first-child {
    border-radius: calc(8px - 1px) calc(8px - 1px) 0 0;
}

.widget-49 .widget-49-title-wrapper {
  display: flex;
  align-items: center;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e6b4007b;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #343434;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e3e3e3;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #616161;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #e8faf8;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #17d1bd;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebf7ff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #36afff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: floralwhite;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
   color: #FFC868;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-date-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #feeeef;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #e6b600;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-light {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fefeff;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #f7f9fa;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ebedee;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
    color: #394856;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}


.widget-49 .widget-49-title-wrapper .widget-49-date-base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f0fafb;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  color: #68CBD7;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-pro-title {
  color: #3c4142;
  font-size: 14px;
}

.widget-49 .widget-49-title-wrapper .widget-49-meeting-info .widget-49-meeting-time {
  color: #B1BAC5;
  font-size: 13px;
}

.widget-49 .widget-49-meeting-points {
  font-weight: 400;
  font-size: 13px;
  margin-top: .5rem;
}


.widget-49 .widget-49-meeting-action {
  text-align: right;
}

.widget-49 .widget-49-meeting-action a {
  text-transform: uppercase;
}
</style>
<template>

    <!-- Button trigger modal -->
    <button type="button" ref="modalclick" hidden data-bs-toggle="modal" data-bs-target="#staticBackdrop"></button>

    <!-- Modal -->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <h6 class="modal-title fw-bolder" id="exampleModalLabel">{{signin ? 'Sign In' : 'Join Us'}}</h6> -->
            <button type="button" ref="closeModal" class="btn-close" data-bs-dismiss="modal" hidden aria-label="Close"></button>
        
        <div class="modal-body">
            <form class=" row g-2 text-start" @submit.prevent="login">
              <div class="col-12">
                <label for="inputEmail" class="form-label" style="font-size:0.9rem">Email</label>
                <input type="email" v-model="userData.email" class=" rounded-pill shadow form-control form-control-sm" required id="inputEmail" placeholder="johndoe@***.com">
              </div>
              <div class="col-12">
                <label for="inputpassword" class="form-label" style="font-size:0.9rem">Password</label>
                  <input type="password" v-model="userData.password"   class=" rounded-pill shadow form-control form-control-sm  d-inline password">
              </div>

              <div class="col-12">
                    <button type="submit" class="btn btn-sm rounded-pill" @click="login" >signin</button>
                </div>
            </form>
        </div>
       
        </div>
    </div>
    </div>

    <div class="row w-100">
        <div class="col-8 d-flex align-items-center justify-content-center">
           <form class="row p-4 w-75 shadow mx-4 g-3"  @submit.prevent="create">
            <div class="col-md-8">
                <label for="inputEmail4" class="form-label text-start w-100 fw-bold" >Title</label>
                <input type="text" class="form-control2 form-control form-control-sm bg-transparent" v-model="resource.title"  id="inputEmail4">
            </div>
            <div class="col-4">
                <label for="inputAddress1" class="form-label text-start w-100 fw-bold">Source</label>
                <input type="text" class="form-control2 form-control form-control-sm bg-transparent" id="inputAddress1" v-model="resource.source" placeholder="Something Gh">
            </div>
            <div class="col-md-12">
                <label for="inputPassword4" class="form-label text-start w-100 fw-bold">Description</label>
                <textarea class="form-control2 form-control form-control-sm bg-transparent" id="exampleFormControlTextarea1" v-model="resource.subtext" rows="3"></textarea>
            </div>
            <div class="col-6">
                <label for="inputAddress" class="form-label text-start w-100 fw-bold">Resource URL </label>
                <input type="text" class="form-control2 form-control form-control-sm bg-transparent" id="inputAddress" v-model="resource.link" placeholder="http://www.something.com">
            </div>
            <div class="col-6">
                <label for="inputAddress2" class="form-label text-start w-100 fw-bold">Source URL</label>
                <input type="text" class="form-control2 form-control form-control-sm bg-transparent" id="inputAddress2" v-model="resource.sourcelink" placeholder="http://www.something.com">
            </div>
            <div class="col-12">
                <button type="submit" class="btn btn-primary rounded-pill btn-sm float-end" >Add Resource</button>
            </div>
            <p class="text-warning text-center mx-auto mt-2"  style="font-size:0.7rem !important">{{resMsg}}</p>
            </form>
        </div>
        <div class="col-4  shadow  mt-3" >
            <div class=" my-1 d-flex justify-content-between">
                <input type="text" v-model="searchVal" @keydown="searchResource()" class="form-control form-control-sm rounded-pill w-25 bg-transparent" style="width: 2in!important;" placeholder="eg. ghana or farming">
                <p class="text-dark mt-2 mb-0" style="font-size:12px">{{resources.length}} items found</p>
            </div>
            <div class="text-start  overflow-scroll pb-3" style="height:5.5in">
            
                <div class=" mb-2" v-for="(resource, i) in resources" :key="i">
                    <label for="recources" class="fw-bold fs-6">{{resource.title}}</label>
                    <p class="truncate-text1" style="font-size:13px">{{resource.subtext}}</p>
                    <div class="">
                        <a :href="resource.link"  class="text-primary d-inline-block text-truncate" style="font-size:12px;max-width: 100px !important">{{resource.link}}</a>           
                        <p class="text-dark fst-italic" style="font-size:12px">Data Source : <a :href="resource.sourcelink">{{resource.source}}</a></p>
                    </div>   
                    <hr class="w-75">          
                </div>
            </div>
        </div>  
    </div>

    
    <br>
</template>
<script>
import axios from 'axios'
import jwt_decode from "jwt-decode";

export default {
    data() {
        return {
            resources:[],
            searchVal:'',
            resMsg:'',
            authenticated:false,
            userData:{
                email:'',
                password:''
            },
            resource:{
                title:'',
                subtext:'',
                link:'',
                source:'',
                sourcelink:'',

            }
        }
    },
    created(){
        if(!this.$route.params.searchValue) {
            this.getResources()
        }else{
            this.searchVal = this.$route.params.searchValue
            this.searchResource()
        }
        
        var token = this.getCookie('DataToken')
        
        if (token == 'null' || token == null || token == '' ) {
          setTimeout(() => {
              this.$refs.modalclick.click();
            }, 1000);
        }else{
            if(!jwt_decode(token).isAdmin){
                this.$refs.modalclick.click();
                this.resMsg = 'not authorized'
            }else{
                this.authenticated = true
            }
        }
    },
    methods:{
        login(){
          axios.post('https://aghub.miphost.com/api/datasets/login',
               {
                email:this.userData.email,
                password:this.userData.password
               },
            ).then(response =>{
             this.setCookie('DataToken', response.data, 1)
             if(!jwt_decode(response.data).isAdmin) {
                this.resMsg = 'not authorized'
            }else{
                this.resMsg = 'You are currently logged In'
                setTimeout(() => {
                    this.resMsg = null
                    this.authenticated = true
                    this.$refs.closeModal.click();
                }, 1000);

            }
          }).catch(error =>{
              console.log(error.response.data)
              this.resMsg = error.response.data
                setTimeout(() => {
                  this.resMsg = null
              }, 1000);
          })
        },
         create(){
          var DataToken = this.getCookie('DataToken')

            axios.post(`https://aghub.miphost.com/api/resource/`, this.resource, 
            { headers:{'Authorization': `Bearer ${DataToken}`}})
            .then(response =>  {
                this.getResources()
            }).catch(error => {
                console.log(error);
            })
        },
        getResources(){
            axios.get('https://aghub.miphost.com/api/resource')
            .then(res =>{
                this.resources = res.data.reverse()
            })
            .catch(err =>{
                console.log(err)
            })
        },
        searchResource(){
            axios.get(`https://aghub.miphost.com/api/resource/${this.searchVal}`)
            .then(res =>{
                this.resources = res.data
            })
            .catch(err =>{
                console.log(err.response.data)
            })
        },
        getCookie(cname) {
          let name = cname + "=";
          let ca = document.cookie.split(';');
          for(let i = 0; i < ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
              c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
              }
          }
          return "";
        },
          setCookie(cname, cvalue, exdays) {
          const d = new Date();
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
    }
}
</script>
<style scoped>
    .btn{
        background-color:#f56a6a !important;
        width: 1.5in;
        border-color: transparent !important;
    }
    .truncate-text1 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines to show */
        -webkit-box-orient: vertical;
    }
    .form-control2{
        border: none;
        border-bottom: 2px solid darkgray;
        border-radius: 0px;
    }
    .form-control2:focus{
        border-bottom: 3px solid darkgray;
        box-shadow:none
    }
</style>
<template>
  <div style="height: 700px; width: 100%; margin-top: 50px; mx-5" class="position-relative">
    <div
      id="searchbar"
      class="ui container position-absolute w-lg-25 w-sm-50 mt-3"
    >
      <div class="ui centered grid">
        <div class="row">
          <div class="column">
            <div class="ui action input dropdown">
              <input
                type="text"
                id="searchInput"
                class="form-control shadow rounded-5 form-control-sm dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-model="searchInput"
                @keyup="filterResults()"
                placeholder="Search..."
              />
              <ul class="dropdown-menu w-100">
                <li>
                  <span
                    class="dropdown-item" style="font-size:12px"
                    @click="changeCenter(farm.center_point)"
                    v-for="(farm, i) in farms"
                    :key="i"
                    >{{ farm.ghana_card_no }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      id="addBtn"
      @click="showForm = !showForm"
      class="btn btn-sm bg-warning shadow rounded-5 py-1 position-absolute mt-2"
    >
      <i class="bi bi-plus-lg text-light"></i>
    </button>

    <div
      v-if="showForm"
      id="formBar"
      class="ui container bg-light shadow rounded-5 py-3 position-absolute w-lg-25 w-sm-75 mt-3"
    >
      <div class="ui centered grid">
        <h4 for="" class="fw-bolder fs-6 py-2 text-start">Map a Farm</h4>
        <div class="row">
          <div class="column">
            <form @submit.prevent="submitForm">
              <div class="ui action input">
                <input
                  type="text"
                  id="ghanaCardInput"
                  class="form-control shadow rounded-5 form-control-sm"
                  v-model="ghanaCardNo"
                  placeholder="Ghana Card Number"
                />
              </div>
              <div class="ui action input mt-3">
                <input
                  type="text"
                  id="centerCoordinatesInput"
                  class="form-control shadow rounded-5 form-control-sm"
                  v-model="centerCoordinates"
                  placeholder="Center Coordinates (lat,lng)"
                />
              </div>
              <div class="ui action input mt-3">
                <label for="" class="text-dark"  style="font-size:12px"> <b>{{ numPoints > 4 ?numPoints - 1 : 0 }} selected</b> , last coordinate is <i class="fw-bold"> Auto-Filled</i></label> 
                <input
                  type="text"
                  id="numPointsInput"
                  class="form-control shadow rounded-5 form-control-sm"
                  v-model.number="numPoints"
                  @input="generatePolygonInputs"
                  placeholder="Number of Points"
                />
              </div>
              <div
                v-if="numPointsError"
                class="ui fw-light text-danger negative message mt-2"
                style="font-size: 11px"
              >
                <p>Error: {{ numPointsError }}</p>
              </div>
              <div
                v-for="(point, index) in polygonPoints"
                :key="index"
                class="ui action input mt-3"
              >
                <input
                  type="text"
                  :id="'point' + (index + 1)"
                  class="form-control shadow rounded-5 form-control-sm"
                  required
                  v-model="polygonPoints[index]"
                  :placeholder="'Point ' + (index + 1) + ' (lat,lng)'"
                />
              </div>
              <button
                type="submit"
                class="btn btn-success btn-sm px-4 w-100 rounded-pill mt-3"
              >
                Submit
              </button>
              <p class=" text-muted text-center" style="font-size:12px">{{msg}}</p>
            </form>
          </div>
        </div>
      </div>
    </div>

    <l-map ref="map" v-model:zoom="zoom" :center="center">
      <l-tile-layer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        layer-type="base"
                :attribution="attribution"

        name="OpenStreetMap"
      ></l-tile-layer>
      <span v-if="farms.length > 0">
        <l-polygon
          v-for="(farm, i) in farms"
          :key="i"
          :lat-lngs="parseJson(farm.coordinates)"
          color="#41b782"
          :fill="true"
          :fillOpacity="0.5"
          fillColor="#41b782"
        >
          <l-marker :lat-lng="parseCoordinate(farm.center_point)"> </l-marker>
          <l-popup ref="popup"> {{ farm.ghana_card_no }} </l-popup>
        </l-polygon>
      </span>
    </l-map>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import {
  LMap,
  LTileLayer,
  LPolygon,
  LPopup,
  LMarker,
} from "@vue-leaflet/vue-leaflet";
import axios from "axios";

export default {
  components: {
    LMap,
    LTileLayer,
    LPolygon,
    LPopup,
    LMarker,
  },
  data() {
    return {
      attribution: '&copy; <a href="https://www.esri.com/en-us/home">Esri</a>',
      showForm: false,
      msg: '',
      ghanaCardNo: "",
      centerCoordinates: "",
      numPoints: 0,
      polygonPoints: [],
      numPointsError: "",
      farms: [],
      index: 0,
      districtData: [],
      searchInput: "",
      zoom: 7,
      jData: [],
      center: [7.998338, -1.213402],
      geojson: null,
      geoStyler: (feature) => ({
        opacity: feature.properties.code / 100000,
        radius: 8,
        fillColor: "#198754",
        color: "#fff",
        weight: 1,
        fillOpacity: 0.5,
      }),
    };
  },
  created() {
    this.getAllPolygons();
  },
  methods: {
    onPopupOpen(data) {},
    closePopup() {},
    generatePolygonInputs() {
      if (this.numPoints < 4 || this.numPoints > 8) {
        this.numPointsError = "Number of points must be between 4 and 8.";
        this.polygonPoints = [];
      } else {
        this.numPointsError = "";
        this.numPoints = this.numPoints + 1
        this.polygonPoints = Array(this.numPoints).fill("");
      }
    },
    submitForm() {
      var token = this.getCookie("token");

      if (!this.numPointsError) {
        this.polygonPoints[this.numPoints - 1] = this.polygonPoints[0];

        const data = {
          ghana_card_no: this.ghanaCardNo,
          center_point: this.centerCoordinates,
          coordinates: JSON.stringify(this.polygonPoints),
        };
        axios
          .post("https://aghub.miphost.com/api/polygon/", data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.getAllPolygons();
            this.msg  = 'farm successfully mapped'
            setTimeout(() => {
              this.msg = ''
            }, 5000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getAllPolygons() {
      axios
        .get("https://aghub.miphost.com/api/polygon")
        .then((response) => {
          console.log(response.data);
          this.farms = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    parseJson(jsonString) {
      console.log(JSON.parse(jsonString));
      const stringArray = JSON.parse(jsonString);
      const coordinateArray = stringArray.map((coord) => {
        return coord.split(", ").map(Number);
      });

      return coordinateArray;
    },

    // updateGroup() {
    //   var token = this.getCookie("token");
    //   axios
    //     .post(
    //       "https://aghub.miphost.com/api/broadcast/group/update/" +
    //         this.groupid,
    //       this.extGroup,
    //       { headers: { Authorization: `Bearer ${token}` } }
    //     )
    //     .then((response) => {
    //       this.resMsg = response.data;
    //       setTimeout(() => {
    //         this.extGroup.label = "";
    //         this.extGroup.description = "";
    //         this.resMsg = "";
    //         this.showupdate = !this.showupdate;
    //         this.getallgroups();
    //       }, 2000);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    // deleteGroup(groupid) {
    //   var token = this.getCookie("token");
    //   axios
    //     .get(
    //       "https://aghub.miphost.com/api/broadcast/group/delete/" + groupid,
    //       { headers: { Authorization: `Bearer ${token}` } }
    //     )
    //     .then((response) => {
    //       console.log(response);
    //       this.getallgroups();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    validateInput() {
      // Optional: add validation logic for the inputs
    },
    changeCenter(centerPoint) {
      this.center = centerPoint.split(", ").map(Number);
      this.zoom = 15;
    },
    parseCoordinate(centerPoint) {
      return centerPoint.split(", ").map(Number);
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  watch: {
    polygonPoints: {
      handler(newPoints) {
        if (newPoints.length && newPoints[0]) {
          this.polygonPoints[this.numPoints - 1] = newPoints[0];
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
#searchbar {
  top: 10%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

#formBar {
  top: 50%;
  right: 1%;
  transform: translate(0%, -50%);
  z-index: 1000;
}

#addBtn {
top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

@media (max-width: 575.98px) {
  .w-sm-50 {
    width: 50%;
  }
  #searchbar {
    top: 5%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  #formBar {
  top: 50%;
  right: 2%;
  transform: translate(0%, -50%);
  z-index: 1000;
}
}

@media (min-width: 576px) {
  .w-lg-25 {
    width: 25%;
  }
}
</style>

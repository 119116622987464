<template>
    <div class="p-5 mt-5 mx-auto">
        <form class="row g-3 p-4 shadow bg-light text-start">
            <label for="">Add Extension Officers</label>
            <div class="col-md-4">
                <label for="inputEmail4" class="form-label">Name</label>
                <input type="text" v-model="extensionOfficer.name" class="form-control form-control-sm bg-light"  id="inputEmail4">
            </div>
            <div class="col-md-4">
                <label for="inputPassword4" class="form-label">Email</label>
                <input type="email" v-model="extensionOfficer.email" class="form-control form-control-sm bg-light"  id="inputPassword4">
            </div>
             <div class="col-4">
                <label for="inputAddress" class="form-label">Phone</label>
                <input type="text" v-model="extensionOfficer.phone" class="form-control form-control-sm bg-light"  id="inputAddress" placeholder="0244xxxxxx">
            </div>
             <div class="col-3">
                <label for="inputAddress" class="form-label">Institution</label>
                <input type="text" v-model="extensionOfficer.institution" class="form-control form-control-sm bg-light"  id="inputAddress" placeholder="NADMO">
            </div>
            <div class="col-3">
                <label for="inputAddress2" class="form-label">Assignment</label>
                    <select class="form-select form-select-sm" v-model="extensionOfficer.role" aria-label="Small select example">
                        <option selected>Open this select menu</option>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                        <option value="pprsd">PPRSD</option>
                        <option value="responser">Responder</option>
                    </select>   
            </div>
            <div class="col-md-3">
                <label for="inputCity"  class="form-label">Password</label>
                <input type="password" v-model="extensionOfficer.password" class="form-control form-control-sm bg-light"  id="inputCity">
            </div>
            <div class="col-md-3">
                <label for="inputCity" class="form-label">Repeat Password</label>
                <input type="password" v-model="extensionOfficer.repeat_password" class="form-control form-control-sm bg-light"  id="inputCity">
            </div>
            <div class="col-12">
                <button class="btn btn-success btn-sm" v-if="!isUpdateOfficer"  @click="register">Add Officer</button>
                <button class="btn btn-dark btn-sm" v-else @click.prevent="updateOfficer">Update Officer</button>
            </div>
        </form>
        <div class="mt-5 table-responsive">
            <table class="table shadow table-striped-columns table-hover text-start">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">role</th>
                    <th scope="col">Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(officer , i) in officers" :key="officer.id">
                        <th scope="row">{{i + 1}}</th>
                        <td>{{officer.name}}</td>
                        <td>{{officer.phone}}</td>
                        <td>{{officer.role}}</td>
                        <td>{{officer.email}}</td>
                        <td @click="extensionOfficer = officer, isUpdateOfficer=true" style="width:20px"><i class="bi bi-pencil-square btn btn-sm bg-dark text-light"></i></td>
                        <td><i @click="deleteOfficer(officer.id)" class="bi bi-trash3-fill btn btn-sm bg-danger text-light"></i></td>
                    </tr>
                </tbody>
            </table> 
        </div>                                           
    </div>
</template>

    <script>
    import axios from 'axios'    


    export default {
        components: { 
        },
        data() {
            return {
                isUpdateOfficer:false,
                extensionOfficer:{
                    name:'',
                    email:'',
                    phone:'',
                    institution:'',
                    role:'',
                    password:'',
                    repeat_password:''
                },
                officers:[]
            }
        },
        created(){
            this.getOfficers()
        },
        computed:{
            
        },
        methods:{
            register(e){
                e.preventDefault();
                var token = this.getCookie('token')
                axios.post('https://aghub.miphost.com/api/broadcast', this.extensionOfficer,
                    { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.extensionOfficer = {
                        name:'',
                        email:'',
                        phone:'',
                        institution:'',
                        role:'',
                        password:'',
                        repeat_password:''
                    }
                    this.getOfficers()
                }).catch(error => {
                    console.log(error);
                })
            },
            getOfficers(){
                var token = this.getCookie('token')
                axios.get('https://aghub.miphost.com/api/broadcast',
                    { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.officers = response.data
                }).catch(error => {
                    console.log(error);
                })
            },
            deleteOfficer(id){
                var token = this.getCookie('token')
                axios.get('https://aghub.miphost.com/api/broadcast/delete/'+ id,
                    { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {                    
                    this.getOfficers()
                }).catch(error => {
                    console.log(error);
                })
            },
            updateOfficer(){
                var token = this.getCookie('token')
                const officerId = this.extensionOfficer.id
                delete this.extensionOfficer.id
                axios.post('https://aghub.miphost.com/api/broadcast/update/'+ officerId, this.extensionOfficer,
                    { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.getOfficers()
                }).catch(error => {
                    console.log(error);
                })
            },  
             getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
            },

        }
    }
</script>
<style scoped>
    .action_badge:hover {
        background-color: #86d1fc;
        border-radius: 50%;
        
    }
    .input-group-text{
        background-color:#e9ecef00 !important;
        border: none !important;
        padding: 0px !important;
    }
    .input-group-text:hover{
        color:#037205 !important;
        cursor: pointer;
    }
    .btn-close{
        width: 3px !important;
        height: 3px !important;
        margin-top: 6px;
    }
    .alert a{
         margin-top: 3px !important;
         opacity: .6;

    }
    .alert a:hover{
         opacity: 1;
    }
    .alert{
        padding: 2px 14px !important;
        border-radius: 50rem!important;
    }
        /* we will explain what these classes do next! */
        .v-enter-active,
        .v-leave-active {
        transition: opacity 2.5s ease;
        }

        .v-enter-from,
        .v-leave-to {
        opacity: 0;
        }
    .dropdown-toggle::after {
        display: none !important;
    }
    .card{
        margin-top:4%;
        /* min-height: 90% !important; */
        min-width: 80% !important;
        /* overflow-x: scroll; */
        border-radius: 1rem;
        transition: 0.5s;
        background-color: #ffffff !important;
    }

    .stockList:hover>* {
        --bs-table-accent-bg: rgb(0 0 0 / 0%) !important;
    }

    .table-sm>:not(caption)>*>* {
        padding: 0.6rem .25rem !important;
    }

/* **************************** */

.form-control-sm, .form-select-sm  {
    background-color: #1a222600;
    border: none;
    border-bottom: 2px solid #dc3545;
    border-top: 0px;
    border-radius: 0px;
    font-weight:100;
    outline: 0;
    margin-bottom: 20px;
    padding-left: 10px;
    color: #202020;
}

.form-control-sm, .form-select-sm{
    font-size: 0.75rem !important;
}
.form-control:focus, .form-select:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px solid #dc3545 !important ;
    outline: 0;
    background-color: #31383b00;
    color: #333333;
}

input:focus {
    outline: none;
    box-shadow: 0 0 0;
}

label {
    margin-bottom: 0px;
}

.form-label {
    font-size: 10px;
    color: #6C6C6C;
    font-weight:100;
    letter-spacing: 1px;
}

.spinner-border {
    display: block  !important;;
    width: 1rem;
    height: 1rem;
    color: #dc3545;
    border: .2em solid currentColor;
    border-right-color: transparent !important;
}
.spinner-grow-sm {
    width: .45rem;
    height: .45rem;
    vertical-align: 0.576em !important;
    color: #f68888;
}
</style>
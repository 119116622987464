<template>
  <div class="container my-5  ">
    <p>Welcome to AG-DATAHUB Support</p>
    <h1 class="fw-bold">Choose a Topic</h1>

    <div class="row row-cols-1 row-cols-md-3 g-3 d-flex justify-content-center">
      <div class="col" v-for="(item, i) in faqTopics" :key="i" >
        <a href="#scrollspyHeading1" @click="faqComment = item.comment, faqTopic = item.title">
          <div class="card h-100 pt-3 shadow mx-auto">
            <div class="card-body">
              <span class=" border fs-1 border-light rounded-2 p-3 ">
                <i class="bi" :class="item.icon"></i>
              </span>
              <p class="card-title d-block mt-4">{{item.title}}</p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <br>
    <div>
      <h1 class="mt-3 text-start">- Frequently Asked Questions</h1>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item bg-transparent border-0" v-for="(item, i) in faqs " :key="i">
          <h2 class="accordion-header">
            <button class="accordion-button  bg-transparent text-black collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+ i" aria-expanded="false" :aria-controls="'collapse' + i">
             {{i+1}}. {{item.question}}
            </button>
          </h2>
          <div :id="'collapse'+ i" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body ms-4 text-start">
              - {{item.answer}}
            </div>
          </div>
        </div>
      </div>              
    </div>
    
    <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true" class="scrollspy-example bg-body-tertiary my-5 text-start rounded-2" tabindex="0">
      <h4 id="scrollspyHeading1" class="fw-bold">How it works</h4>
      <h5 style="font-size:15px" class="fw-bold ms-3"> - {{faqTopic}}</h5>
      <p>{{faqComment}}</p>

    <br> <br>
    <h1 class="mt-y">- For Support       <i class="bi bi-headset fs-2 mx-auto"></i></h1>
    <div class="card h-100 mb-5 shadow mx-auto" id="scrollspyHeading2">
      <div class="card-body h-100 d-lg-flex justify-content-between  mx-4">
        <div class="d-flex align-content-center">
          <div class="my-auto">
             <p class="card-title d-block mb-0 mt-2 fw-bold"> Phone     <i class="bi bi-telephone-fill"></i> </p>
              <ul class="p-0">
                <li class="list-group-item text-light p-0">+233 542 7455 221</li>
                <li class="list-group-item text-light p-0 ">+233 204 5521 445</li>
              </ul>
              <p class="m-0 fw-bold">Email   <i class="bi bi-envelope-fill"></i></p>
              <a href="#" class="p-0 text-decoration-none text-light">info@aghubghana.com</a>
          </div>
        </div>
        <div>
          <h4 class="mt-3">Send us a Message</h4>
            <div class="row">
              <div class="col">
                <label for="exampleFormControlTextarea1 " style="font-size:12px !important" class="form-label m-0">Your Email</label>
                <input type="text" v-model="mail.email" class="form-control form-control-sm" aria-label="First name">
              </div>
              <div class="col">
                <label for="exampleFormControlTextarea1 " style="font-size:12px !important" class="form-label m-0 ">Subject</label>
                <input type="text" v-model="mail.subject" class="form-control form-control-sm"  aria-label="Last name">
              </div>
            </div>
            <div class="my-3">
              <label for="exampleFormControlTextarea1 " style="font-size:12px !important" class="form-label">Message</label>
              <textarea class="form-control form-control-sm" v-model="mail.msg" id="exampleFormControlTextarea1" rows="3"></textarea>
            </div>
            <button class="btn btn-sm rounded-pill text-light px-3 float-end" @click="sendMail()">
              <div id="loader" v-if="loading">
                <div class="spinner-border text-light spinner-border-sm " role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <span v-else>Send</span>
              
              </button>
        </div>
      </div>
    </div>
    </div> 

    

    <a href="#" class="float">
      <i class="bi bi-arrow-up-short my-float fs-5"></i>
    </a> 

    <a href="#scrollspyHeading2" class="float2 d-flex align-content-center">
      <i class="bi bi-headset my-float2 fs-3 mx-auto"></i>
    </a> 

  </div>
  <br>
  <br>

</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {
            mail:{
              email:'',
              subject:'',
              msg:''
            },
            displayAnswer:false,
            loading:false,
            faqComment:'',
            faqTopic:'',
            faqs:[
              {
                question:'How do I add data to datasets?',
                answer:'you\'ll need to create an account and to be able to add, however your uploaded dataset will need to be approved by the Aghub team'
              },
              {
                question:'Why can\'t I download dataset?',
                answer:'if you cant download a dataset it probably because the owner of such data has declared it private, if not so kindly contact us or leave a message and we will revert'
              },
              {
                question:'What is the purpose of the cropping calendar?',
                answer:'The cropping calender provides the farmer a sequential order of activities to be taken on the farm this is calculated based on the onset of rain. this details when to plant, harvest, weed and so on'
              },
              {
                question:'what is the extension portal',
                answer:'the extension portal is managed by extension managers, who serve farmers(extensions) with educative materials, this portal allows extension officers to send messages to farmers via email and sms portals'
              },
              {
                question:'How do I signup as an extension manager or officer',
                answer:'To registered in or system as an officer kindly leave as a message with your contact and we will get back to you. Terms and Conditions Apply'
              }
            ],
            faqTopics:[
              {
                title:'Using Dashboards',
                icon:'bi-graph-up',
                comment:' given the vast amount of data collected by AICCRA and Africa Rising, it may not be possible to explore all possible scenarios comprehensively. Therefore, I invite you to select a data source and specific data points of your choice to create a chart interface. This allows you to customize the data visualization to your preferences. Go ahead and choose the data source and the specific data you\'d like to use for charting.'
              },
              {
                title:'Why StorySets',
                icon:'bi-flag',
                comment:'Thank you for offering access to your predefined and analyzed data through story sets. This will indeed expedite the process of visualizing the data. Please provide details on the specific story set you\'d like to use, including any key data points, categories, or themes you\'d like to focus on. With this information, I can proceed to create a data visualization or chart interface to help you gain insights from the provided data.'
              },
              {
                title:'Understanding Dataset',
                icon:'bi-clipboard2-data',
                comment:'This section consist of collections of data gathered by your team, some of which are designated as public, allowing for downloading, while others are kept private. Please specify whether you\'d like to work with a public dataset that\'s available for download, and if so, provide details about the dataset\'s subject or theme. Once you share these details, I can assist in utilizing the public dataset to create data visualizations or conduct data analysis as needed.'
              },
              {
                title:'Services Detailing',
                icon:'bi-gear-wide-connected',
                comment:'services offer a suite of pre-built tools created to provide users with access to a broad spectrum of solutions and data. These tools include features like a weather application, a cropping calendar, a comparison of live and forecast data, as well as a farmer and extension portal. These services cater to a diverse range of needs, particularly in the realm of agriculture and related fields.'
              },
              {
                title:'Resource Contents',
                icon:'bi-book',
                comment:'Resources provides you with go-to web portal for comprehensive data resources. Discover and access a wide range of data from reliable sources, empowering your research, analysis, and decision-making process'
              }
            ]
        }
    }, 
    created(){
      this.faqComment = this.faqTopics[0].comment
      this.faqTopic = this.faqTopics[0].title
    } ,
    methods: {
      sendMail(){
        this.loading = true
            axios.post('https://aghub.miphost.com/api/mail/receive',{
              email:this.mail.email,
              subject:this.mail.subject,
              msg: this.mail.msg
            })
            .then(response =>  {
              console.log(response)
              this.loading = false
            }).catch(error => {
                console.log(error)
            })
      }
    },
}
</script>

<style scoped>
  a{
    text-decoration: none;
  }
  .card{
    /* width: 200px !important; */
    background-color:#e6b600 !important;
    color: #fff;
    border: none;

  }
  li{
    background-color: transparent !important;
    border: none;
  }
  .accordion-button:focus {
    box-shadow: none
}
  .float{
	position:fixed;
	width:30px;
	height:30px;
	bottom:80px;
	right:40px;
	background-color:rgb(204, 0, 0);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:20px;
}

.float2{
	position:fixed;
	width:50px;
	height:50px;
	top:80px;
	right:40px;
	background-color:rgb(204, 0, 0);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float2{
	margin-top:7px;
}

.form-control{
  background-color: transparent;
  border-bottom:1px solid #306102 !important;
  border:none;
  border-radius: 0px;
  color: #fff !important;
}

.form-control:focus{
  background-color: transparent;
  border-bottom:2px solid #306102 !important;
  border:none;
  border-radius: 0px;
  box-shadow: none;
}

    .btn{
        background-color:#306102 !important;
    }
    .btn:hover{
        border:1px solid #ffffff !important;
    }
</style>
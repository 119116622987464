<template>
    <div>
        <div class="container">
            <header class="blog-header py-1">
            </header>

            <div class="nav-scroller py-1 mb-2">
                <nav class="nav d-flex justify-content-between align-items-between">
                    <a class="navbar-brand" style="width:10%;" href="#"><img class="ms-0 w-100"
                            src="../assets/logo5.png"></a>
                    <div class="mw-100 text-center my-3">
                        <div class="row row-cols-3 row-cols-sm-3 row-cols-md-6">
                            <div class="col">
                                <router-link class="nav-ink text-decoration-none " style="color: #000;"
                                    to="/main/services" @click="active = 'cropping'">Services</router-link>
                            </div>
                            <div class="col">
                                <router-link class="nav-ink text-decoration-none " style="color: #000;"
                                    to="/main/dashboard" @click="active = 'Dashbords'">Dashbords</router-link>
                            </div>
                            <div class="col">
                                <router-link class="nav-ink text-decoration-none " style="color: #000;"
                                    to="/main/storyset" @click="active = 'Storyset'">StorySet</router-link>
                            </div>
                            <div class="col">
                                <router-link class="nav-ink text-decoration-none " style="color: #000;"
                                    to="/main/datasets" @click="active = 'Dataset'">Dataset</router-link>
                            </div>
                            <div class="col">
                                <router-link class="nav-ink text-decoration-none " style="color: #000;"
                                    to="/main/resources" @click="active = 'Resources'">Resources</router-link>
                            </div>
                            <div class="col">
                                <router-link class="nav-ink text-decoration-none " style="color: #000;" to="/main/faq"
                                    @click="active = 'faq'">Support</router-link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <main class="container">
            <div class="p-4 p-md-5 mb-4 text-white rounded jumbo ">
                <div class="col-md-12 px-0 ">
                    <h1 class="display-4 fw-bold text-light w-50 text-start"> <mark class="title">AG-Data Hub Ghana's
                            Approach </mark> </h1>
                    <p class="lead my-3 w-75 text-start p-1"> <mark class="subtext">Growing insights, harvesting success
                            and Empowering Agriculture through Data. Smart data for smarter agriculture. Your partner in
                            data-driven agriculture.</mark> </p>
                </div>
            </div>
            <div class="text-start w-75">
                <h2 class="blog-post-title">THE CLUSTER PROJECT</h2>
                <p>Creating seamless access to data and information along the agricultural value chain promises a
                    radical change in transforming agriculture and food systems around the world, towards eliminating
                    hunger and poverty, creating truly sustainable climate smart systems and meeting the 2030
                    Sustainable Development. It is in the light of this that the Ghana Agricultural Data Hub (Ag-Data
                    Hub) is being developed as a secure mission-critical, reliable, multi-tier and multipurpose digital
                    data exchange backbone for information and data sharing among stakeholders and for the development
                    of fact-based decision support systems to drive policy-making and to trigger a move towards
                    sustainable climate smart agricultural practices.</p>
                <hr>
            </div>
            <div class="position-relative">
                <!-- <a  class="float align-middle " href="#offcanvasExample"  aria-controls="offcanvasExample" @click="previous()">
                <i class="bi bi-chevron-left fs-4 " style="left: 6.4px;"></i>
            </a> -->
                <i class=" float fs-3 bi bi-arrow-left-circle-fill" @click="previous()"></i>
                <!-- <a  class="float2 align-middle"  href="#offcanvasExample"  aria-controls="offcanvasExample"  @click="nextitem()" >
                <i class="bi bi-chevron-right fs-4" style="left: 10.4px;"></i>
            </a> -->
                <i class=" float2 fs-3 bi bi-arrow-right-circle-fill" @click="nextitem()"></i>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <div
                            class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static text-start" style="min-height: 3in">
                                <h3 class="mb-2">{{ displayParam[0].Title }}</h3>
                                <p class="card-text mb-auto">{{ displayParam[0].text }}</p>
                                <div>
                                    <router-link :to="displayParam[0].link"
                                        class="btn btn-sm text-light  border-white rounded-pill px-4 ">View More <i
                                            class="bi bi-arrow-right-short"></i></router-link>
                                </div>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img :src="require('../assets/' + displayParam[0].img)" width="200" height="250"
                                    style="object-fit:contain" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div
                            class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                            <div class="col p-4 d-flex flex-column position-static text-start" style="min-height: 3in">
                                <h3 class="mb-0">{{ displayParam[1].Title }}</h3>
                                <p class="card-text mb-auto">{{ displayParam[1].text }}</p>
                                <div>
                                    <router-link :to="displayParam[1].link"
                                        class="btn btn-sm text-light  border-white rounded-pill px-4 ">View More <i
                                            class="bi bi-arrow-right-short"></i></router-link>
                                </div>
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <img :src="require('../assets/' + displayParam[1].img)" width="200" height="250"
                                    style="object-fit:contain" alt="">
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class=" mt-4 row g-5">
                <div class="col-md-12 mb-5">
                    <div class="position-sticky" style="top: 2rem;">
                        <div class="p-4 mb-3 bg-transparent rounded">
                            <h4 class="fst-italic text-start">Overview</h4>
                            <p class="mb-0 text-dark text-start">The project, funded by the World Bank and led by IITA,
                                seeks to scale the most strategic and impactful CGIAR Climate Change, Agriculture and
                                Food Security (CCAFS)-Africa programs that promote resilience to climate change and
                                improved food security. The focus is on six World Bank International Development
                                Association (IDA)-eligible countries in Africa, including Ghana.</p>
                        </div>

                        <!-- <div class="p-4">
                <h4 class="fst-italic">Archives</h4>
                <ol class="list-unstyled mb-0">
                    <li><a href="#">March 2021</a></li>
                    <li><a href="#">February 2021</a></li>
                    <li><a href="#">January 2021</a></li>
                    <li><a href="#">December 2020</a></li>
                    <li><a href="#">November 2020</a></li>
                    <li><a href="#">October 2020</a></li>
                    <li><a href="#">September 2020</a></li>
                    <li><a href="#">August 2020</a></li>
                    <li><a href="#">July 2020</a></li>
                    <li><a href="#">June 2020</a></li>
                    <li><a href="#">May 2020</a></li>
                    <li><a href="#">April 2020</a></li>
                </ol>
                </div>

                <div class="p-4">
                    <h4 class="fst-italic">Elsewhere</h4>
                    <ol class="list-unstyled">
                        <li><a href="#">GitHub</a></li>
                        <li><a href="#">Twitter</a></li>
                        <li><a href="#">Facebook</a></li>
                    </ol>
                </div> -->
                    </div>
                </div>
                <div class="col-md-12">
                    <article class="blog-post text-start">
                        <h2>Our Partners</h2>
                        <vueper-slides class="no-shadow" :visible-slides="8" :gap="7" :duration="3000" :bullets="false"
                            :autoplay="true" :arrows="false" :slide-ratio="1 / 4" :dragging-distance="70">
                            <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide" />
                        </vueper-slides>
                        <!-- <img src="../assets/imgstk.png" style="max-width: 60%;" alt=""> -->


                        <!-- <blockquote class="blockquote">
                <p>Quoted text goes here.</p>
                </blockquote>
                <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how a longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
                <h3>Example lists</h3>
                <p>This is some additional paragraph placeholder content. It's a slightly shorter version of the other highly repetitive body text used throughout. This is an example unordered list:</p>
                <ul>
                <li>First list item</li>
                <li>Second list item with a longer description</li>
                <li>Third list item to close it out</li>
                </ul>
                <p>And this is an ordered list:</p>
                <ol>
                <li>First list item</li>
                <li>Second list item with a longer description</li>
                <li>Third list item to close it out</li>
                </ol>
                <p>And this is a definiton list:</p>
                <dl> -->
                        <!-- <dt>HyperText Markup Language (HTML)</dt>
                <dd>The language used to describe and define the content of a Web page</dd>
                <dt>Cascading Style Sheets (CSS)</dt>
                <dd>Used to describe the appearance of Web content</dd>
                <dt>JavaScript (JS)</dt>
                <dd>The programming language used to build advanced Web sites and applications</dd>
                </dl>
                <h2>Inline HTML elements</h2>
                <p>HTML defines a long list of available inline tags, a complete list of which can be found on the <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element">Mozilla Developer Network</a>.</p>
                <ul>
                <li><strong>To bold text</strong>, use <code class="language-plaintext highlighter-rouge">&lt;strong&gt;</code>.</li>
                <li><em>To italicize text</em>, use <code class="language-plaintext highlighter-rouge">&lt;em&gt;</code>.</li>
                <li>Abbreviations, like <abbr title="HyperText Markup Langage">HTML</abbr> should use <code class="language-plaintext highlighter-rouge">&lt;abbr&gt;</code>, with an optional <code class="language-plaintext highlighter-rouge">title</code> attribute for the full phrase.</li>
                <li>Citations, like <cite>— Mark Otto</cite>, should use <code class="language-plaintext highlighter-rouge">&lt;cite&gt;</code>.</li>
                <li><del>Deleted</del> text should use <code class="language-plaintext highlighter-rouge">&lt;del&gt;</code> and <ins>inserted</ins> text should use <code class="language-plaintext highlighter-rouge">&lt;ins&gt;</code>.</li>
                <li>Superscript <sup>text</sup> uses <code class="language-plaintext highlighter-rouge">&lt;sup&gt;</code> and subscript <sub>text</sub> uses <code class="language-plaintext highlighter-rouge">&lt;sub&gt;</code>.</li>
                </ul>
                <p>Most of these elements are styled by browsers with few modifications on our part.</p>
                <h2>Heading</h2>
                <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how a longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
                <h3>Sub-heading</h3>
                <p>This is some additional paragraph placeholder content. It has been written to fill the available space and show how a longer snippet of text affects the surrounding content. We'll repeat it often to keep the demonstration flowing, so be on the lookout for this exact same string of text.</p>
                <pre><code>Example code block</code></pre>
                <p>This is some additional paragraph placeholder content. It's a slightly shorter version of the other highly repetitive body text used throughout.</p> -->
                    </article>


                </div>


            </div>

        </main>

        <footer class=" container blog-footer d-flex justify-content-between border-top border-1 p-4 "
            style="border-color  : #e2ae00 !important;">
            <small>Want to know more about us @ <a href="http://poc.miphost.com/"
                    class=" text-decoration-none ">poc.miphost.com</a> let's walk you through our Proof of
                Concepts</small>

            <p>
                <span class=" ">&copy; {{ new Date(Date.now()).getFullYear() }} Ag-Datahub </span>
                <a href="#" class="text-decoration-none " style="color: #e2ae00;"> <i
                        class="bi bi-arrow-up-circle"></i></a>
            </p>
        </footer>
    </div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    components: {
        VueperSlides,
        VueperSlide,
    },
    data() {
        return {
            slides: [
                require("../assets/stk/Layer 2.png"),
                require("../assets/stk/Layer 3.png"),
                require("../assets/stk/Layer 4.png"),
                require("../assets/stk/Layer 5.png"),
                require("../assets/stk/Layer 18.png"),
                require("../assets/stk/Layer 6.png"),
                require("../assets/stk/Layer 7.png"),
                require("../assets/stk/Layer 8.png"),
                require("../assets/stk/Layer 9.png"),
                require("../assets/stk/Layer 10.png"),
                require("../assets/stk/Layer 11.png"),
                require("../assets/stk/Layer 12.png"),
                require("../assets/stk/Layer 13.png"),
                require("../assets/stk/Layer 14.png"),
                require("../assets/stk/Layer 15.png"),
                require("../assets/stk/Layer 16.png"),
                require("../assets/stk/Layer 17.png"),


            ],
            vectordetails: [
                {
                    id: 0,
                    img: 'service.png',
                    Title: 'Services',
                    text: 'Utilize our Services created by our team of expects to assist you in your field of intrest, these are Applications created from the very data found here, these have been refined to develop Apps that suit you ',
                    link: '/main/services'
                },
                {
                    id: 1,
                    img: 'dash.png',
                    Title: 'Dashboards',
                    text: 'What of you could Select a dataset and choose a data field that intrests you, and we\ll populate graphs and charts that suit your selected data fields and if you are still not satisfied with the default charts you have options. This is an opportunity for you to tell your own story, be your own god.',
                    link: '/main/dashboard'
                },
                {
                    id: 2,
                    img: 'jigsaw.png',
                    Title: 'StorySets',
                    text: 'This details findings from exploratory to in-depth analysis (quantitative and qualitative) of a decade of data gathered and stored by the Africa RISING Dataverse portal. However, highlights are thrown on other crops of interest where the data supports our objective.',
                    link: '/main/storyset'
                },
                {
                    id: 3,
                    img: 'set.png',
                    Title: 'DataSets',
                    text: ' Inside AgData-hub you\'ll find all the data you need to workon your projects and research. So explore our datasets, these are collections of data of prefered all fields and backgrounds from stakeholders both public and private, this means you get to upload and share your datasets with the etire community, just sign up to upload',
                    link: '/main/datasets'
                },
                {
                    id: 4,
                    img: 'e-learning.png',
                    Title: 'Farm Academy',
                    text: ' Our e-learning platform for farmers provides accessible and comprehensive training to improve agricultural practices. Our expert instructors deliver interactive courses on crop management, soil health, irrigation techniques, and more. Join our community of lifelong learners today.',
                    link: '/learn'
                },
            ],
            displayParam: [],
        }
    },
    created() {
        this.displayParam.push(this.vectordetails[0])
        this.displayParam.push(this.vectordetails[1])
    },
    methods: {
        changeTopic(param) {
        },
        previous() {
            let prevIndex = this.displayParam[0].id
            if (prevIndex == 0) prevIndex = this.vectordetails.length
            this.displayParam.pop()
            this.displayParam.unshift(this.vectordetails[prevIndex - 1])
            console.log(this.displayParam)
        },
        nextitem() {
            console.log(this.displayParam[1].id)
            let nextIndex = this.displayParam[1].id
            if (nextIndex == this.vectordetails.length - 1) nextIndex = -1
            this.displayParam.shift()
            this.displayParam.push(this.vectordetails[nextIndex + 1])
        }
    }
};
</script>
<style scoped>
.vueperslide {
    background-size: contain;
    position: relative;
    background-repeat: no-repeat;
    /* width: 8% !important; */
}


mark {
    -webkit-animation: 1.5s highlight 1.5s 1 normal forwards;
    animation: 1.5s highlight 1.5s 1 normal forwards;
    background-color: none;
    background: linear-gradient(90deg, #e60000af 50%, rgba(255, 255, 255, 0) 50%);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: #fff;
    padding-block: 0px;
}


/* .subtext {
            background: linear-gradient(90deg, #e6b600c9 50%, rgba(255, 255, 255, 0) 50%);
        } */
p .subtext {
    -webkit-animation: 2s highlight 2s 1 normal forwards;
    animation: 2s highlight 2s 1 normal forwards;
    background-color: none;
    background: linear-gradient(90deg, #e6b600c9 50%, rgba(255, 255, 255, 0) 50%);
    background-size: 200% 100%;
    background-position: 100% 0;
    color: #fff;
    padding-block: 0px;
}

@-webkit-keyframes highlight {
    to {
        background-position: 0 0;
    }
}

@keyframes highlight {
    to {
        background-position: 0 0;
    }
}



.jumbo {
    background-image: url('../assets/cocoa.jpeg');
    background-size: cover;
    background-position: center;
}

.row .border {
    border: 1.2px solid #306102c4 !important;
}

.text-pink {
    color: #e6b400c8;
}

.btn {
    background-color: #e6b600 !important;
}

.btn:hover {
    background-color: #306102 !important;
}

.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.nav-ink {
    margin-inline: 8px;
    padding-inline: 7px;
    padding-block: 3px;
    border-radius: 50px;
    background-color: transparent;
    transition: background-color 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);

}

.nav-ink:hover {
    background-color: #e6b600;
    color: #fff !important;
    border-radius: 50px;

}

div .col-md-6 {
    height: unset;
}

.row {
    height: 100%;
}

.float {
    position: absolute;
    /* height:40px; */
    bottom: 50%;
    left: -15px;
    /* background-color:#f56a6a; */
    color: #e6b600;
    border-radius: 50px;
    transition: transform .2s;
    text-align: center;
    z-index: 1;
    /* width: 40px !important; */
    /* box-shadow: 0px 0px 5px #999; */
}

.float2 {
    position: absolute;
    /* height:40px; */
    bottom: 50%;
    /* width: 40px !important; */
    right: -15px;
    /* background-color:#f56a6a; */
    color: #e6b600;
    border-radius: 50px;
    z-index: 1;
    transition: transform .2s;
    text-align: center;
    /* box-shadow: 0px 0px 5px #999; */
}

.my-float {
    position: absolute;
    top: 3.5px;
}

.float:hover {
    transform: scale(1.1);
    color: #e6b600d8;

}

.float2:hover {
    transform: scale(1.1);
    color: #e6b600d8;

}
</style>
<template>
  <div style="height:700px; width:100%" class="position-relative " >
  	<div id="searchbar" class="ui container position-absolute w-lg-25 w-sm-50 mt-3">
			<div class="ui centered grid">
				<div class="row">
					<div class="column">
						<div class="ui action input">
							<input type="text" id="searchInput" class="form-control shadow rounded-5 form-control-sm " v-model="searchInput" @keyup="filterResults()" placeholder="Search...">
						</div>
					</div>
				</div>
			</div>
		</div>

    <l-map ref="map" v-model:zoom="zoom" :center="[7.998338, -1.213402]">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
        <l-geo-json :geojson="geojson" :options-style="geoStyler"  @popupopen="onPopupOpen">
          <l-popup ref="popup" >
            <p>Region: {{msg.REGION}}</p>
            <p>Capital: {{msg.DISTRICT}}</p>
            <p>Sensors: 32C</p>
          </l-popup>
        </l-geo-json >
    </l-map> 

    <div id="sidebar" class="ui segment position-absolute top-0 end-0" v-if="districtData.length > 0">
    <i class="bi bi-x fw-bold icon-link icon-link-hover" @click="districtData=[], closePopup()"></i>
      <div class="ui segment bg-white p-2 mb-3 shadow rounded-3 text-justify" v-for="(district, index) in districtData" :key="index">
        <h3 class="ui header">{{district["REGION"]}}</h3>
        <p>Description: {{district["DISTRICT"]}}</p>
        <!-- <router-link class="read-more-link" :to="{ name: 'MapDetails', params: { id: project['FID'] }}">Read more</router-link> -->
      </div>
    </div>

  </div>

</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LGeoJson, LPopup, LMarker } from "@vue-leaflet/vue-leaflet";
import lunr from 'lunr';

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LPopup,
    LMarker,
  },
  data() {
    return {
      msg: {},
      index: 0,
      districtData: [],
      searchInput: '',
      zoom: 7,
      jData: [],
      geojson: null,
      geoStyler: (feature) => ({
        opacity: feature.properties.code / 100000,
        radius: 8,
        fillColor: '#198754',
        color: '#fff',
        weight: 1,
        fillOpacity: 0.5,
      }),
    };
  },
  async created() {
    this.getGeoJsonData();
  },
  methods: {
    onPopupOpen(data) {
      const content = data.layer.feature.properties;
      this.msg = content;
      this.displayDistrictInfo(this.msg.DISTRICT);
    },
    async getGeoJsonData() {
      try {
        const response = await fetch("/data/map_data/Ghana_New_260_District.geojson");
        if (!response.ok) {
          throw new Error(`Failed to fetch GeoJSON file: ${response.statusText}`);
        }
        this.geojson = await response.json();
        let jsonData = this.geojson.features;
        let jdataLocal = []
        jsonData.forEach((district)=>{
          this.jData.push(district.properties)
          jdataLocal.push(district.properties)
        })
        this.index = lunr(function () {
          this.ref('FID');
          this.field('REGION');
          this.field('DISTRICT');
          jdataLocal.forEach((doc) => {
            this.add(doc);
          });
        });

      } catch (error) {
        console.error(error);
      }

    },
    displayDistrictInfo(districtName) {
      this.districtData = this.jData.filter(district => district["DISTRICT"].toLowerCase().includes(districtName.toLowerCase()));
    },
    filterResults() {
       let district= this.jData.filter(district => district["DISTRICT"].toLowerCase().includes(this.searchInput.toLowerCase()));
       let region= this.jData.filter(district => district["REGION"].toLowerCase().includes(this.searchInput.toLowerCase()));

       this.districtData = district.concat(region);
      // const searchRes = [];
      // const searchString = this.searchInput;

      // const searchResults = this.index.search(searchString);
      // console.log(searchResults)
      // searchResults.forEach((result) => {
      //             console.log(this.jData, typeof(result.ref))

      //   const matchingDistrict = this.jData.find(district => district["FID"] == result.ref);
      //   if (matchingDistrict) {
      //     searchRes.push(matchingDistrict);
      //   }
      // });
      // this.districtData = searchRes;

    },
    closePopup() {
      console.log(this.$refs.popup);
    },
  },
};
</script>


<style scoped>
#sidebar {
  text-align: justify;
  width: 400px;
  height: 700px;
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
#searchbar {
  top: 5%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
@media (max-width: 575.98px) {
    .w-sm-50 {
        width: 50%;
    }
    #searchbar {
      top: 5%;
      left: 35%;
      transform: translate(-50%, -50%);
      z-index: 1000;
    }
}

@media (min-width: 576px) {
    .w-lg-25 {
        width: 25%;
    }
}
</style>
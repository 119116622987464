<template>
  <div style="height:700px; width:100%" class="position-relative " >
  	<div id="searchbar" class="ui container position-absolute w-lg-25 w-sm-50 mt-3">
			<div class="ui centered grid">
				<div class="row">
					<div class="column">
						<div class="ui action input">
							<input type="text" id="searchInput" class="form-control shadow rounded-5 form-control-sm " v-model="searchInput" @keyup="filterResults()" placeholder="Search...">
						</div>
					</div>
				</div>
			</div>
		</div>

    <l-map ref="map" v-model:zoom="zoom" :center="[7.998338, -1.213402]">
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
        <l-geo-json :geojson="geojson" :options-style="geoStyler"  @popupopen="onPopupOpen">
          <l-popup ref="popup" >
            <p>Region: {{msg.region}}</p>
            <p>Capital: {{msg.capital}}</p>
            <p>Total Projects: {{msg.projects}}</p>
          </l-popup>
        </l-geo-json >
    </l-map> 

    <div id="sidebar" class="ui segment position-absolute top-0 end-0" v-if="districtData.length > 0">
    <i class="bi bi-x fw-bold icon-link icon-link-hover" @click="districtData=[], closePopup()"></i>
      <div class="ui segment bg-white p-2 mb-3 shadow rounded-3 text-justify" v-for="(project, index) in districtData" :key="index">
        <h3 class="ui header">{{project["Name of Project"]}}</h3>
        <p>Description: {{project["Description"]}}</p>
        <p>Region: {{project["Region"]}}</p>
        <router-link class="read-more-link" :to="{ name: 'MapDetails', params: { id: project['UID'] }}">Read more</router-link>
      </div>
    </div>

  </div>

</template>

<script>
import "leaflet/dist/leaflet.css";
import {  LMap, LTileLayer, LGeoJson, LPopup, LMarker} from "@vue-leaflet/vue-leaflet";
import lunr from 'lunr'

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LPopup,
    LMarker,
  },
  data() {
    return {
      msg:[],
      jsonData:[],
      cachedIndex : localStorage.getItem('lunrIndex'),
      index:0,
      districtData:{},
      searchInput : '',
      zoom: 7,
      geojson:null,
      geoStyler: (feature) => ({
        opacity: feature.properties.code / 100000,
        radius: 8,
        fillColor: 'blue',
        color: '#fff',
        weight: 1,
        fillOpacity: 0.5,
      }),
    };
  },
  async created() {
    this.getNgoData()
  try {
    const response = await fetch("/data/map_data/ghana_regions.geojson");
    if (!response.ok) {
      throw new Error(`Failed to fetch GeoJSON file: ${response.statusText}`);
    }
    this.geojson = await response.json();
  } catch (error) {
    console.error(error);
  }
},
methods:{
  onPopupOpen(data){
    var regionName =  data.layer.feature.properties.region;
    var regionProjectsCount = 0;

    if (regionName.toLowerCase() !== "greater accra") {
        regionName += " Region";
    }
    this.jsonData.forEach(project => {
        if (project["Region"].toLowerCase() == regionName.toLowerCase()) {
            regionProjectsCount++;
        }
    });
    
      const popup = this.$refs.popup;
      const content = data.layer.feature.properties;
      this.msg = content
      this.msg.projects = regionProjectsCount
      
      this.displayDistrictInfo(this.msg.region)

  },

  getNgoData(){
    fetch('/data/ngo_data/csvjson.json').then(response => response.json()).then(data => {
    this.jsonData = data
    let jsonData = data; 
    if (!this.cachedIndex) {
        this.index = lunr( function(){
            this.ref('UID');
            this.field('Name of Project');
            this.field('Description');
            this.field('Sponsor-Full');
            this.field('Sponsor-Abb');
            this.field('System');
            this.field('CSA Technology');
            this.field('subcategory');

            jsonData.forEach(function(doc){
                this.add(doc);
            }, this);
        });

        localStorage.setItem('lunrIndex', JSON.stringify(this.index));
    }
    else {
        this.index = lunr.Index.load(JSON.parse(this.cachedIndex));
    }


    });
  },
  displayDistrictInfo(districtName) { 

    this.districtData = this.jsonData.filter(project => project["Region"].toLowerCase().includes(districtName.toLowerCase()))
  },

  filterResults() {
    var searchRes = []
    var searchString = this.searchInput;
    var searchResults = this.index.search(searchString);
        searchResults.forEach( (result)=> {
        var matchingProject = this.jsonData.find(project => project["UID"] == result.ref); // Find matching project
        if (matchingProject) {
          searchRes.push(matchingProject)
        }
    });
    console.log(searchRes)
    this.districtData = searchRes
},
closePopup(){
  console.log(this.$refs.popup)
}
}
}
</script>

<style scoped>
#sidebar {
  text-align: justify;
  width: 400px;
  height: 700px;
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
#searchbar {
  top: 5%;
  left: 20%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
@media (max-width: 575.98px) {
    .w-sm-50 {
        width: 50%;
    }
    #searchbar {
      top: 5%;
      left: 35%;
      transform: translate(-50%, -50%);
      z-index: 1000;
    }
}

@media (min-width: 576px) {
    .w-lg-25 {
        width: 25%;
    }
}
</style>
<template>
        <div class="card bg-light rounded-1 shadow-lg p-3 mb-5">
            <div class="card-body" v-if="!viewMoreInfo">
                <div class="d-flex justify-content-between">
                    <h5 class="card-title text-start fw-bolder" style="color:#061704">Risk Reporting</h5>
                    <ul class="nav nav-tabs justify-content-end">
                        <li class="nav-item" >
                            <a class="nav-link" @click="activeTab='risk'" :class="{ active: activeTab == 'risk'}" aria-current="page" href="#">Risks Reporting</a>
                        </li>
                        <li class="nav-item" v-if="jwt.role == 'admin'">
                            <a class="nav-link" @click="activeTab='zone'" :class="{ active:  activeTab == 'zone'}" href="#">Zone</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" @click="activeTab='assign'" :class="{ active:  activeTab == 'assign'}" href="#">Assign Zone</a>
                        </li>                                                           -->
                    </ul>
                </div>
                <hr>
                <span>          
                    <form class="row g-3 p-4 shadow bg-light text-start" v-if="activeTab == 'zone' && jwt.role == 'admin'">
                        <label for="">New Zone</label>
                        <div class="col-md-4">
                            <label for="inputEmail4" class="form-label">Name</label>
                            <input type="text" v-model="zone.name" class="form-control form-control-sm bg-light"  id="inputEmail4">
                        </div>
                        <div class="col-md-6">
                            <label for="inputPassword4" class="form-label">Description</label>
                            <input type="text" v-model="zone.description" class="form-control form-control-sm bg-light"  id="inputPassword4">
                        </div>
                        <div class="col-md-2">
                            <label for="inputPassword4" class="form-label d-block mb-2">Color</label>
                            <color-picker class="d-block"  v-model:pureColor="zone.color"/>        
                        </div>
                        <div class="col-md-2 pt-4">
                            <button class="btn btn-sm btn-success rounded-3 px-4" v-if="!isLoader" @click="createZone">Create</button>
                            <div class="barLoader " v-if="isLoader "></div>
                            <p v-if="isMsg"></p>
                        </div>
                    </form>
                </span>
                <div  v-if="activeTab == 'risk'">
                    <div class="text-start">
                        <ul class="nav nav-tabs">
                            <li class="nav-item" >
                                <a class="nav-link"  @click="viewRisks = 'listRisks'" :class="{ active: viewRisks =='listRisks'}" aria-current="page" href="#">Risks</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"  @click="viewRisks = 'addRisks'" :class="{ active:  viewRisks == 'addRisks'}" href="#">Add Risk</a>
                            </li> 
                            <li class="nav-item">
                                <a class="nav-link"  @click="viewRisks = 'addCategory'" :class="{ active:  viewRisks == 'addCategory'}" href="#">Add Category</a>
                            </li>                                                         
                        </ul>
                    </div>
                    <div v-if="viewRisks == 'addCategory'" class="mt-5"  >
                        <form class="row g-3 p-4 shadow bg-light text-start" @submit.prevent="addCategory()">
                            <label for="">Add Risk Category</label>
                            <div class="col-md-3">
                                <label for="inputEmail4" class="form-label">Name</label>
                                <input type="text" v-model="category" class="form-control form-control-sm bg-light"  id="inputEmail4">
                            </div>
                            <div class="col-md-2">
                                <button type="submit" class="btn btn-sm btn-dark text-light"  v-if="!isLoader "  id="inputEmail4">Add</button>
                                <div class="barLoader " v-if="isLoader "></div>
                                <p v-if="isMsg"></p>
                            </div>
                            <div class="col">
                               <span class="badge bg-dark fw-light text-light m-2" v-for="(category, i) in categories" :key="i">{{category.name}}</span>
                            </div>
                        </form> 
                    </div>
                    <div v-if="viewRisks == 'addRisks'" class="mt-5"  >
                        <form class="row g-3 p-4 mt-4 shadow bg-light text-start justify-content-center" >
                            <div class="col-md-3">
                                <label for="location" class="form-label">Category</label>
                                <select v-model="risk.riskCategoryId" class="form-select form-select-sm" aria-label="Small select example">
                                    <option :value="0">Open this select menu</option>
                                    <option v-for="(category, i) in categories" :key="i" :value="category.id">{{category.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                            <label for="name" class="form-label">Name</label>
                            <input type="text" v-model="risk.name" class="form-control form-control-sm bg-light" id="name">
                            </div>
                            <div class="col-md-3">
                                <label for="location" class="form-label">Zone</label>
                                <select v-model="risk.riskZoneId" class="form-select form-select-sm" aria-label="Small select example">
                                    <option :value="0">Open this select menu</option>
                                    <option v-for="(zone, i) in zones" :key="i" :value="zone.id">{{zone.name}}</option>
                                </select>
                            </div>
                            <div class="col-md-12">
                                <label for="description" class="form-label">Description</label>
                                <textarea v-model="risk.description" class="form-control form-control-sm bg-light" id="description"></textarea>
                            </div>
                            <div class="col-md-2 pt-4">
                                <label for="isActive" class="form-label me-3">Active</label>
                                <input type="checkbox" v-model="risk.isActive" class="form-check-input" id="isActive">
                            </div>
                            <div class="col-md-6">
                                <label for="images" class="form-label">Images</label>
                                <input type="file" multiple @change="handleFileUpload" class="form-control form-control-sm bg-light" id="images">
                            </div>
                            <div class="col-md-4" v-if="isUpdateRisk">
                                <label for="images" style="font-size:12px" class=" d-block">Your location:</label>
                                <label for="images" style="font-size:12px" class="" v-html="JSON.parse(risk.location).name"></label>
                                <label for="images" style="font-size:12px" class=" d-block fw-bold">{{JSON.parse(risk.location).coordinates}}</label>
                            </div>
                            <div class="col-md-4" v-else>
                                <div class="row g-3 align-items-center">
                                    <div class="col-auto">
                                        <span id="passwordHelpInline" class="form-text">
                                        Manual Location
                                        </span>
                                    </div>
                                    <div class="col-auto form-check form-switch">
                                        <input class="form-check-input" v-model="autoLocation" type="checkbox" role="switch" id="flexSwitchCheckChecked">
                                    </div>
                                    <div class="col-auto">
                                        <span id="passwordHelpInline" class="form-text">
                                        Auto Location
                                        </span>
                                    </div>
                                </div>

                                <label for="images" style="font-size:12px" class=" d-block">Your location:</label>
                                <label for="images" style="font-size:12px" class="" v-html="risk.location.name"></label>
                                <label for="images" style="font-size:12px" class=" d-block fw-bold">{{risk.location.coordinates}}</label>

                                <form class="row g-3 mt-1" v-if="!autoLocation">
                                    <div class="col-auto">
                                        <div class="input-group input-group-sm mb-3">
                                            <input class="form-control d-inline" type="text" v-model="searchLocation" @keyup="search(searchLocation)" placeholder="Search">
                                            <button type="button" style="background-color:#c2213d !important" class="btn d-inline text-light" @click="search()">All locations</button>
                                        </div>

                                        <div class="list-group mt-2 text-sm-center text-lg-start overflow-auto" style="max-height:2in">
                                            <a href="#" @click="getLocation(locationData)" style="font-size:11px"  class="text-capitalize list-group-item ps-3 list-group-item-action"
                                                v-for="locationData in locationDataRes" 
                                                :key="locationData.id"  >
                                                {{locationData.location}}
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <span class="border border-1 shadow p-5 mx-5 row g-3 mt-4 bg-light text-start justify-content-center" v-if="jwt.role !== 'user' && isUpdateRisk">
                                <label for="" class="text-decoration-underline ">PPRSD Validation & Approval</label>
                                <div class="col-md-12">
                                    <label for="comments" class="form-label">Comments</label>
                                    <textarea v-model="risk.comments" class="form-control form-control-sm bg-light" id="comments"></textarea>
                                </div>
                                <div class="col-md-12">
                                    <label for="mitigation" class="form-label">Mitigation</label>
                                    <textarea v-model="risk.mitigation" class="form-control form-control-sm bg-light" id="mitigation"></textarea>
                                </div>
                                <div class="col-md-2 pt-4">
                                    <label for="isVerified" class="form-label me-3">Verified & Approved</label>
                                    <input type="checkbox" v-model="risk.isVerified" class="form-check-input" id="isVerified">
                                </div>
                                <div class="col-md-6 pt-4">
                                    <span class="d-inline-flex gap-1 form-label">
                                        <button class="btn btn-danger btn-sm " type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                            Select a Responder for this Risk
                                        </button>
                                    </span>
                                    <div class="collapse" id="collapseExample">
                                        <div class="card card-body">
                                            <div class="overflow-auto" style="font-size:12px">
                                                <div class="">
                                                    <input type="text" v-model="searchVal" class="form-control form-control-sm bg-light" @keyup="searchOfficers()" placeholder="search"  id="inputEmail4">
                                                </div>
                                                <ul class="list-group  list-group-flush">
                                                    <li class="list-group-item" v-for="(officer, index) in officers.filter(item => item.role === 'responder')" :key="index">
                                                        <input
                                                        class="form-check-input me-1"
                                                        type="checkbox"
                                                        :value="officer.id"
                                                        v-model="risk.riskResponders"
                                                        :id="'checkbox-' + officer.id"
                                                        >                                    
                                                        <label class="form-check-label stretched-link" :for="'checkbox-' + officer.id">{{ officer.name }}</label>
                                                    </li>

                                                    <!-- If you want a checkbox to represent all officers -->
                                                    <li class="list-group-item">
                                                        <input
                                                        class="form-check-input me-1"
                                                        type="checkbox"
                                                        @change="toggleAllOfficers"
                                                        :checked="areAllSelected"
                                                        id="checkbox-all-officers"
                                                        >                                   
                                                        <label class="form-check-label stretched-link" for="checkbox-all-officers">All Officers</label> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div class="col-md-12 pt-4" v-if="isLoader">
                                <div class="barLoader" ></div>
                                <p v-if="isMsg"></p>
                            </div>
                            <div class="col-md-12 pt-4" v-else >
                                <button type="button" v-if="!isUpdateRisk" @click.prevent="createRisk()" class="btn btn-sm btn-success rounded-3 px-4">Create</button>
                                <button type="button" v-else @click.prevent="updateRisk(risk.id)" class="btn btn-sm btn-success rounded-3 px-4">Update</button>
                            </div>
                        </form>
                    </div>
                    <div v-if="viewRisks =='listRisks'">
                        <section class="py-1 bg-blueGray-50">
                            <div class="rounded-t mb-0 px-4 py-3 border-0">
                                <div class="d-flex justify-content-end ">
                                    <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                                        <button class="btn btn-success text-light btn btn-sm" @click="switchToMap=!switchToMap" type="button">{{switchToMap ? 'Table View': 'Map View'}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full xl:full mb-12 xl:mb-0 px-4 mx-auto mt-24">
                                <div class="relative flex flex-col min-w-0 break-words  w-full mb-6 shadow-lg rounded ">
                                    <div class="block w-full overflow-x-auto px-3" v-if="!switchToMap">
                                    <table class="items-center bg-transparent w-full table  table-striped-columns table-hover ">
                                        <thead>
                                        <tr>
                                            <th class="px-1 bg-slate-200 text-blueGray-500 align-middle border-blueGray-100 py-1.5 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                Risk
                                            </th>
                                            <th class="px-1 bg-slate-200 text-blueGray-500 align-middle border-blueGray-100 py-1.5 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                Category
                                            </th>
                                            <th class="px-1 bg-slate-200 text-blueGray-500 align-middle border-blueGray-100 py-1.5 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                Zone
                                            </th>
                                            <th class="px-1 bg-slate-200 text-blueGray-500 align-middle border-blueGray-100 py-1.5 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                Status
                                            </th>
                                            <th class="px-1 bg-slate-200 text-blueGray-500 align-middle border-blueGray-100 py-1.5 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                Officer
                                            </th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr v-for="(risk , i) in risks" :key="i" class=" hover:bg-slate-100 " @click="switchToMore(risk)">
                                            <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left">
                                                {{risk.name}}
                                            </th>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left  ">
                                                {{risk.riskCategory.name}}
                                            </td>
                                            <td class="border-t-0 px-6 align-center border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left ">
                                                {{risk.riskZone.name}}
                                            </td>
                                            <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left ">
                                                {{risk.isVerified ? "Verified":"Pending"  }} - {{risk.isActive? 'Active':'Inactive'}}
                                            </td>
                                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left  ">
                                                {{risk.extensionsOfficer.name}}
                                            </td>
                                        </tr>
                                        </tbody>

                                    </table>
                                    </div>
                                    <RiskMap @more_info="switchToMore" :risks="risks" v-if="switchToMap" />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="row g-3 p-4 shadow bg-light text-start mt-3"  v-if="activeTab == 'zone'">
                    <label for="">Assign Extension Officer</label>
                    <div class="row g-3 p-3">
                        <div class="col-md-4" style="font-size:12px">
                            <label for="">Zones</label>
                            <ul  :style="'height:3in'" class="overflow-auto list-group list-group-flush">
                                <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                                 v-for="(zone, index) in zones" :key="index"
                                 @click="getZonesById(zone.id), selectedZone=zone">
                                   {{zone.name}}
                                    <span class="badge bg-primary text-light  rounded-5">14</span>
                                </li>
                               
                            </ul>
                        </div>
                        <div class="col-md-4" style="font-size:12px">
                            <label for="">Extension Officers</label>
                            <ul class="list-group  list-group-flush overflow-y-auto" v-if="zone && zone.extensionsOfficers && zone.extensionsOfficers.length > 0" >
                                <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" 
                                v-for="(officer, index) in zone.extensionsOfficers" :key="index">
                                    {{officer.name}}
                                </li>
                            </ul>
                            <p v-else>No Officers Found</p>
                        </div>
                        <div class="col-md-4" style="font-size:12px">
                            <label for="">Assign Extension Officer to <span class="bg-danger shadow p-1 text-light rounded-5 px-4">{{ Object.keys(selectedZone).length > 0 ? selectedZone.name:'NO ZONE'}}</span> </label>
                            <div class="">
                                <input type="text" v-model="searchVal" class="form-control form-control-sm bg-light" @keyup="searchOfficers()" placeholder="search"  id="inputEmail4">
                            </div>
                            <ul class="list-group  list-group-flush">
                                <li class="list-group-item" v-for="(officer, index) in officers.filter(item => item.role === 'pprsd')" :key="index">
                                    <input
                                        class="form-check-input me-1"
                                        type="checkbox"
                                        :value="officer.id"
                                        v-model="selectedOfficers"
                                        :id="'checkbox-' + officer.id"
                                    >                                    
                                    <label class="form-check-label stretched-link" :for="'checkbox-' + officer.id">{{officer.name}}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class=" pt-4" v-if="isLoader">
                        <div class="barLoader" ></div>
                        <p v-if="isMsg"></p>
                    </div>
                    <button v-else @click="submitSelectedOfficers" class="btn btn-success btn-sm mt-3">Assign Officers to Zone</button>
                </div>
            </div>
            <div v-if="viewMoreInfo">
                <div class="blog-single gray-bg" >
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-lg-8 m-15px-tb">
                            <article class="article">
                                <div class="article-img">
                                   <div class="pt-0">
                                        <div class="row">
                                            <div class="col-2 bg-dark text-light" @click="viewMoreInfo = false">
                                                <i class="bi bi-caret-left-fill"></i> Back
                                            </div>
                                            <div class="col text-white fw-bold" style="background:#2b5207">{{riskView.name}}</div>
                                        </div>
                                    </div> 
                                   <div class="masonry mt-5 px-3" >
                                        <div class="item" :style="'grid-row: span '+ generateRandomNumber(5,8)" v-for="(image , i) in riskView.riskImages" :key="i" >
                                            <img class="riskImages" :src="'https://aghub.miphost.com/risk_files/'+ image.image" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="article-title px-3">
                                    <div class="media d-flex justify-content-between">
                                        <div class="d-flex justify-content-between">
                                            <div class="avatar border-3" :class="riskView.isActive ? 'border-danger ':'border-warning '">
                                                <img  :src="require('../assets/leaf.png')" title="" alt="">
                                            </div>
                                            <p class="mx-2 py-2"  :class="riskView.isActive ? 'text-danger':' text-warning'"> {{riskView.isActive ? 'Active':'Inactive'}}</p>
                                        </div>
                                        <div class="media-body">
                                            <span class="text-start" v-html="JSON.parse(riskView.location).name"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="article-content text-start px-3">
                                    <p>{{riskView.description}}</p>
                                    <span v-if="riskView.comments" >
                                        <h3>PPRSD Officers' Comments</h3>
                                        <div class="widget-body text-start"  v-html="`<p>${riskView.comments.replace(/\r\n/g, '</p><p>')}</p>`"></div>
                                    </span>
                                </div>
                                <div class="nav tag-cloud d-flex justify-content-center px-3">
                                    <a href="#">Reported on :{{new Date(riskView.createdAt).toDateString()}}</a>
                                    <a href="#">Updated on :{{new Date(riskView.updatedAt).toDateString() }}</a>
                                    <a href="#" v-if="riskView && riskView.riskZone">{{riskView.riskZone.name}}</a>
                                </div>
                            </article>
                        </div>
                        <div class="col-lg-4 m-15px-tb blog-aside">
                            <!-- Author -->
                                <l-map ref="map" style="height:300px" v-model:zoom="zoom" :center="JSON.parse(riskView.location).coordinates.split(',')">
                                    <l-tile-layer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    layer-type="base"
                                    name="OpenStreetMap"
                                    ></l-tile-layer>

                                    <l-marker :lat-lng="JSON.parse(riskView.location).coordinates.split(',')" > </l-marker>
                                </l-map>

                            <!-- End Author -->
                            <!-- Trending Post -->
                            <div class="widget widget-post">
                                <div class="widget-title" v-if="riskView.mitigation">
                                    <h3>Recommended Mitigation</h3>
                                    <div class="widget-body text-start"   style="font-size:13px" v-html="`<p>${riskView.mitigation.replace(/\r\n/g, '</p><p>')}</p>`">
                                    </div>
                                </div>
                            </div>
                                <div class="nav tag-cloud d-flex justify-content-center" v-if="jwt.role == 'pprsd' || jwt.role == 'admin'">
                                    <button class="w-100 btn-danger text-light my-3 rounded-sm  btn btn-sm" @click="viewRisks = false, viewMoreInfo = false, risk=riskView, isUpdateRisk = true" >Updated This Risk</button>
                                </div>
                        </div>
                    </div>
                    <div class="widget widget-post shadow-lg p-2 rounded-3">
                        <div class="widget-title text-start" v-if="riskView.respondent_comments">
                            <h3>Other Comments</h3>
                            <div class="widget-body text-start"   style="font-size:13px"  v-for="(comment, index) in JSON.parse(riskView.respondent_comments)" :key="index" >
                                {{ comment }}
                            </div>
                        </div>
                    </div>
                </div>
    </div>
            </div>
        </div>
</template>

    <script>
    import axios from 'axios'
    import RiskMap from './riskMap.vue'
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";

    import "leaflet/dist/leaflet.css";
    import {
    LMap,
    LTileLayer,
    LMarker,
    } from "@vue-leaflet/vue-leaflet";
    import jwtDecode from 'jwt-decode';


    export default {
        components: { 
            LMap,
            LTileLayer,
            LMarker,
            RiskMap,
            ColorPicker
        },
        data() {
            return {
                autoLocation:true,
                isMsg: '',
                isLoader:false,
                zoom: 15,
                center: [7.998338, -1.213402],
                viewMoreInfo:false,
                switchToMap:true,
                viewRisks:'listRisks',
                isUpdateRisk:false,
                activeTab:'risk',
               searchVal:'',
               selectedOfficers: [],
               allRiskResponders:[],
               selectedZone:{},
               locationDataRes:[],
               searchLocation:'',
               zone:{
                name:'',
                description:'',
                color:"red"
               },
               zones:[],
               categories:[],
               category:'',
               officers:[],
               risks:[],
                risk: {
                    riskCategoryId: '',
                    name: '',
                    description: '',
                    riskZoneId:'',
                    location: '',
                    isActive: false,
                    riskCategory:{},
                    riskZone:{},
                    riskResponders:[],
                },
                riskView:{},
                images: []

            }
        },
        created(){
            this.getZones()
            this.getCategories()
            this.getLocation()
            this.getOfficers()
            this.getAllRisks()
            this.jwt = jwtDecode(this.getCookie('token'));
            console.log(this.jwt)
        },
        computed: {
            areAllSelected() {
                if(!this.risk.riskResponders){
                    this.risk.riskResponders = []
                }
                console.log(this.risk.riskResponders)
                return this.officers.length > 0 && this.officers.every(officer => this.risk.riskResponders.includes(officer.id));
            }
        },
        methods: {
            getAllRisks() {
            axios
                .get("https://aghub.miphost.com/api/broadcast/risks")
                .then((response) => {
                this.risks = response.data;
                })
                .catch((error) => {
                console.log(error);
                });
            },
            createZone(e){
                isLoader = true
                e.preventDefault();
                delete this.zone.id
                var token = this.getCookie('token')
                axios.post('https://aghub.miphost.com/api/broadcast/zone', this.zone,
                    { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.getZones()
                    isLoader = false
                }).catch(error => {
                    console.log(error);
                    setTimeout(() => {
                        this.isMsg = true
                        msg = error.response.data.message
                    }, 3000);
                })
            },
            getZones(){
                axios.get('https://aghub.miphost.com/api/broadcast/zone')
                .then(response =>  {
                    this.zones = response.data
                }).catch(error => {
                    console.log(error);
                })
            },
            getZonesById(id){
                axios.get('https://aghub.miphost.com/api/broadcast/zone/'+ id)
                .then(response =>  {
                    this.zone = response.data
                    console.log(this.zone)
                }).catch(error => {
                    console.log(error);
                })
            },
            async getCategories(){
               await axios.get('https://aghub.miphost.com/api/broadcast/risk_categories')
                .then(response =>  {
                    this.categories = response.data
                    this.getCategories()
                }).catch(error => {
                    console.log(error);
                })
            },
            handleFileUpload(event) {
                this.images = event.target.files;
            },
            search(location = null){
                var url = `https://aghub.miphost.com/api/weather/locations/${location}`
                if(!location){
                    url = `https://aghub.miphost.com/api/weather/locations`
                }
                axios.get(url)
                .then(response =>  {
                    this.locationDataRes = response.data
                }).catch(error => {
                    console.log(error);
                })
            },
            getOfficers(){
                var token = this.getCookie('token')
                axios.get('https://aghub.miphost.com/api/broadcast/',
                { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.officers = response.data
                }).catch(error => {
                    console.log(error);
                })
            },
            searchOfficers(){
                var token = this.getCookie('token')
                axios.get('https://aghub.miphost.com/api/broadcast/search?searchVal='+ this.searchVal,
                { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.officers = response.data
                }).catch(error => {
                    console.log(error);
                })
            },
            async createRisk() {
                this.isLoader = true
                var token = this.getCookie('token')
                try {
                    const formData = new FormData();
                    formData.append('riskCategoryId', this.risk.riskCategoryId);
                    formData.append('name', this.risk.name);
                    formData.append('description', this.risk.description);
                    formData.append('location', JSON.stringify(this.risk.location));
                    formData.append('isActive', this.risk.isActive);
                    formData.append('riskZoneId', this.risk.riskZoneId);
                    
                    for (let i = 0; i < this.images.length; i++) {
                    formData.append('images', this.images[i]);
                    }

                    const response = await axios.post('https://aghub.miphost.com/api/broadcast/risk', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                    });
                    this.isLoader = false
                    this.viewRisks = true
                    this.risk = {
                        riskCategoryId: '',
                        name: '',
                        description: '',
                        riskZoneId:'',
                        location: '',
                        isActive: false,
                        riskCategory:{},
                        riskZone:{},
                        riskResponders:[],
                    }                    
                    console.log('Risk created:', response.data);
                } catch (error) {
                    console.error('There was an error creating the risk:', error);
                    setTimeout(() => {
                        this.isMsg = true
                        msg = error.response.data.message
                    }, 3000);
                    this.isMsg = false
                    this.isLoader = false
                }
            },
            async updateRisk(id) {
                this.isLoader = true
                var token = this.getCookie('token')
                delete this.risk.createdAt
                delete this.risk.updatedAt
                delete this.risk.extensionsOfficerId
                delete this.risk.riskZone
                delete this.risk.riskImages
                delete this.risk.extensionsOfficer
                delete this.risk.riskCategory

                
                axios.post('https://aghub.miphost.com/api/broadcast/risk/update/'+id, this.risk,
                    { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.isLoader = false
                }).catch(error => {
                    console.log(error);
                    setTimeout(() => {
                        this.isMsg = true
                        msg = error.response.data.message
                    }, 3000);
                    this.isMsg = false
                    this.isLoader = false
                })
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            async getLocation(location){
                if(this.autoLocation){
                        navigator.geolocation.getCurrentPosition(async (position) => {
                        let lat = position.coords.latitude;
                        let long = position.coords.longitude;
                        this.risk.location = {coordinates : `${lat.toFixed(4)}, ${long.toFixed(4)}`, name: await this.reverseGeocode(lat,long)}  
                    });
                }else{
                    this.risk.location = {coordinates : `${location.latitude}, ${location.longitude}`, name: location.location} 
                    this.locationDataRes = []
                }
            },
            async reverseGeocode(lat, lon) {
                const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`;
                try {
                    const response = await fetch(url);
                    const data = await response.json();
                    if (data.address) {
                    return `${data.address.suburb} - ${data.address.town},<br> ${data.address.county},<br> ${data.address.country}`;
                    } else {
                        return 'Location not found';
                    }
                } catch (error) {
                    console.error('Error fetching location:', error);
                    return 'Error fetching location';
                }
            },
            addCategory(){
                this.isLoader = true
                var token = this.getCookie('token')
                axios.post('https://aghub.miphost.com/api/broadcast/risk-category', {name:this.category},
                    { headers:{'Authorization': `Bearer ${token}`}})
                .then(response =>  {
                    this.getZones()
                    this.isLoader = false
                }).catch(error => {
                    console.log(error);
                    setTimeout(() => {
                        this.isMsg = true
                        msg = error.response.data.message
                    }, 3000);
                    this.isMsg = false
                    this.isLoader = false
                })
            },
            toggleCatDropdown(){
                this.$refs.categoryToggle.click()
            },
            submitSelectedOfficers() {
                this.isLoader = true
                var token = this.getCookie('token')
                if (this.selectedOfficers.length === 0) {
                    alert('No officers selected');
                    return;
                }

                const promises = this.selectedOfficers.map(officerId =>
                    axios.post('https://aghub.miphost.com/api/broadcast/assignOfficerToZone',
                        { extensionsOfficerId : officerId, riskZoneId:this.selectedZone.id },
                        { headers:{'Authorization': `Bearer ${token}`}}
                    )
                );

                Promise.all(promises)
                    .then(responses => {
                        alert('All officers submitted successfully');
                        this.getZones()
                        this.isLoader = false
                    })
                    .catch(error => {
                        alert('Error submitting officers');
                        setTimeout(() => {
                            this.isMsg = true
                            msg = error.response.data.message
                        }, 3000);
                        this.isMsg = false
                        this.isLoader = false
                    });
            },
            switchToMore(risk){
                console.log(risk)
                this.viewMoreInfo = true
                this.riskView = risk
            },
            generateRandomNumber(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            },
            toggleAllOfficers(event) {
                if (event.target.checked) {
                    const allOfficerIds = this.officers.map(officer => officer.id);
                    this.risk.riskResponders = [...new Set([...this.risk.riskResponders, ...allOfficerIds])];
                } else {
                    this.risk.riskResponders = this.risk.riskResponders.filter(id => !this.officers.some(officer => officer.id === id));
                }
                }
            },  
    }
</script>
<style scoped>
    .masonry {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: 10px;
        gap: 20px;
    }
    .item {
        background-color: #ffffff00;
        padding: 0px;
        box-sizing: border-box;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
        color: #fff;
        border-radius: 8px;

    }
    .item:hover {
        transform: translateY(-10px);
    }

    .riskImages{
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .nav-tabs {
        border-bottom: 1px solid #26490600;
    }

    .active{
         /* border-color: #274a06 #274b06 #fff; */
         border-color: none;
         box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    }
    .nav-link{
        padding: 0.2rem 1rem;
        color: #3c3c3c;
    }
    .action_badge:hover {
        background-color: #86d1fc;
        border-radius: 50%;
        
    }
    .input-group-text{
        background-color:#e9ecef00 !important;
        border: none !important;
        padding: 0px !important;
    }
    .input-group-text:hover{
        color:#037205 !important;
        cursor: pointer;
    }
    .btn-close{
        width: 3px !important;
        height: 3px !important;
        margin-top: 6px;
    }
    .alert a{
         margin-top: 3px !important;
         opacity: .6;

    }
    .alert a:hover{
         opacity: 1;
    }
    .alert{
        padding: 2px 14px !important;
        border-radius: 50rem!important;
    }
        /* we will explain what these classes do next! */
        .v-enter-active,
        .v-leave-active {
        transition: opacity 2.5s ease;
        }

        .v-enter-from,
        .v-leave-to {
        opacity: 0;
        }
    .dropdown-toggle::after {
        display: none !important;
    }
    .card{
        margin-top:4%;
        /* min-height: 90% !important; */
        min-width: 80% !important;
        /* overflow-x: scroll; */
        border-radius: 1rem;
        transition: 0.5s;
        background-color: #ffffff !important;
    }

    .stockList:hover>* {
        --bs-table-accent-bg: rgb(0 0 0 / 0%) !important;
    }

    .table-sm>:not(caption)>*>* {
        padding: 0.6rem .25rem !important;
    }

/* **************************** */

.form-control-sm, .form-select-sm {
    background-color: #1a222600;
    border: none;
    border-bottom: 2px solid #dc3545;
    border-top: 0px;
    border-radius: 0px;
    font-weight:100;
    outline: 0;
    margin-bottom: 20px;
    padding-left: 10px;
}
.form-control-sm, .form-select-sm{
    font-size: 0.75rem !important;
}
.form-control:focus .form-select-sm.focus{
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px solid #dc3545 !important ;
    outline: 0;
    background-color: #31383b00;
}

input:focus {
    outline: none;
    box-shadow: 0 0 0;
}

label {
    margin-bottom: 0px;
}

.form-label {
    font-size: 10px;
    color: #6C6C6C;
    font-weight:100;
    letter-spacing: 1px;
}

.spinner-border {
    display: block  !important;;
    width: 1rem;
    height: 1rem;
    color: #dc3545;
    border: .2em solid currentColor;
    border-right-color: transparent !important;
}
.spinner-grow-sm {
    width: .45rem;
    height: .45rem;
    vertical-align: 0.576em !important;
    color: #f68888;
}
</style>


<style scoped>

/* Blog 
---------------------*/
.blog-grid {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
}
.blog-grid .blog-img {
  position: relative;
}
.blog-grid .blog-img .date {
  position: absolute;
  background: #fc5356;
  color: #ffffff;
  padding: 8px 15px;
  left: 10px;
  top: 10px;
  border-radius: 4px;
}
.blog-grid .blog-img .date span {
  font-size: 22px;
  display: block;
  line-height: 22px;
  font-weight: 700;
}
.blog-grid .blog-img .date label {
  font-size: 14px;
  margin: 0;
}
.blog-grid .blog-info {
  padding: 20px;
}
.blog-grid .blog-info h5 {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 10px;
}
.blog-grid .blog-info h5 a {
  color: #20247b;
}
.blog-grid .blog-info p {
  margin: 0;
}
.blog-grid .blog-info .btn-bar {
  margin-top: 20px;
}


/* Blog Sidebar
-------------------*/
.blog-aside .widget {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.blog-aside .widget-body {
  padding: 15px;
}
.blog-aside .widget-title {
  padding: 15px;
  border-bottom: 1px solid #eee;
}
.blog-aside .widget-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: #fc5356;
  margin: 0;
}
.blog-aside .widget-author .media {
  margin-bottom: 15px;
}
.blog-aside .widget-author p {
  font-size: 16px;
  margin: 0;
}
.blog-aside .widget-author .avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid;
}
.blog-aside .widget-author h6 {
  font-weight: 600;
  color: #20247b;
  font-size: 22px;
  margin: 0;
  padding-left: 20px;
}
.blog-aside .post-aside {
  margin-bottom: 15px;
}
.blog-aside .post-aside .post-aside-title h5 {
  margin: 0;
}
.blog-aside .post-aside .post-aside-title a {
  font-size: 18px;
  color: #20247b;
  font-weight: 600;
}
.blog-aside .post-aside .post-aside-meta {
  padding-bottom: 10px;
}
.blog-aside .post-aside .post-aside-meta a {
  color: #6F8BA4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}
.blog-aside .latest-post-aside + .latest-post-aside {
  border-top: 1px solid #eee;
  padding-top: 15px;
  margin-top: 15px;
}
.blog-aside .latest-post-aside .lpa-right {
  width: 90px;
}
.blog-aside .latest-post-aside .lpa-right img {
  border-radius: 3px;
}
.blog-aside .latest-post-aside .lpa-left {
  padding-right: 15px;
}
.blog-aside .latest-post-aside .lpa-title h5 {
  margin: 0;
  font-size: 15px;
}
.blog-aside .latest-post-aside .lpa-title a {
  color: #20247b;
  font-weight: 600;
}
.blog-aside .latest-post-aside .lpa-meta a {
  color: #6F8BA4;
  font-size: 12px;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 10px;
}

.tag-cloud a {
  padding: 4px 15px;
  font-size: 13px;
  color: #ffffff;
  background: #20247b;
  border-radius: 3px;
  margin-right: 4px;
  margin-bottom: 4px;
  text-decoration: none;
}
.tag-cloud a:hover {
  background: #fc5356;
}

.article {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 0px;
  padding-top: 0px !important;
  margin: 0px 0 30px;
}
.article .article-title {
  padding: 15px 0 20px;
}
.article .article-title h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 20px;
}
.article .article-title h6 a {
  text-transform: uppercase;
  color: #fc5356;
  border-bottom: 1px solid #fc5356;
}
.article .article-title h2 {
  color: #20247b;
  font-weight: 600;
}
.article .article-title .media {
  padding-top: 15px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 20px;
}
.article .article-title .media .avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
border: 5px solid;

}
.article .article-title .media .media-body {
  padding-left: 8px;
}
.article .article-title .media .media-body label {
  font-weight: 600;
  color: #fc5356;
  margin: 0;
}
.article .article-title .media .media-body span {
  display: block;
  font-size: 12px;
}
.article .article-content h1,
.article .article-content h2,
.article .article-content h3,
.article .article-content h4,
.article .article-content h5,
.article .article-content h6 {
  color: #20247b;
  font-weight: 600;
  margin-bottom: 15px;
}
.article .article-content blockquote {
  max-width: 600px;
  padding: 15px 0 30px 0;
  margin: 0;
}
.article .article-content blockquote p {
  font-size: 20px;
  font-weight: 500;
  color: #fc5356;
  margin: 0;
}
.article .article-content blockquote .blockquote-footer {
  color: #20247b;
  font-size: 16px;
}
.article .article-content blockquote .blockquote-footer cite {
  font-weight: 600;
}
.article .tag-cloud {
  padding-top: 10px;
}

.article-comment {
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  padding: 20px;
}
.article-comment h4 {
  color: #20247b;
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 22px;
}
img {
    max-width: 100%;
}
img {
    vertical-align: middle;
    border-style: none;
}

/* Contact Us
---------------------*/
.contact-name {
  margin-bottom: 30px;
}
.contact-name h5 {
  font-size: 22px;
  color: #20247b;
  margin-bottom: 5px;
  font-weight: 600;
}
.contact-name p {
  font-size: 18px;
  margin: 0;
}

.social-share a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  margin-right: 10px;
}
.social-share .dribbble {
  box-shadow: 0 8px 30px -4px rgba(234, 76, 137, 0.5);
  background-color: #ea4c89;
}
.social-share .behance {
  box-shadow: 0 8px 30px -4px rgba(0, 103, 255, 0.5);
  background-color: #0067ff;
}
.social-share .linkedin {
  box-shadow: 0 8px 30px -4px rgba(1, 119, 172, 0.5);
  background-color: #0177ac;
}

.contact-form .form-control {
  border: none;
  border-bottom: 1px solid #20247b;
  background: transparent;
  border-radius: 0;
  padding-left: 0;
  box-shadow: none !important;
}
.contact-form .form-control:focus {
  border-bottom: 1px solid #fc5356;
}
.contact-form .form-control.invalid {
  border-bottom: 1px solid #ff0000;
}
.contact-form .send {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .contact-form .send {
    margin-bottom: 20px;
  }
}

.section-title h2 {
    font-weight: 700;
    color: #20247b;
    font-size: 45px;
    margin: 0 0 15px;
    border-left: 5px solid #fc5356;
    padding-left: 15px;
}
.section-title {
    padding-bottom: 45px;
}
.contact-form .send {
    margin-top: 20px;
}
.px-btn {
    padding: 0 50px 0 20px;
    line-height: 60px;
    position: relative;
    display: inline-block;
    color: #20247b;
    background: none;
    border: none;
}
.px-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 30px;
    background: transparent;
    border: 1px solid rgba(252, 83, 86, 0.6);
    border-right: 1px solid transparent;
    -moz-transition: ease all 0.35s;
    -o-transition: ease all 0.35s;
    -webkit-transition: ease all 0.35s;
    transition: ease all 0.35s;
    width: 60px;
    height: 60px;
}
.px-btn .arrow {
    width: 13px;
    height: 2px;
    background: currentColor;
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 25px;
}
.px-btn .arrow:after {
    width: 8px;
    height: 8px;
    border-right: 2px solid currentColor;
    border-top: 2px solid currentColor;
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>


